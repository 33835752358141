import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { components } from "react-select";
import _ from "lodash";
import { createForm } from "../../../apis";
import { MCButton, MCInput, MCSelect, MCTooltip } from "../../MainComponents";
import Icon from "../../Icon/Icon";
import { ModalConditionals, ModalConstraints, ModalSelectCatalogue } from "./";
import {
  CATALOGUES_PREFIX,
  INPUT_TYPE_CATALOGUE_RADIO,
  INPUT_TYPE_CATALOGUE_SELECT,
  INPUT_TYPE_CHECKBOX,
  INPUT_TYPE_CHECKBOX_CONDITIONAL,
  INPUT_TYPE_DATE,
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_DESCRIPTION,
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_FILE,
  INPUT_TYPE_INVOLVED,
  INPUT_TYPE_NUMBER,
  INPUT_TYPE_SUBJECT,
  INPUT_TYPE_TEXT,
  INPUT_TYPE_TEXTAREA,
  isEspecialCatalogue,
} from "../consts";
import { useTranslation } from "react-i18next";

const IconSingleValue = (props) => (
  <components.SingleValue {...props}>
    <Icon name={props.data.icon} size={24} className="me-2 dyIcon2" />
    {props.data.label}
  </components.SingleValue>
);

const IconOption = (props) => (
  <components.Option {...props}>
    <Icon name={props.data.icon} size={24} className="me-2 dyIcon2" />
    {props.data.label}
  </components.Option>
);

const IconSingleValueGrid = (props) => (
  <components.SingleValue {...props}>
    <Icon name={props.data.icon} size={24} className="dyIcon2" />
    {/* {props.data.label} */}
  </components.SingleValue>
);

const returnIconType = (type) => {
  if (type === INPUT_TYPE_TEXT) return "text";
  if (type === INPUT_TYPE_NUMBER) return "24_support";
  if (type === INPUT_TYPE_TEXTAREA) return "textblock";
  if (type === INPUT_TYPE_CHECKBOX) return "check_square";
  if (type === INPUT_TYPE_CHECKBOX_CONDITIONAL) return "check_square";
  if (type === INPUT_TYPE_FILE) return "folder";
  if (type === INPUT_TYPE_DATE) return "calendar";
  if (type === INPUT_TYPE_DATE_RANGE) return "calendar_add";
  if (type === INPUT_TYPE_SUBJECT) return "outline_messages_3";
  if (type === INPUT_TYPE_DESCRIPTION) return "outline_note_text";
  if (type === INPUT_TYPE_EMAIL) return "outline_note_text";
  if (type === INPUT_TYPE_INVOLVED) return "outline_user";
  if (type.includes(CATALOGUES_PREFIX)) return "outline_task_square";
  return "add";
};
const itHasPlaceholder = (type) => {
  if (
    type === "string" ||
    type === "number" ||
    type === "textarea" ||
    type === "subject" ||
    type === "description" ||
    type === "email"
  )
    return true;
};

export const BuildInput = ({
  activeStep,
  activeInput,
  inputIndex,
  inputSchema,
  hdlChange,
  hdlActInp,
  hdlDelInp,
  hdlMveInp,
  hdlAddInp,

  entireSchema,
  entirePathSchema,
  setSchemaState,
  entireFormData,
  entirePathData,
  setFormData,
  entireIsValid,
  setIsValid,
  constraints,
  setConstraints,

  tryToCreate,
  setActiveInput,
  nestLvl = 0,
  egCataloguesState,
  ownCataloguesState,
}) => {
  const [t] = useTranslation("forms");

  const optionsGrid = [
    {
      label: t("buildForm.one_column"),
      value: 4,
      icon: "add",
    },
    {
      label: t("buildForm.two_columns"),
      value: 8,
      icon: "add",
    },
    {
      label: t("buildForm.half_row"),
      value: 6,
      icon: "add",
    },
    {
      label: t("buildForm.whole_row"),
      value: 12,
      icon: "add",
    },
  ];

  const optionsSelectType = [
    {
      value: INPUT_TYPE_TEXT,
      label: t("buildForm.text"),
      icon: "text",
    },
    {
      value: "catalogue",
      label: t("buildForm.catalog"),
      icon: "outline_task_square",
    },
    {
      value: INPUT_TYPE_NUMBER,
      label: t("buildForm.numbers"),
      icon: "24_support",
    },
    {
      value: INPUT_TYPE_TEXTAREA,
      label: t("buildForm.textarea"),
      icon: "textblock",
    },
    {
      value: INPUT_TYPE_CHECKBOX,
      label: t("buildForm.checkboxes"),
      icon: "check_square",
    },
    {
      value: INPUT_TYPE_FILE,
      label: t("buildForm.file"),
      icon: "folder",
    },
    {
      value: INPUT_TYPE_DATE,
      label: t("buildForm.date"),
      icon: "calendar",
    },
    {
      value: INPUT_TYPE_DATE_RANGE,
      label: t("buildForm.date_range"),
      icon: "calendar_add",
    },
    {
      value: INPUT_TYPE_SUBJECT,
      label: t("buildForm.subject"),
      icon: "outline_messages_3",
    },
    {
      value: INPUT_TYPE_DESCRIPTION,
      label: t("buildForm.description"),
      icon: "outline_note_text",
    },
    {
      value: INPUT_TYPE_EMAIL,
      label: t("buildForm.email"),
      icon: "outline_sms",
    },
    {
      value: INPUT_TYPE_INVOLVED,
      label: t("buildForm.involved"),
      icon: "outline_user",
    },
    {
      value: INPUT_TYPE_CHECKBOX_CONDITIONAL,
      label: t("buildForm.conditional_checkboxes"),
      icon: "check_square",
    },
  ];

  const optionsSelectRole = [
    {
      value: null,
      label: "Sin rol",
      icon: "add",
    },
    {
      value: "complainants_name",
      label: t("buildForm.involved"),
    },
    {
      value: "employee_number",
      label: t("buildForm.employee_number"),
    },
    {
      value: "phone",
      label: t("buildForm.phone"),
    },
    {
      value: "location",
      label: t("buildForm.location"),
    },
    {
      value: "address",
      label: t("buildForm.address"),
    },
    {
      value: "street",
      label: t("buildForm.street"),
    },



    {
      value: INPUT_TYPE_TEXT,
      label: t("buildForm.text"),
      icon: "text",
    },
    {
      value: INPUT_TYPE_NUMBER,
      label: t("buildForm.numbers"),
      icon: "24_support",
    },
    {
      value: INPUT_TYPE_TEXTAREA,
      label: t("buildForm.textarea"),
      icon: "textblock",
    },
    {
      value: INPUT_TYPE_CHECKBOX,
      label: t("buildForm.checkboxes"),
      icon: "check_square",
    },
    {
      value: INPUT_TYPE_FILE,
      label: t("buildForm.file"),
      icon: "folder",
    },
    {
      value: INPUT_TYPE_DATE,
      label: t("buildForm.date"),
      icon: "calendar",
    },
    {
      value: INPUT_TYPE_DATE_RANGE,
      label: t("buildForm.date_range"),
      icon: "calendar_add",
    },
    {
      value: INPUT_TYPE_SUBJECT,
      label: t("buildForm.subject"),
      icon: "outline_messages_3",
    },
    {
      value: INPUT_TYPE_DESCRIPTION,
      label: t("buildForm.description"),
      icon: "outline_note_text",
    },
    {
      value: INPUT_TYPE_EMAIL,
      label: t("buildForm.email"),
      icon: "outline_sms",
    },
    {
      value: INPUT_TYPE_INVOLVED,
      label: t("buildForm.involved"),
      icon: "outline_user",
    },
  ];

  const filterOptionsGrid = (inputType) => {
    if (inputType === INPUT_TYPE_DATE_RANGE) {
      return optionsGrid.filter(
        (item) => item.value === 4 || item.value === 12
      );
    }
    if (inputType === INPUT_TYPE_FILE) {
      return optionsGrid.filter((item) => item.value === 12);
    }
    if (inputType === INPUT_TYPE_INVOLVED) {
      return optionsGrid.filter((item) => item.value === 12);
    }
    if (inputType.includes(INPUT_TYPE_CATALOGUE_RADIO)) {
      return optionsGrid.filter(
        (item) => item.value === 4 || item.value === 6 || item.value === 12
      );
    }
    if (inputType.includes(INPUT_TYPE_CATALOGUE_SELECT)) {
      return optionsGrid.filter(
        (item) => item.value === 4 || item.value === 6 || item.value === 12
      );
    }
    return optionsGrid;
  };

  const filterOptionsSelectType = (nestLvl = 0) => {
    const omitOnNest = [INPUT_TYPE_CHECKBOX_CONDITIONAL];
    if (nestLvl === 0) {
      return optionsSelectType;
    } else {
      return optionsSelectType.filter(
        (item) => !omitOnNest.includes(item.value)
      );
    }
  };

  const typeTemp = inputSchema.type.includes("catalog")
    ? "catalogue"
    : inputSchema.type;
  const [rerender, setRerender] = useState(false);

  const [key, setKey] = useState(inputSchema.key);
  const [label, setLabel] = useState(inputSchema.label);
  const [placeholder, setPlaceholder] = useState(inputSchema.placeholder);
  const [type, setType] = useState(typeTemp);
  const [required, setRequired] = useState(inputSchema.required);
  const [sensitive, setSensitive] = useState(inputSchema.sensitive);
  const [grid, setGrid] = useState(inputSchema.grid);
  const [br, setBr] = useState(inputSchema.break);
  const [hidden, setHidden] = useState(inputSchema.hidden);
  const [role, setRole] = useState(inputSchema.role ?? null);
  const [conditionals, setConditionals] = useState(inputSchema.conditionals);
  // Just for checkboxes
  const [defaultAnswer, setDefaultAnswer] = useState(inputSchema.defaultAnswer);

  const [showCatalogModal, setShowCatalogModal] = useState(false);
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [showConstraintsModal, setShowConstraintsModal] = useState(false);

  useEffect(() => {
    setKey(inputSchema.key);
    setLabel(inputSchema.label);
    setPlaceholder(inputSchema.placeholder);
    setType(typeTemp);
    setRequired(inputSchema.required);
    setSensitive(inputSchema.sensitive);
    setGrid(inputSchema.grid);
    setBr(inputSchema.break);
    setHidden(inputSchema.hidden);
    setRole(inputSchema.role);
    setConditionals(inputSchema.conditionals);
    setDefaultAnswer(inputSchema.defaultAnswer);
  }, []);

  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  useEffect(() => {
    setKey(inputSchema.key);
    setLabel(inputSchema.label);
    setPlaceholder(inputSchema.placeholder);
    setType(typeTemp);
    setRequired(inputSchema.required);
    setSensitive(inputSchema.sensitive);
    setGrid(inputSchema.grid);
    setBr(inputSchema.break);
    setHidden(inputSchema.hidden);
    setRole(inputSchema.role);
    setConditionals(inputSchema.conditionals);
    setDefaultAnswer(inputSchema.defaultAnswer);
  }, [inputSchema, inputIndex]);

  const handleChange = (e) => {
    if (e.target.id === "label") {
      hdlChange({
        inputIndex,
        pathSchema: entirePathSchema,
        pathData: entirePathData,
        value: e.target.value,
        toChange: "label",
        key,
        label,
        placeholder,
        type,
        required,
        sensitive,
        defaultAnswer,
        grid,
        break: br,
        conditionals,
      });
    } else if (e.target.id === "placeholder") {
      hdlChange({
        inputIndex,
        pathSchema: entirePathSchema,
        pathData: entirePathData,
        value: e.target.value,
        toChange: "placeholder",
        key,
        label,
        placeholder,
        type,
        required,
        sensitive,
        defaultAnswer,
        grid,
        break: br,
        conditionals,
      });
    } else if (e.target.id === "sensitive") {
      hdlChange({
        inputIndex,
        pathSchema: entirePathSchema,
        pathData: entirePathData,
        value: !sensitive,
        toChange: "sensitive",
        key,
        label,
        placeholder,
        type,
        required,
        sensitive,
        defaultAnswer,
        grid,
        break: br,
        conditionals,
      });
    } else if (e.target.id === "required") {
      hdlChange({
        inputIndex,
        pathSchema: entirePathSchema,
        pathData: entirePathData,
        value: !required,
        toChange: "required",
        key,
        label,
        placeholder,
        type,
        required,
        sensitive,
        defaultAnswer,
        grid,
        break: br,
        conditionals,
      });
    } else if (e.target.id === "break") {
      hdlChange({
        inputIndex,
        pathSchema: entirePathSchema,
        pathData: entirePathData,
        value: !br,
        toChange: "break",
        key,
        label,
        placeholder,
        type,
        required,
        sensitive,
        defaultAnswer,
        grid,
        break: br,
        conditionals,
      });
    } else if (e.target.id === "defaultAnswer") {
      console.log("KKKK");
      console.log(e.target.id);
        hdlChange({ inputIndex, 
          pathSchema: entirePathSchema, 
          pathData: entirePathData,
          value: !defaultAnswer, 
          toChange: "defaultAnswer", 
          key, 
          label, 
          placeholder, 
          type, 
          required, 
          sensitive, 
          defaultAnswer, 
          grid, 
          break: br, 
          conditionals 
        });
    } else if (e.target.id === "hidden") {
      hdlChange({
        inputIndex,
        pathSchema: entirePathSchema,
        pathData: entirePathData,
        value: !hidden,
        toChange: "hidden",
        key,
        label,
        placeholder,
        type,
        required,
        sensitive,
        defaultAnswer,
        grid,
        break: br,
        conditionals,
      });
    } else if (e.target.id === "role") {
      hdlChange({
        inputIndex,
        pathSchema: entirePathSchema,
        pathData: entirePathData,
        value: e.target.value,
        toChange: "role",
        key,
        label,
        placeholder,
        type,
        required,
        sensitive,
        defaultAnswer,
        grid,
        break: br,
        conditionals,
      });
    } else if (e.target.id === "grid") {
      hdlChange({
        inputIndex,
        pathSchema: entirePathSchema,
        pathData: entirePathData,
        value: e.target.value,
        toChange: "grid",
        key,
        label,
        placeholder,
        type,
        required,
        sensitive,
        defaultAnswer,
        grid,
        break: br,
        conditionals,
      });
    } else if (e.target.id === "type") {
      if (e.target.value === "catalogue") {
        if (!(type === "catalogue")) {
          setShowCatalogModal(true);
        }
      } else {
        hdlChange({
          inputIndex,
          pathSchema: entirePathSchema,
          pathData: entirePathData,
          value: e.target.value,
          toChange: "type",
          key,
          label,
          type,
          required: inputSchema.required,
          sensitive: inputSchema.sensitive,
          defaultAnswer: inputSchema.defaultAnswer,
          grid: inputSchema.grid,
          conditionals: inputSchema.conditionals,
        });
      }
    }
  };
  const hdlVldInp = () => {
    let errors = [];
    if (
      typeof inputSchema.label === "string" &&
      inputSchema.label.trim() === ""
    ) {
      errors.push(`${t("buildForm.field_no_empty")}`);
    }
    if (inputSchema.type === "catalog-error") {
      errors.push(`${t("buildForm.catalog_no_valid")}`);
    }
    return errors;
  };
  const handleMoveInput = (e, direction) => {
    e.stopPropagation();
    hdlMveInp({
      direction,
      inputIndex,
      entirePathSchema,
      entirePathData,
      nestLvl,
      setActiveInput,
      setRerender,
    });
  };

  if (rerender) return <></>;

  return (
    <>
      <ModalSelectCatalogue
        inputIndex={inputIndex}
        hdlChange={hdlChange}
        entirePathSchema={entirePathSchema}
        entirePathData={entirePathData}
        nestLvl={nestLvl}
        IconOption={IconOption}
        IconSingleValue={IconSingleValue}
        egCataloguesState={egCataloguesState}
        ownCataloguesState={ownCataloguesState}
        show={showCatalogModal}
        onHide={() => setShowCatalogModal(false)}
      />

      <ModalConditionals
        activeStep={activeStep}
        inputIndex={inputIndex}
        entireSchema={entireSchema}
        entirePathSchema={entirePathSchema}
        entireFormData={entireFormData}
        entirePathData={entirePathData}
        entireIsValid={entireIsValid}
        setFormData={setFormData}
        setSchemaState={setSchemaState}
        setIsValid={setIsValid}
        constraints={constraints}
        setConstraints={setConstraints}
        hdlAddInp={hdlAddInp}
        hdlChange={hdlChange}
        hdlMveInp={hdlMveInp}
        hdlDelInp={hdlDelInp}
        tryToCreate={tryToCreate}
        nestLvl={nestLvl + 1}
        show={showConfigModal}
        onHide={() => setShowConfigModal(false)}
      />

      <ModalConstraints
        activeStep={activeStep}
        inputIndex={inputIndex}
        entireSchema={entireSchema}
        entirePathSchema={entirePathSchema}
        setSchemaState={setSchemaState}
        entireFormData={entireFormData}
        entirePathData={entirePathData}
        setFormData={setFormData}
        entireIsValid={entireIsValid}
        setIsValid={setIsValid}
        constraints={constraints}
        setConstraints={setConstraints}
        show={showConstraintsModal}
        onHide={() => setShowConstraintsModal(false)}
      />

      <div
        className={`input dyTheme1 dyBorder1 ${
          inputSchema.type === "catalog-error" ? "error" : ""
        }  ${inputIndex === activeInput ? "active" : ""}`}
      >
        <div className="input-lbar"></div> {/* barra azul active */}
        <div className="input-info">
          <div className="input-info-title pointer"
            onClick={(e) => {
              hdlActInp(inputIndex);
            }}
          >
            <div className="input-title">
              <h6>
                {label === t("buildForm.enter_label_name")
                  ? t("buildForm.new_field")
                  : label}
              </h6>
            </div>
            <div className="input-move">
              <div onClick={(e) => handleMoveInput(e, "up")}>
                <Icon name="arrow_up_4" className="dyIcon2 pointer" />
              </div>
              <div onClick={(e) => handleMoveInput(e, "down")}>
                <Icon name="arrow_down" className="dyIcon2 pointer" />
              </div>
            </div>
          </div>

          <div className="input-info-main">
            <div className="input-info-main-inputs">
              <label>{t("buildForm.label")}:</label>
              <MCInput
                type="text"
                id="label"
                autoComplete="off"
                value={inputSchema.label}
                onChange={handleChange}
                className="input-label"
              />
            </div>
            <div className="input-info-main-inputs">
              <label>{t("buildForm.answer_type")}:</label>
              <MCSelect
                options={filterOptionsSelectType(nestLvl)}
                components={{
                  Option: IconOption,
                  SingleValue: IconSingleValue,
                }}
                onChange={(e) => handleChange({ target: { ...e, id: "type" } })}
                value={filterOptionsSelectType().find(
                  (item) => item.value === type
                )}
                menuWidth={"100%"}
              />
            </div>
            {itHasPlaceholder(inputSchema.type) && (
              <div className="input-info-main-inputs">
                <label>{t("buildForm.support_label")}:</label>
                <MCInput
                  type="text"
                  id="placeholder"
                  autoComplete="off"
                  value={inputSchema.placeholder}
                  onChange={handleChange}
                  className="input-label"
                />
              </div>
            )}
          </div>

          {/* Cuando el Input esta activo */}
          <div className="input-info-extra">
            <div className="input-info-extra-left">
              
              <div className="input-info-extra-item d-flex align-items-center">
                <MCTooltip
                  text={t("buildForm.sensitive_info")}
                  position="bottom"
                >
                  <div>
                    <Icon
                      name="outline_lock_circle"
                      className={`dyIconEg ${
                        sensitive ? "active" : ""
                      } pointer`}
                      onClick={() =>
                        handleChange({
                          target: { id: "sensitive", value: sensitive },
                        })
                      }
                    />
                  </div>
                </MCTooltip>
              </div>

              {/* REQUIRED */}
              { (!hidden || isEspecialCatalogue(inputSchema.catalogue)) &&
                <div className="input-info-extra-item d-flex align-items-center">
                  <MCTooltip text={t("buildForm.required")} position="bottom">
                    <div>
                      <Icon
                        name="info_circle"
                        className={`dyIconEg ${required ? "active" : ""} pointer`}
                        onClick={() =>
                          handleChange({
                            target: { id: "required", value: required },
                          })
                        }
                      />
                    </div>
                  </MCTooltip>
                </div> }

              {/* BREAK */}
              { (!hidden || isEspecialCatalogue(inputSchema.catalogue)) &&
                <div className="input-info-extra-item d-flex align-items-center">
                  <MCTooltip text={t("buildForm.line_break")} position="bottom">
                    <div>
                      <Icon
                        name="pharagraphspacing"
                        className={`dyIconEg ${br ? "active" : ""} pointer`}
                        onClick={() =>
                          handleChange({ target: { id: "break", value: br } })
                        }
                      />
                    </div>
                  </MCTooltip>
                </div> }
              
              {/* AUTOSELECTION ON CHECKBOX */}
              { inputSchema.type === INPUT_TYPE_CHECKBOX &&
                <div className="input-info-extra-item d-flex align-items-center">
                  <MCTooltip text={t("buildForm.default_value")} position='bottom' >
                    <div>
                    <Icon name="check_square" className={`dyIconEg ${defaultAnswer?"active":""} pointer`} onClick={()=>handleChange({target:{id:"defaultAnswer", value: defaultAnswer }})} />
                    </div>
                  </MCTooltip>
                </div> }

              {/* HIDDEN */}
              <div className="input-info-extra-item d-flex align-items-center"> 
                <MCTooltip 
                  text={
                    hidden 
                    ? isEspecialCatalogue(inputSchema.catalogue) // Saber si el catalogo solo se ocultara determinado scope
                      ? "Campos ocultos" 
                      : "Oculto"
                    : isEspecialCatalogue(inputSchema.catalogue)
                      ? "Campos visibles" 
                      : "Visible"
                  } 
                  position="bottom"
                >
                  <div>
                    <Icon
                      name={hidden ? "outline_eye_slash" : "outline_eye" }
                      className={`dyIconEg ${hidden ? "active" : ""} pointer`} // hidden
                      onClick={() =>
                        handleChange({ target: { id: "hidden", value: hidden } })
                      }
                    />
                  </div>
                </MCTooltip>
              </div>
             
              {/* GRID */}
              <div className="input-info-extra-item d-flex align-items-center">
                <div>
                  <MCSelect
                    className="select-grid"
                    options={filterOptionsGrid(inputSchema.type)}
                    value={optionsGrid.find((item) => item.value === grid)}
                    onChange={(e) =>
                      handleChange({ target: { id: "grid", value: e.value } })
                    }
                    components={{
                      Option: IconOption,
                      SingleValue: IconSingleValueGrid,
                    }}
                    menuWidth={160}
                  />
                </div>
                <MCTooltip
                  text={t("buildForm.area_field_displayed")}
                  position="bottom"
                >
                  <div className="ms-1">
                    <Icon name="question_bubble_bold" size={18} />
                  </div>
                </MCTooltip>
              </div>

              {/* ROLE */}
              {
                (inputSchema.type === INPUT_TYPE_TEXT 
                || inputSchema.type === INPUT_TYPE_TEXTAREA
                || inputSchema.type.includes(CATALOGUES_PREFIX)) &&
                <div className="input-info-extra-item d-flex align-items-center">
                  <div>
                    <MCSelect
                      className="select-grid"
                      options={optionsSelectRole}
                      components={{
                        Option: IconOption,
                        SingleValue: IconSingleValue,
                      }}
                      onChange={(e) => handleChange({ target: { ...e, id: "role" } })}
                      value={optionsSelectRole.find(
                        (item) => item.value === role
                      )}
                      menuWidth={280}
                    />
                  </div>
                  <MCTooltip
                    text={t("buildForm.area_field_displayed")}
                    position="bottom"
                  >
                    <div className="ms-1">
                      <Icon name="question_bubble_bold" size={18} />
                    </div>
                  </MCTooltip>
                </div>
              }
            </div>


            <div className="input-info-extra-right">
              {/* right icons */}
              {inputSchema?.type?.includes(CATALOGUES_PREFIX) && (
                <div
                  className="input-info-extra-item"
                  onClick={() => setShowConstraintsModal(true)}
                >
                  <Icon name="remove_from_playlist" size={24} />
                </div>
              )}
              {inputSchema?.type?.includes("conditional") && (
                <div
                  className="input-info-extra-item"
                  onClick={() => setShowConfigModal(true)}
                >
                  <Icon name="settings" />
                </div>
              )}
              <div
                className="input-info-extra-item"
                onClick={() =>
                  hdlDelInp({
                    inputIndex,
                    entirePathSchema,
                    entirePathData,
                    nestLvl,
                    setActiveInput,
                    setRerender,
                  })
                }
              >
                <Icon name="trash_icon" />
              </div>
            </div>
          </div>

          {/* Cuando el Input esta inactivo */}
          <div className="inactive-info">
            <div className="inactive-info-properties">
              <label>{t("buildForm.features")}:</label>
              <div className="properties-list">
                <div className="properties-item">
                  <Icon name="outline_lock_circle" />
                  <span>
                    {sensitive
                      ? t("buildForm.sensitive")
                      : t("buildForm.non-sensitive")}
                  </span>
                </div>
                { (!hidden || isEspecialCatalogue(inputSchema.catalogue)) && 
                  <div className="properties-item">
                    <Icon name="info_circle" />
                    <span>
                      {required
                        ? t("buildForm.required")
                        : t("buildForm.optional")}
                    </span>
                  </div>
                }
                { (!hidden || isEspecialCatalogue(inputSchema.catalogue)) && 
                  <div className="properties-item">
                    <Icon name="outline_fatrows" />
                    <span>
                      {grid &&
                        optionsGrid.find((item) => grid == item.value).label}
                    </span>
                  </div>
                }
                { hidden &&
                  <div className="properties-item">
                    <Icon name="outline_eye_slash" />
                    <span>
                      Oculto
                    </span>
                  </div>
                }
              </div>
            </div>
            <div className="inactive-info-type">
              <label>{t("buildForm.answer_type")}:</label>
              <div className="type-item">
                <Icon name={returnIconType(type)} />
                <span>
                  {optionsSelectType.find((item) => item.value === type)
                    .label || "add"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const BuildForm = ({
  activeStep,
  activeInput,
  steps,
  schemaState,
  setSchemaState,
  formData,
  setFormData,
  isValid,
  setIsValid,
  constraints,
  setConstraints,

  hdlChange,
  hdlActInp,
  hdlAddInp,
  hdlDelInp,
  hdlMveInp,

  formIdentifier,
  validFormIdentifier,
  hdlFrmNme,
  validCreateSteps,
  validCreateInputs,
  setValidCreateInputs,

  create,
  tryToCreate,
  setTryToCreate,

  setShowPreview,
  egCataloguesState,
  ownCataloguesState,
}) => {
  const [t] = useTranslation("forms");
  const { gTheme } = useSelector((state) => state.theme);

  const navigate = useNavigate();

  const checkAllValidations = () => {
    let errors = false;
    if (validFormIdentifier.length > 0) {
      errors = true;
    }
    validCreateSteps.map((step) => {
      if (step.length > 0) {
        errors = true;
      }
    });
    validCreateInputs.map((step) => {
      step.map((input) => {
        if (input.length > 0) {
          errors = true;
        }
      });
    });
    return !errors;
  };

  const deleteUnnecessaryKeys = (arrInputs) => {
    return arrInputs.map((input) => {
      if ("conditionals" in input) {
        for (const [index, option] of input.conditionals.entries()) {
          delete input.children;
          delete input.childs;
          delete input.next;
          delete input.selected;
          delete input.lastChild;
          const newConditionals = deleteUnnecessaryKeys(option.nestChildren);
          input.conditionals[index].nestChildren = newConditionals;
          return input;
        }
      } else {
        delete input.children;
        delete input.childs;
        delete input.next;
        delete input.selected;
        delete input.lastChild;
        return input;
      }
    });
  };

  const sendForm = async () => {
    setTryToCreate(true);
    if (checkAllValidations()) {
      const formToSend = {};
      _.set(formToSend, "identifier_name", formIdentifier);
      const newSteps = steps.map((step, idx) => {
        const newSchemaStep = _.cloneDeep(schemaState[idx]);
        const cleanSchemaState = deleteUnnecessaryKeys(newSchemaStep);
        _.set(step, "form.json-schema", cleanSchemaState);
        return step;
      });
      _.set(formToSend, "stepers", newSteps);
      const resp = await createForm(formToSend);
      // console.log(resp);
      navigate("/formslist", { state: resp });
    } else {
      console.log("error");
    }
  };

  const validSingleVal = (who) => {
    if (who === "title" && tryToCreate && validFormIdentifier.length > 0) {
      return "error";
    }
    return "";
  };

  return (
    <>
      {tryToCreate && (
        <div className="create-form-error-msg fade-in-image">
          {t("buildForm.error_creating_form")}
        </div>
      )}
      <div className="dyTheme1 dyBorder1 rounded inputs-list">
        <div>
          {create ? (
            <>
              <h4>{t("buildForm.create_new_form")}</h4>
              <small className="pb-3">
                {t("buildForm.select_descriptive_form_title")}
              </small>
            </>
          ) : (
            <>
              <h4>{t("buildForm.edit_form")}</h4>
              <small className="pb-3">
                {t("buildForm.is_a_digital_document_to_record_data")}
              </small>
            </>
          )}
        </div>

        <div className="dyTheme1 dyBorder1 mb-2 info-container title">
          <label>{t("buildForm.title")}:</label>
          <input
            disabled={!create}
            autoComplete="off"
            id="identifier"
            type="text"
            className={`input-title ${validSingleVal("title")}`}
            value={formIdentifier}
            onChange={(e) => hdlFrmNme(e.target.value)}
            placeholder={t("buildForm.enter_title")}
          />
          {/* Array de errores identifier */}
          {validSingleVal("title") === "error" && (
            <small className="array-error-msgs">
              <ul>
                {validFormIdentifier.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </small>
          )}
        </div>

        <div
          className={`info-container inputs dyTheme1 dyBorder1 mb-2 ${
            gTheme !== "light" ? "dark-mode" : ""
          }`}
        >
          {schemaState[activeStep].map((sch, idx) => (
            <BuildInput
              key={idx}
              activeStep={activeStep}
              activeInput={activeInput}
              inputIndex={idx}
              inputSchema={sch}
              hdlChange={hdlChange}
              hdlAddInp={hdlAddInp}
              hdlActInp={hdlActInp}
              hdlDelInp={hdlDelInp}
              hdlMveInp={hdlMveInp}
              tryToCreate={tryToCreate}
              setValidCreateInputs={setValidCreateInputs}
              navigate={navigate}
              entireSchema={schemaState}
              entirePathSchema={`${activeStep}.${idx}`}
              setSchemaState={setSchemaState}
              entireFormData={formData}
              entirePathData={`${activeStep}.${idx}`}
              setFormData={setFormData}
              entireIsValid={isValid}
              setIsValid={setIsValid}
              constraints={constraints}
              setConstraints={setConstraints}
              gTheme={gTheme}
              egCataloguesState={egCataloguesState}
              ownCataloguesState={ownCataloguesState}
            />
          ))}

          <div
            className="add-input"
            onClick={() =>
              hdlAddInp({
                inputIndex: schemaState[activeStep].length,
                entirePathSchema: `${activeStep}`,
                entirePathData: `${activeStep}`,
                nestLvl: 0,
              })
            }
          >
            <div>
              <Icon name="add_square" />
              <span>{t("buildForm.add_field")}</span>
            </div>
          </div>
        </div>

        <div className="info-container buttons dyTheme1 dyBorder1">
          <div className="button-list">
            <MCButton
              label={t("buildForm.cancel")}
              variant="primary"
              outline
              size="sm"
              className="me-2"
              onClick={() => navigate(-1)}
            />
            <MCButton
              label={t("buildForm.preview")}
              variant="primary"
              outline
              size="sm"
              className="me-2"
              onClick={() => setShowPreview(true)}
            />
            <MCButton
              label={`${
                create ? t("buildForm.create_form") : t("buildForm.update_form")
              }`}
              variant="primary"
              outline
              size="sm"
              onClick={sendForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};
