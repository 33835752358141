import { useTranslation } from "react-i18next";
import { Breadcrum } from "../MainComponents/Breadcrumb/Breadcrumb";
import { Button, ProgressBar } from "react-bootstrap";
import { parseDatoToLgAndHZ } from "../../helpers/dateTransform";
import { OutlineDocumentText, Paperclip } from "../Icon";
import { useEffect, useState } from "react";
import { listAssignedUsers } from "../../apis";
import { MCTooltip } from "../../components/MainComponents/Tooltip/Tooltip";
import { AvatarGroup } from "../../components/MainComponents/Avatar/AvatarGroup";

import {
  OutlineMessages3,
  Monitor,
  Call,
  Mobile,
  Grammerly,
  CdIcon,
  OutlineSmsTracking,
  OutlineWhatsapp,
  OutlineDeviceMessage,
  Outline3dCubeScan,
  OutlineAlarm,
  OutlineUserMinus,
  OutlineLock,
  OutlineDirectboxReceive,
  OutlineSms,
  OutlineHierarchy,
  OutlineRouting2
} from "../Icon";

const getStatus = (status, translates) => {
  if (status === "new") return translates["new"];
  if (status === "finish") return translates["finish"];
  if (status === "pending") return translates["pending"];
  if (status === "closed") return translates["closed"];
  if (status === "process") return translates["process"];
  if (status === "draft") return translates["draft"];
};

export const ReportHeader2 = ({
  title,
  subtitle,
  icon,
  reportDetail,
  setShowModalAttachments,
  setShowModalPDFGenerator,
}) => {
  const [t] = useTranslation("report");
  const [t2] = useTranslation("information");

  // console.log(reportDetail);

  const [assignedUsers, setAssignedUsers] = useState([]);
  const [prueba, setPrueba] = useState([]);



  useEffect(() => {
    listAssignedUsers(reportDetail?.id).then((resp) => {
      setAssignedUsers(resp.users);
      setPrueba(resp);
    });
  }, [reportDetail]);


  const status_locales = {
    new: t("status.new"),
    process: t("status.process"),
    finish: t("status.finish"),
    pending: t("status.pending"),
    closed: t("status.closed"),
    draft: t("status.draft"),
  };

  const PresentIndicators = ({ indicators, entryway }) => {
    const indicatorItems = [];
    const iconIndicators = {
      has_plan: {
        text: t("ReportsList.has_plan"),
        element: <OutlineDeviceMessage className="mx-1 icon-indicator" />,
      },
      is_locked: {
        text: t("ReportsList.is_locked"),
        element: <OutlineLock className="mx-1 icon-indicator" />,
      },
      has_message: {
        text: t("ReportsList.has_message"),
        element: <OutlineDirectboxReceive className="mx-1 icon-indicator" />,
      },
      is_severity: {
        text: t("ReportsList.is_severity"),
        element: <OutlineAlarm className="mx-1 icon-indicator blocking" />,
      },
      is_anonymous: {
        text: t("ReportsList.is_anonymous"),
        element: <OutlineUserMinus className="mx-1 icon-indicator" />,
      },
      has_complement: {
        text: t("ReportsList.has_complement"),
        element: <Outline3dCubeScan className="mx-1 icon-indicator" />,
      },
      has_attachments: {
        text: t("ReportsList.has_attachments"),
        element: <Paperclip className="mx-1 icon-indicator" />,
      },
      has_relateds: {
        text: t("ReportsList.has_relateds"),
        element: <OutlineHierarchy className="mx-1 icon-indicator" />
      },
      has_followup: {
        text: t("ReportsList.has_followup"),
        element: <OutlineRouting2 className="mx-1 icon-indicator" />
      }
    };

    const iconEntryway = {
      WEBSITE: {
        element: <Monitor className="mx-1 icon-indicator" />,
      },
      PHONE: {
        element: <Call className="mx-1 icon-indicator" />,
      },
      EMAIL: {
        element: <OutlineSms className="mx-1 icon-indicator" />,
      },
      CHAT: {
        element: <OutlineMessages3 className="mx-1 icon-indicator" />,
      },
      WHATSAPP: {
        element: <OutlineWhatsapp className="mx-1 icon-indicator" />,
      },
      APP: {
        element: <Mobile className="mx-1 icon-indicator" />,
      },
      OUTDOOR: {
        element: <Grammerly className="mx-1 icon-indicator" />,
      },
      OTHER: {
        element: <CdIcon className="mx-1 icon-indicator" />,
      },
    };

    let severity_element = null;

    // Add Entryway in Icon Indicator Form
    indicatorItems.push(
      <MCTooltip
        text={t(`ReportsList.entryways.${entryway}`)}
        position="bottom"
        color
        key={entryway}
      >
        <button className="btnIcon">{iconEntryway[entryway]?.element}</button>
      </MCTooltip>
    );

    if (indicators) {
      for (const [key, value] of Object.entries(indicators)) {
        if (value) {
          if (key !== "is_severity") {
            indicatorItems.push(
              <MCTooltip
                text={iconIndicators[key]?.text}
                position="bottom"
                color
                key={key}
              >
                <button className="btnIcon">
                  {iconIndicators[key]?.element}
                </button>
              </MCTooltip>
            );
          } else {
            severity_element = (
              <MCTooltip
                text={iconIndicators[key].text}
                position="bottom"
                color
                key={key}
              >
                <button className="btnIcon">
                  {iconIndicators[key].element}
                </button>
              </MCTooltip>
            );
          }
        }
      }
    }

    if (severity_element !== null) indicatorItems.push(severity_element);

    if (indicatorItems.length <= 0) {
      return <p className="my-auto"></p>;
    }

    return indicatorItems;
  };

  const iconEntryway = {
    WEBSITE: {
      element: (
        <Monitor
          className={`newmonitor-icon${reportDetail?.status?.current} mx-2`}
        />
      ),
    },
    PHONE: {
      element: (
        <Call
          className={`newmonitor-icon${reportDetail?.status?.current} mx-2`}
        />
      ),
    },
    EMAIL: {
      element: (
        <OutlineSmsTracking
          className={`newmonitor-icon${reportDetail?.status?.current} mx-2`}
        />
      ),
    },
    CHAT: {
      element: (
        <OutlineSms
          className={`newmonitor-icon${reportDetail?.status?.current} mx-2`}
        />
      ),
    },
    WHATSAPP: {
      element: (
        <OutlineWhatsapp
          className={`newmonitor-icon${reportDetail?.status?.current} mx-2`}
        />
      ),
    },
    APP: {
      element: (
        <Mobile
          className={`newmonitor-icon${reportDetail?.status?.current} mx-2`}
        />
      ),
    },
    OUTDOOR: {
      element: (
        <Grammerly
          className={`newmonitor-icon${reportDetail?.status?.current} mx-2`}
        />
      ),
    },
    OTHER: {
      element: (
        <CdIcon
          className={`newmonitor-icon${reportDetail?.status?.current} mx-2`}
        />
      ),
    },
  };

  const iconElement = iconEntryway[reportDetail?.entry_way]?.element;

  return (
    <div className="dyTheme1 dyBorder1 rounded mt-20" id="inform">
      <div className="p-4 d-flex align-items-center justify-content-between row">
        {/* Información reporte izq */}
        <div className="my-2 col-xl-6">
          <div className="d-flex data-inform">
            {/* <div className="channel-icon"> */}
            {iconElement}
            {/* </div> */}

            {/* <pre>{JSON.stringify(reportDetail,null,2)}</pre> */}

            <div className="align-items-center my-2">
              <h4>
                {reportDetail?.indicators?.is_anonymous
                  ? t("ReportViewer.anonymous")
                  : t("ReportViewer.no_anonymous")}
              </h4>
              <small className="mr-2">
                {t2("information.updated_at")}:{" "}
                {parseDatoToLgAndHZ(
                  reportDetail?.updated_at,
                  t2("information.locale")
                ) || t("information.no_updates")}
              </small>
            </div>
          </div>

          <div className="d-flex data-inform">
            <div className="align-items-center my-2">
              <div className="status">
                <div className={`data-status ${reportDetail?.status?.current}`}>
                  <span className="status-text">
                    {getStatus(reportDetail?.status?.current, status_locales)}
                  </span>
                </div>

                {/* <pre>{JSON.stringify(reportDetail?.status?.current,null,2)}</pre> */}

                {/* draft, new, pending, process, finish, closed */}

                {/* Usuarios */}
                <div className="users">
                  <AvatarGroup contacts={assignedUsers} max={3}></AvatarGroup>
                  {/* <span className="user-text">{assignedUsers[0]?.name || "user"} y {assignedUsers.length - 1} personas más</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Información reporte der */}
        <div className="my-2 col-xl-6">
          <div className="text-lg-right">
            <div className="d-sm-flex align-items-center justify-content-start justify-content-xl-end">
              <div className="text-left">
                <p className="followup">
                  {t("ReportsList.informants_folio")}
                  <span className="followup-info">{` ${reportDetail?.tracking_code}`}</span>
                </p>
                <div className="actions-icon">
                  <div className="actionicon">
                    <PresentIndicators
                      indicators={reportDetail?.indicators}
                      entryway={reportDetail?.entry_way}
                    />
                  </div>

                  {/* <div className="actionicon blocking">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <g fill="#292D32" fill-rule="evenodd">
                        <path d="M2 22.75c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h20c.41 0 .75.34.75.75s-.34.75-.75.75H2z" fill-rule="nonzero" />
                        <path d="M3 22.75c-.41 0-.75-.34-.75-.75v-7c0-5.38 4.37-9.75 9.75-9.75s9.75 4.37 9.75 9.75v7c0 .41-.34.75-.75.75H3zm17.25-1.5V15c0-4.55-3.7-8.25-8.25-8.25S3.75 10.45 3.75 15v6.25h16.5z" />
                        <path d="M11.25 3V2c0-.41.34-.75.75-.75s.75.34.75.75v1c0 .41-.34.75-.75.75s-.75-.34-.75-.75zM4.47 5.53l-1-1a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l1 1c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22s-.38-.07-.53-.22zM18.47 5.53a.754.754 0 0 1 0-1.06l1-1c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-1 1c-.15.15-.34.22-.53.22s-.38-.07-.53-.22z" fill-rule="nonzero" />
                      </g>
                    </svg>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="d-block d-xl-flex align-items-end justify-content-end mt-20">
              <div className="d-flex mt-2 justify-content-between">
                {/* <Button
                  className="download-btn"
                  onClick={() => setShowModalAttachments(true)}
                >
                  <Paperclip className="icons m-1" />
                  <span className="d-none d-md-inline-block m-1">
                    {t("ReportViewer.attachments")}
                  </span>
                </Button> */}

                <Button
                  className="download-btn mx-2"
                  onClick={() => setShowModalPDFGenerator(true)}
                >
                  <OutlineDocumentText className="icons m-1" />
                  <span className="d-none d-md-inline-block m-1">
                    {t("Creator.generate_pdf")}
                  </span>
                </Button>

                {/* Marcador  */}
                {/* <button type="button" className="download-btn mx-2 btn btn-primary">
                          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#66818D" fill-rule="nonzero">
                                <path d="M2.934 14.667V1.333c0-.273.226-.5.5-.5.273 0 .5.227.5.5v13.334c0 .273-.227.5-.5.5a.504.504 0 0 1-.5-.5z"/>
                                <path d="M3.434 11.167a.504.504 0 0 1-.5-.5c0-.274.226-.5.5-.5H10.9c.727 0 1.067-.194 1.134-.36.066-.167-.034-.54-.554-1.054l-.8-.8a1.68 1.68 0 0 1-.546-1.2c-.02-.506.18-1.006.546-1.373l.8-.8c.494-.493.647-.893.574-1.067-.074-.173-.454-.346-1.154-.346H3.434a.5.5 0 1 1 0-1H10.9c1.46 0 1.927.606 2.08.966.147.36.247 1.12-.793 2.16l-.8.8a.87.87 0 0 0-.253.634c.006.2.086.38.226.506l.827.82c1.02 1.02.92 1.78.773 2.147-.153.353-.626.967-2.06.967H3.434z"/>
                            </g>
                          </svg>
                        </button> */}

                {/* Adjuntos */}
                {/* <button type="button" className="download-btn btn btn-primary" id="openModalAdj">
                          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.22 16v-6c0-4.27 3.48-7.75 7.75-7.75s7.75 3.48 7.75 7.75v5.5a4.26 4.26 0 0 1-4.25 4.25 4.26 4.26 0 0 1-4.25-4.25V12c0-.41.34-.75.75-.75s.75.34.75.75v3.5c0 1.52 1.23 2.75 2.75 2.75s2.75-1.23 2.75-2.75V10c0-3.45-2.8-6.25-6.25-6.25S5.72 6.55 5.72 10v6c0 2.89 2.35 5.25 5.25 5.25a.749.749 0 1 1 0 1.5c-3.72 0-6.75-3.03-6.75-6.75z"
                              fill="#292D32" fill-rule="nonzero"></path>
                          </svg>
                          <span className="d-none d-md-inline-block m-1">Ver adjuntos</span>
                        </button> */}

                {/* Generar PDF */}
                {/* <button type="button" className="download-btn mx-2 btn btn-primary">
                          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="icons m-1">
                            <g fill="#292D32" fill-rule="evenodd">
                              <path d="M8 22.75c-3.65 0-5.75-2.1-5.75-5.75V7c0-3.65 2.1-5.75 5.75-5.75h8c3.65 0 5.75 2.1 5.75 5.75v10c0 3.65-2.1 5.75-5.75 5.75H8zM3.75 7v10c0 2.86 1.39 4.25 4.25 4.25h8c2.86 0 4.25-1.39 4.25-4.25V7c0-2.86-1.39-4.25-4.25-4.25H8C5.14 2.75 3.75 4.14 3.75 7z">
                              </path>
                              <path d="M16.5 9.25c-1.52 0-2.75-1.23-2.75-2.75v-2c0-.41.34-.75.75-.75s.75.34.75.75v2c0 .69.56 1.25 1.25 1.25h2c.41 0 .75.34.75.75s-.34.75-.75.75h-2zM8 13.75c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75H8zM8 17.75c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75H8z"
                                fill-rule="nonzero">
                              </path>
                            </g>
                          </svg>
                          <span className="d-none d-md-inline-block m-1">Generar PDF</span>
                        </button> */}

                {/* Acciones */}
                {/* <button type="button" className="download-btn btn btn-primary" id="actions-Button">
                          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#292D32" fill-rule="evenodd">
                                <path d="M2.25 12c0-1.52 1.23-2.75 2.75-2.75S7.75 10.48 7.75 12 6.52 14.75 5 14.75 2.25 13.52 2.25 12zm1.5 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zM16.25 12c0-1.52 1.23-2.75 2.75-2.75s2.75 1.23 2.75 2.75-1.23 2.75-2.75 2.75-2.75-1.23-2.75-2.75zm1.5 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zM9.25 12c0-1.52 1.23-2.75 2.75-2.75s2.75 1.23 2.75 2.75-1.23 2.75-2.75 2.75S9.25 13.52 9.25 12zm1.5 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"/>
                            </g>
                          </svg>
                        </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
