// UserWayScript.js
import React, { useEffect } from 'react';

function UserWayScript() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdn.userway.org/widget.js";
    script.async = true;
    script.setAttribute('data-account', "0zsBH2ywyS");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return null;
}

export default UserWayScript;