import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos




export const DistibutionStateSeverity = ({ chartData, startDate, endDate }) => {
    const [chart, setChart] = useState(null);
    const chartDivId = useRef(`DistibutionStateSeverity-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
    const diaInicio = startDate
    const diaFin = endDate


    useEffect(() => {
        let newChart = null;

        const generateChart = async () => {
            try {


                var startDate = new Date(diaInicio).getTime();
                var endDate = new Date(diaFin).getTime();
                const endDatePlusOneDay = new Date(endDate);
                endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


                const root = am5.Root.new(chartDivId.current);
                root.setThemes([MyTheme.new(root)]);


                var zoomableContainer = root.container.children.push(
                    am5.ZoomableContainer.new(root, {
                        width: am5.p100,
                        height: am5.p100,
                        wheelable: true,
                        pinchZoom: true
                    })
                );

                // var zoomTools = zoomableContainer.children.push(am5.ZoomTools.new(root, {
                //     target: zoomableContainer
                // }));

                newChart = root.container.children.push(
                    am5percent.PieChart.new(root, {
                        layout: root.verticalLayout,
                    })
                );

                var series = zoomableContainer.contents.children.push(
                    am5hierarchy.Treemap.new(root, {
                        maskContent: false, //!important with zoomable containers
                        sort: "descending",
                        singleBranchOnly: false,
                        downDepth: 2,
                        upDepth: 0,
                        initialDepth: 2,
                        valueField: "value",
                        categoryField: "name",
                        childDataField: "children",
                        nodePaddingOuter: 0,
                        nodePaddingInner: 0
                    })
                );

                series.nodes.template.events.on("click", function (ev) {
                    var depth = ev.target.dataItem.get("depth");
                    if (depth == 2) {
                        series.selectDataItem(ev.target.dataItem.get("parent"));
                    }
                })

                series.labels.template.set("minScale", 0);
                series.get("colors").set("step", 1);



                const newDataValue = chartData.data.map((item) => {
                    return {
                        category: item.label,
                        value: item.data[0].filter((value, index) => {
                            const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
                            if (startDate === 0 && endDate === 0) {
                                return true;
                            } else {
                                return date >= startDate && date <= endDatePlusOneDay;
                            }
                        }).length,
                        metadata: item.data[2],
                        severity: item.data[5]
                        

                    };
                });
                let listita = [];
                newDataValue.forEach(element => {
                    if (element.value !== 0) {
                        listita.push({
                            category: element.category,
                            value: element.value,
                            metadata: element.metadata,
                            severity: element.severity
                        })
                    }
                });

                let listitaCompleta = [];





                listita.forEach(element => {
                    let countLow = 0;
                    let countMedium = 0;
                    let countHigh = 0;

                    
                    element.severity.forEach(subelement => {
                
                                switch (subelement.path) {
                                    case "type::low":
                                        countLow++;
                                        break;
                                    case "type::medium":
                                        countMedium++;
                                        break;
                                    case "type::hight":
                                        countHigh++;
                                        break;
                                }
                        
                    });

                    if (countLow == 0) {
                        countLow = undefined;
                    }
                    if (countMedium == 0) {
                        countMedium = undefined;
                    }
                    if (countHigh == 0) {
                        countHigh = undefined;
                    }

                    listitaCompleta.push({
                        state: element.category,
                        low: countLow,
                        medium: countMedium,
                        high: countHigh,
                    });
                });

                // Función para obtener la suma total de denuncias
                function sumaDenuncias(item) {
                    return (item.low || 0) + (item.medium || 0) + (item.high || 0);
                }

                // Ordenar la lista `listitaCompleta` por la suma de denuncias de menor a mayor
                listitaCompleta.sort((a, b) => sumaDenuncias(a) - sumaDenuncias(b));


                // console.log("LISTITA COMPLETA", listitaCompleta);


                // console.log("NEW DATA VALUE", newDataValue);

                let data = listitaCompleta








                function processData(data) {
                    var treeData = [];

                    // console.log("ESTE ES EL NUEVO DATA", data);

                    var smallBrands = { name: "Other", children: [] };
                    var index = 0;

                    am5.object.eachOrdered(
                        data,
                        (brand) => {
                            var brandData = { name: data[index].state, children: [] };
                            var brandTotal = 0;
                        
                            // Sumar las severidades si existen
                            if (data[index].low !== undefined) {
                                brandTotal += data[index].low;
                            }
                            if (data[index].medium !== undefined) {
                                brandTotal += data[index].medium;
                            }
                            if (data[index].high !== undefined) {
                                brandTotal += data[index].high;
                            }
                        
                            // Definir los estados para las severidades
                            let severities = [
                                { severity: 'Baja', value: data[index].low, added: false },
                                { severity: 'Media', value: data[index].medium, added: false },
                                { severity: 'Alta', value: data[index].high, added: false }
                            ];
                        
                            // Recorrer el brand para añadir las severidades si cumplen la condición
                            for (var model in data[brand]) {
                                severities.forEach((sev) => {
                                    if (data[brand][model] >= 0 && sev.value !== undefined && !sev.added) {
                                        brandData.children.push({ 
                                            name: data[brand].state + `\nSeveridad: ${sev.severity}`, 
                                            value: sev.value, 
                                            fill: am5.color(0x677935) 
                                        });
                                        sev.added = true;  // Marcar como añadido
                                    }
                                });
                            }
                        
                            // Solo añadir las marcas con un total mayor o igual a 1
                            if (brandTotal >= 1) {
                                treeData.push(brandData);
                            }
                        
                            index++;
                        },
                        (a, b) => {
                            let aval = 0;
                            let bval = 0;
                            am5.object.each(data[a], (key, val) => (aval += val));
                            am5.object.each(data[b], (key, val) => (bval += val));
                            if (aval > bval) return -1;
                            if (aval < bval) return 1;
                            return 0;
                        }
                    );

                    return [{
                        name: "Root",
                        children: treeData
                    }];
                }

                series.data.setAll(processData(data));
                series.set("selectedDataItem", series.dataItems[0]);


                var exporting = am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {
                        align: "right",
                        valign: "bottom"
                    })
                });

                // // Add legend
                newChart.legend = am5.Legend.new(root, {});


                if (chart) {
                    chart.dispose();
                }

                setChart(newChart);
            } catch (error) {
                console.error("Error al cargar las bibliotecas de AmCharts:", error);
            }
        };


        generateChart();




        return () => {

            am5.array.each(am5.registry.rootElements, function (root) {
                if (root) {
                    if (root.dom.id == chartDivId.current) {
                        root.dispose();
                    }
                }
            });


            if (chart) {
                chart.dispose();
            }
        };
    }, [chartData, startDate, endDate]);

    return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default DistibutionStateSeverity;