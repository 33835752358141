import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos

export const BarChartHistoric = ({ chartData, startDate, endDate }) => {
  const [chart, setChart] = useState(null);
  const chartDivId = useRef(`vertical-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
  const diaInicio = startDate
  const diaFin = endDate
  const graphicTitle = chartData.title




  useEffect(() => {
    let newChart = null;

    const generateChart = async () => {
      try {



        var startDate = new Date(diaInicio).getTime();
        var endDate = new Date(diaFin).getTime();
        const endDatePlusOneDay = new Date(endDate);
        endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


        const root = am5.Root.new(chartDivId.current);
        // root.setThemes([am5themes_Animated.new(root)]);
        root.setThemes([MyTheme.new(root)]);

        newChart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panY: false,
            layout: root.verticalLayout,
            wheelX: "panX",
            wheelY: "zoomX",
            paddingLeft: 0
          })
        );


        var cursor = newChart.set("cursor", am5xy.XYCursor.new(root, {
          behavior: "zoomX"
        }));
        cursor.lineY.set("visible", true);
        // console.log("chart created", chartData);

        const newData = chartData.data.map((item) => {
          if (!Array.isArray(item.data[0]) || !Array.isArray(item.data[1])) {
            throw new Error("Estructura de datos inesperada en item.data");
          }
        
          // Primero, transformar los datos y filtrarlos por fecha
          const transformedData = item.data[0].map((value, index) => {
            const daysOffset = parseInt(item.data[1][index]);
            if (isNaN(daysOffset)) {
              console.error(`El valor en item.data[1][${index}] no es un número válido`);
              return null;
            }
        
            const date = new Date(Date.now() - (daysOffset * 24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0); // Normalizar la fecha a las 00:00:00
            if (startDate === 0 && endDate === 0) {
              return {
                value: 1,
                date: date
              };
            } else {
              if (date >= startDate && date <= endDatePlusOneDay) {
                return {
                  value: value,
                  date: date
                };
              } else {
                return null;
              }
            }
          }).filter(data => data !== null);
        
          // Agrupar por fecha y sumar valores
          const grouped = transformedData.reduce((acc, current) => {
            const existing = acc.find(item => item.date === current.date);
            if (existing) {
              existing.value += current.value;
            } else {
              acc.push(current);
            }
            return acc;
          }, []);
        
          return { data: grouped };
        });

        // Ordenar los datos en base al valor
        newData.sort((a, b) => b.value - a.value);

        // console.log("chart newData", newData);



        const yAxis = newChart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
          })
        );

        const xAxis = newChart.xAxes.push(am5xy.DateAxis.new(root, {
          maxDeviation: 0,
          baseInterval: {
            timeUnit: "day",
            count: 1
          },
          renderer: am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
            minGridDistance: 200,
            minorLabelsEnabled: true
          }),
          tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.set("minorDateFormats", {
          day: "dd",
          month: "MM"
        });

        xAxis.data.setAll(newData.map(item => item.data));

        const series = newChart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: "Value",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            sequencedInterpolation: true,
            tooltip: am5.Tooltip.new(root, {
              labelText: "{valueY}"
            })


          })
        );
        series.columns.template.adapters.add("fill", function (fill, target) {
          return newChart.get("colors").getIndex(series.columns.indexOf(target));
        });
        series.columns.template.adapters.add("stroke", function (stroke, target) {
          return newChart.get("colors").getIndex(series.columns.indexOf(target));
        });
        series.columns.template.setAll({
          width: am5.percent(100)
        });
        series.data.setAll(newData.flatMap(item => item.data));
        // console.log("ESTE ES El NEW DATA",newData.flatMap(item => item.data));
        // series.data.setAll(newData.flatMap(item => item.data));


        // var exporting = am5plugins_exporting.Exporting.new(root, {
        //   menu: am5plugins_exporting.ExportingMenu.new(root, {
        //     align: "right",
        //     valign: "bottom"
        //   })
        // });


        var title = newChart.children.push(am5.Label.new(root, {
          text: graphicTitle,
          fontSize: 20,
          x: am5.percent(50),
          y: am5.percent(0),
          centerX: am5.percent(50),
          visible: false // Ocultar el título inicialmente
        }));

        // Configuración de exportación
        var exporting = am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
            align: "right",
            valign: "bottom"
          }),
          pdfOptions: {
            addURL: false
          }
        });

        // Evento para mostrar el título solo durante la exportación a PDF
        exporting.events.on("exportstarted", function (event) {
          if (event.format === "pdf") {
            title.set("visible", true); // Mostrar el título solo al exportar a PDF
          }
        });

        exporting.events.on("exportfinished", function (event) {
          title.set("visible", false); // Ocultar el título nuevamente después de exportar
        });



        series.appear();
        newChart.appear(1000, 100);


        if (chart) {
          chart.dispose();
        }

        setChart(newChart);
      } catch (error) {
        console.error("Error al cargar las bibliotecas de AmCharts:", error);
      }
    };

    if (typeof window !== 'undefined') {
      generateChart();
    }

    return () => {

      am5.array.each(am5.registry.rootElements, function (root) {
        if (root) {
          if (root.dom.id == chartDivId.current) {
            root.dispose();
          }
        }
      });



      if (chart) {
        chart.dispose();
      }
    };
  }, [chartData, startDate, endDate]);

  return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};


export default BarChartHistoric;