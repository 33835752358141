import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos

export const BarChart = ({ chartData, startDate, endDate, maximize }) => {
  const [chart, setChart] = useState(null);
  const chartDivId = useRef(`vertical-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
  const diaInicio = startDate
  const diaFin = endDate
  const maximizes = maximize
  const graphicTitle = chartData.title







  useEffect(() => {
    let newChart = null;

    const generateChart = async () => {
      try {

        var startDate = new Date(diaInicio).getTime();
        var endDate = new Date(diaFin).getTime();
        const endDatePlusOneDay = new Date(endDate);
        endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);



        const root = am5.Root.new(chartDivId.current);
        root.setThemes([MyTheme.new(root)]);

        newChart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            paddingLeft: 0,
            paddingRight: 0,
            layout: root.verticalLayout
          }));

        var colors = newChart.get("colors");



        const newData = chartData.data.map((item) => ({
          category: item.label,
          value: item.data[0].filter((value, index) => {
            const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
            if (startDate === 0 && endDate === 0) {
              return true;
            } else {
              return date >= startDate && date <= endDatePlusOneDay;
            }
          }).length,
        }));
        newData.sort((a, b) => b.value - a.value);

        prepareParetoData();

        function prepareParetoData() {
          var total = 0;

          for (var i = 0; i < newData.length; i++) {
            var value = newData[i].value;
            total += value;
          }

          var sum = 0;
          for (var i = 0; i < newData.length; i++) {
            var value = newData[i].value;
            sum += value;
            newData[i].pareto = sum / total * 100;
          }
        }

        var xRenderer = am5xy.AxisRendererX.new(root, {
          minGridDistance: 85,
          minorGridEnabled: true
        })


        let xAxis = newChart.xAxes.push(
          am5xy.CategoryAxis.new(root, {
            visible: true,
            categoryField: "category",
            renderer: xRenderer,
          })
        );
        if (maximizes == false) {
          let xAxis = newChart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
              visible: false,
              categoryField: "category",
              renderer: xRenderer,
            })
          );
        }





        xRenderer.grid.template.setAll({
          location: 1
        })

        xRenderer.labels.template.setAll({
          paddingTop: 20
        });


        xAxis.data.setAll(newData);


        const yAxis = newChart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, { strokeOpacity: 0.1 }),
          })
        );

        var paretoAxisRenderer = am5xy.AxisRendererY.new(root, { opposite: true });
        var paretoAxis = newChart.yAxes.push(am5xy.ValueAxis.new(root, {
          renderer: paretoAxisRenderer,
          min: 0,
          max: 100,
          strictMinMax: true
        }));

        paretoAxisRenderer.grid.template.set("forceHidden", true);
        paretoAxis.set("numberFormat", "#'%");



        const series = newChart.series.push(
          am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            categoryXField: "category",
            sequencedInterpolation: true,


          })
        );

        series.columns.template.setAll({
          tooltipText: "{categoryX}: {valueY}",
          tooltipY: 0,
          strokeOpacity: 0,
          cornerRadiusTL: 6,
          cornerRadiusTR: 6
        });


        // series.columns.template.adapters.add("fill", function (fill, target) {
        //   return newChart.get("colors").getIndex(series.columns.indexOf(target));
        // });
        // series.columns.template.adapters.add("stroke", function (stroke, target) {
        //   return newChart.get("colors").getIndex(series.columns.indexOf(target));
        // });

        series.columns.template.adapters.add("fill", function (fill, target) {
          return newChart.get("colors").getIndex(series.dataItems.indexOf(target.dataItem));
        })


        const paretoSeries = newChart.series.push(am5xy.LineSeries.new(root, {
          xAxis: xAxis,
          yAxis: paretoAxis,
          valueYField: "pareto",
          categoryXField: "category",
          stroke: root.interfaceColors.get("alternativeBackground"),
          maskBullets: false
        }));

        paretoSeries.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 1,
            sprite: am5.Circle.new(root, {
              radius: 5,
              fill: series.get("fill"),
              stroke: root.interfaceColors.get("alternativeBackground")
            })
          })
        })




        paretoSeries.data.setAll(newData);
        series.data.setAll(newData);

        // console.log("NEW DATA PARETO", newData);



        // var exporting = am5plugins_exporting.Exporting.new(root, {
        //   menu: am5plugins_exporting.ExportingMenu.new(root, {
        //     align: "right",
        //     valign: "bottom"
        //   })
        // });




        var title = newChart.children.push(am5.Label.new(root, {
          text: graphicTitle,
          fontSize: 20,
          x: am5.percent(50),
          y: am5.percent(0),
          centerX: am5.percent(50),
          visible: false // Ocultar el título inicialmente
        }));

        // Configuración de exportación
        var exporting = am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
            align: "right",
            valign: "bottom"
          }),
          pdfOptions: {
            addURL: false
          }
        });

        // Evento para mostrar el título solo durante la exportación a PDF
        exporting.events.on("exportstarted", function (event) {
          if (event.format === "pdf") {
            title.set("visible", true); // Mostrar el título solo al exportar a PDF
          }
        });

        exporting.events.on("exportfinished", function (event) {
          title.set("visible", false); // Ocultar el título nuevamente después de exportar
        });

        series.appear();
        newChart.appear(1000, 100);


        if (chart) {
          chart.dispose();
        }

        setChart(newChart);
      } catch (error) {
        console.error("Error al cargar las bibliotecas de AmCharts:", error);
      }
    };

    if (typeof window !== 'undefined') {
      generateChart();
    }

    return () => {

      am5.array.each(am5.registry.rootElements, function (root) {
        if (root) {
          if (root.dom.id == chartDivId.current) {
            root.dispose();
          }
        }
      });


      if (chart) {
        chart.dispose();
      }
    };
  }, [chartData, startDate, endDate]);

  return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default BarChart;