import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map.js";
// import * as am5map from "@amcharts/amcharts5/map.js";
import map from "@amcharts/amcharts5-geodata/mexicoLow.js";

// import map from './worldLow';
// import * as am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "../colors"; // Importar los colores definidos




export const MexicoChart = ({ chartData, startDate, endDate }) => {

  const [chart, setChart] = useState(null);
  const chartDivId = useRef(`mexicochart-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
  const diaInicio = startDate
  const diaFin = endDate
  const graphicTitle = chartData.title




  useEffect(() => {
    let newChart = null;

    const generateChart = async () => {
      try {

        var startDate = new Date(diaInicio).getTime();
        var endDate = new Date(diaFin).getTime();
        const endDatePlusOneDay = new Date(endDate);
        endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);

        const root = am5.Root.new(chartDivId.current);
        root.setThemes([MyTheme.new(root)]);

        newChart = root.container.children.push(
          am5map.MapChart.new(root, {
            projection: am5map.geoMercator()
          })
        );

        var bubbleSeries = newChart.series.push(
          am5map.MapPointSeries.new(root, {
            calculateAggregates: true,
            valueField: "value",
            polygonIdField: "id",

          })
        );

        function interpolateColor(value, min, max) {
          const segmentSize = (max - min) / 3; 
          const firstSegment = min + segmentSize;
          const secondSegment = firstSegment + segmentSize;
        
          if (value <= firstSegment) {
            return "rgb(68, 203, 103)"; 
          } else if (value <= secondSegment) {
            return "rgb(254, 175, 0)"; 
          } else {
            return "rgb(222, 70, 61)"; 
          }
        }

        const minLength = 1; 

        const maxLength = Math.max(...chartData.data
          .filter(item => item.data.length !== 0)
          .map(item => item.data[0].filter((value, index) => {
            const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
            if (startDate === 0 && endDate === 0) {
              return true;
            } else {
              return date >= startDate && date <= endDatePlusOneDay;
            }
          }).length)
        );

  
        var polygonSeries = newChart.series.push(
          am5map.MapPolygonSeries.new(root, {
            geoJSON: map
          })
        );
        
        polygonSeries.mapPolygons.template.setAll({
          strokeWidth: 0.5,
          stroke: root.interfaceColors.get("background"),
          tooltipText: "{name}: {value}", 
          templateField: "polygonTemplate"
        });
        
        const processedData = chartData.data
        .map((item) => {
          const hasData = item.data[2] && item.data[2].length > 0;
          // Obtener el valor del estado si hay datos
          const filteredData = hasData ? item.data[2].filter(obj => obj.catalogue === 'RD-102107099') : [];
          var valueState = filteredData.length > 0 ? filteredData[0].value.split('::').pop() : null;
      
          const value = item.data[0].filter((value, index) => {
            const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
            if (startDate === 0 && endDate === 0) {
              return true;
            } else {
              return date >= startDate && date <= endDatePlusOneDay;
            }
          }).length;
      
          const polygonColor = hasData ? interpolateColor(value, minLength, maxLength) : 0x099ED7;
      
          return {
            category: item.label,
            polygonTemplate: {
              fill: am5.color(polygonColor), 
              tooltipText: "{name}: {value}"
            },
            value: value,
            id: valueState,
            name: item.label,
          };
        });
      
      // Configura el color de fondo del mapa a negro para los estados sin datos
      polygonSeries.mapPolygons.template.setAll({
        fill: am5.color(0x099ED7),
        fillOpacity: 1,
        strokeWidth: 0.5,
        stroke: root.interfaceColors.get("background"),
      });
        





      const rango = (maxLength - minLength) / 3;

      // Contenedor de la leyenda
      var legendContainer = root.container.children.push(
          am5.Container.new(root, {
              layout: root.verticalLayout,
              x: am5.p100,
              centerX: am5.p100,
              marginRight: 15,
              y: am5.p50,
              centerY: am5.p50
          })
      );

      // Crear los items de la leyenda usando los rangos
      function createLegendItem(color, label, min, max) {
          var legendItem = legendContainer.children.push(
              am5.Container.new(root, {
                  layout: root.horizontalLayout,
                  paddingTop: 5,
                  paddingBottom: 5
              })
          );

          // Crear el cuadro de color
          legendItem.children.push(
              am5.Rectangle.new(root, {
                  width: 15,
                  height: 15,
                  fill: am5.color(color),
                  stroke: am5.color(0x000000),
                  strokeWidth: 1
              })
          );

          // Añadir el texto de la leyenda con valores redondeados hacia abajo
          legendItem.children.push(
              am5.Label.new(root, {
                  text: `${label}: ${Math.floor(min)} - ${Math.floor(max)}`,
                  x: 10,  // espacio entre el cuadro y el texto
                  y: -4,
                  fontSize: 14,
                  fill: am5.color(0x000000)
              })
          );
      }

      // Añadir los tres niveles de gravedad con los rangos
      createLegendItem(0x44CB67, "Cantidad de denuncias", minLength, minLength + rango);           // Verde
      createLegendItem(0xFEAF00, "Cantidad de denuncias", minLength + rango, minLength + 2 * rango); // Amarillo
      createLegendItem(0xDE463D, "Cantidad de denuncias", minLength + 2 * rango, maxLength);





      
        // Asignar los datos procesados a la serie de polígonos
        polygonSeries.data.setAll(processedData);

        bubbleSeries.data.setAll(processedData);

        // var exporting = am5plugins_exporting.Exporting.new(root, {
        //   menu: am5plugins_exporting.ExportingMenu.new(root, {
        //     align: "right",
        //     valign: "bottom"
        //   })
        // });







        var title = newChart.children.push(am5.Label.new(root, {
          text: graphicTitle,
          fontSize: 20,
          x: am5.percent(50),
          y: am5.percent(0),
          centerX: am5.percent(50),
          visible: false // Ocultar el título inicialmente
        }));

        // Configuración de exportación
        var exporting = am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
            align: "right",
            valign: "bottom"
          }),
          pdfOptions: {
            addURL: false
          }
        });

        // Evento para mostrar el título solo durante la exportación a PDF
        exporting.events.on("exportstarted", function (event) {
          if (event.format === "pdf") {
            title.set("visible", true); // Mostrar el título solo al exportar a PDF
          }
        });

        exporting.events.on("exportfinished", function (event) {
          title.set("visible", false); // Ocultar el título nuevamente después de exportar
        });






        // Add legend
        newChart.legend = am5.Legend.new(root, {});

        newChart.appear(1000, 100);

        if (chart) {
          chart.dispose();
        }

        setChart(newChart);
      } catch (error) {
        console.error("Error al cargar las bibliotecas de AmCharts:", error);
      }
    };


    generateChart();




    return () => {

      am5.array.each(am5.registry.rootElements, function (root) {
        if (root) {
          if (root.dom.id == chartDivId.current) {
            root.dispose();
          }
        }
      });


      if (chart) {
        chart.dispose();
      }
    };
  }, [chartData, startDate, endDate]);

  return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default MexicoChart;