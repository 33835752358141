
import * as am5 from "@amcharts/amcharts5";

//colores originales
// export class MyTheme extends am5.Theme {
//     setupDefaultRules() {
      
//       this.rule("ColorSet").setAll({
//         colors: [
//           am5.color(0x3181FF),
//           am5.color(0x44CB67),
//           am5.color(0xFFAF00),
//           am5.color(0xDE463D),
//           am5.color(0x007B6F),
//           am5.color(0x7C4DFF),
//           am5.color(0xD81B60),
//           am5.color(0xCDDC39),
//         ],
//         reuse: true
//       });
  
//     }
//   }

//colores claros
export class MyTheme extends am5.Theme {
    setupDefaultRules() {
      
      this.rule("ColorSet").setAll({
        colors: [
          am5.color(0x87b9ff),
          am5.color(0x7fdc8d),
          am5.color(0xffd67f),
          am5.color(0xf49f9a),
          am5.color(0x48a8b8),
          am5.color(0xa896ff),
          am5.color(0xf65490),
          am5.color(0xe6ef87),
        ],
        reuse: true
      });
    }
  }

  export class barrasestadostackchart extends am5.Theme {
    setupDefaultRules() {
      this.rule("ColorSet").setAll({
        colors: [
          am5.color(0x44cb67), 
          am5.color(0xffaf00), 
          am5.color(0xde463d)
        ],
        reuse: true
      });
    }
  }
  

export const colors = [
    // am5.color(0x3181FF),
    // am5.color(0x44CB67),
    // am5.color(0xFFAF00),
    // am5.color(0xDE463D),
    // am5.color(0x007B6F),
    // am5.color(0x7C4DFF),
    // am5.color(0xD81B60),
    // am5.color(0xCDDC39),
0x87b9ff,
0x7fdc8d,
0xffd67f,
0xf49f9a,
0x48a8b8,
0xa896ff,
0xf65490,
0xe6ef87,
];

//PASTELES
//   export class MyTheme extends am5.Theme {
//     setupDefaultRules() {
      
//       this.rule("ColorSet").setAll({
//         colors: [
//           am5.color(0xAAD0FF), // Azul claro
//           am5.color(0x7CE3A8), // Verde claro
//           am5.color(0xFFCE99), // Naranja claro
//           am5.color(0xFFB6B4), // Rojo claro
//           am5.color(0x8AD6D1), // Azul verdoso claro
//           am5.color(0xB3A4FF), // Morado claro
//           am5.color(0xFFA3BB), // Rosa claro
//           am5.color(0xD6ECA6), // Amarillo claro
//         ],
//         reuse: true
//       });
  
//     }
// }


