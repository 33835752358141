import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { CDBProgress } from "cdbreact";
import { AiSlideActivity } from "../../../../AI/AiSlideActivity";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { parseDateToYYYYMMDD } from "../../../../../helpers/dateTransform";
import Icon from "../../../../Icon/Icon";
import { useEffect } from "react";
import _ from "lodash";
import { patchInvestigation } from "../../../../../apis";
import { useTranslation } from "react-i18next";
import NotFound from "../../../../GlobalComponents/NotFound";
import { useSnackbar } from "notistack";
import "./Plan.scss";
import { EGIconButton } from "../../../../MainComponents/Button/IconButton/IconButton";


const PlanComponent = ({
  plan,
  index,
  investigationAlias,
  toggleModal,
  plans,
  setPlans,
}) => {
  // console.log("Plan component");
  // console.log("plan: ", plan);
  // console.log("plans: ", plans);





  const { enqueueSnackbar } = useSnackbar();


  const [t] = useTranslation("report");

  const parseDate = (originDate) => {
    const date = parseDateToYYYYMMDD(originDate);
    return date;
  };

  const handleForward = async () => {
    const newPlans = _.cloneDeep(plans);
    if (plan.status === "pending") {
      _.set(newPlans, `${index}.status`, "process");
    }
    if (plan.status === "process") {
      _.set(newPlans, `${index}.status`, "completed");
    }
    const objToSave = {
      folder: {
        plans: newPlans,
      },
    };
    const resp = await patchInvestigation(investigationAlias, objToSave);
    // if (resp.error) return console.log(resp.error);
    setPlans(newPlans);
    // window.location.reload();
  };

  const handleBackward = async () => {
    const newPlans = _.cloneDeep(plans);
    if (plan.status === "process") {
      _.set(newPlans, `${index}.status`, "pending");
    }
    if (plan.status === "completed") {
      _.set(newPlans, `${index}.status`, "process");
    }
    const objToSave = {
      folder: {
        plans: newPlans,
      },
    };
    const resp = await patchInvestigation(investigationAlias, objToSave);

    // if (resp.error) return;
    setPlans(newPlans, investigationAlias);
    // window.location.reload();

  };

  const handleCancelTask = async () => {
    // Crea una copia de los planes y elimina la tarea en el índice dado
    const updatedPlans = _.cloneDeep(plans);
    updatedPlans.splice(index, 1); // Elimina la tarea en el índice dado

    // Crea un objeto para actualizar los planes en la base de datos
    const objToSave = {
      folder: {
        plans: updatedPlans,
      },
    };

    // Hacer una solicitud para actualizar los planes en la base de datos
    const resp = await patchInvestigation(investigationAlias, objToSave);


    // Actualizar el estado local con los nuevos planes
    setPlans(updatedPlans, investigationAlias);

    // Mostrar una notificación de éxito
    enqueueSnackbar("Tarea cancelada con éxito", { variant: "success" });
  }








  return (
    <div>
      <div className={"my-1"}>
        <div className={`task__${plan?.status} rounded my-1`}>





          <div className="d-flex align-items-center justify-content-between py-2 ">
            <div>
              <span
                className={`pointer backButton ${plan.status === "pending" ? "d-none" : ""
                  }`}
                onClick={handleBackward}
              >
                <Icon name="arrow_left_3" size={16} className="dyIcon2" />
              </span>
            </div>
            <div className="d-flex">
              <small> {parseDate(plan?.created_at)}</small>
              <small className="ms-2">{plan?.kind}</small>
            </div>
            <div style={{ transform: "rotate(180deg)" }}>
              <span
                className={`pointer backButton ${plan.status === "completed" ? "d-none" : ""
                  }`}
                onClick={handleForward}
              >
                <Icon name="arrow_left_3" className="dyIcon2" size={16} />
              </span>
            </div>
          </div>







          <div className="d-flex align-items-center justify-content-between py-2">

            <h6 className="label m-0">
              {plan?.name}
            </h6>
            {/* <button onClick={handleCancelTask} className="cancel-task-button">
            Cancel
          </button> */}
            <div>
              <EGIconButton
                onClick={handleCancelTask}
                style={{
                  cursor: "pointer",
                  maxWidth: "36px"
                }}
                // color="error"
                iconColor="black"
                title={t("information.cancel")}
                iconName="trash_can"
              />
            </div>
          </div>
          <div className="comment">
            <small className="mt-20">{plan?.details}</small>
            <ul className="mt-20">
              {plan?.todos?.map((todo, idx) => (
                <li key={idx}>
                  <h6>{`${t("Plan.task")} #${idx + 1}`}</h6>
                  <span>{`${todo}`}</span>
                </li>
              ))}
            </ul>


            <h6 className="mt-20">{`Personas Asignadas`}</h6>
            <ul className="mt-20">
              {plan?.party?.map((people, idx) => (
                <li key={idx}>
                  {`${idx + 1}. ${people}`}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* )} */}
      </div>











    </div>

  );
};

export const Plan = ({
  investigationAlias,
  toggleModal,
  plans: initPlans = [],
}) => {
  const [t] = useTranslation("report");
  const [plans, setPlans] = useState(initPlans);
  const [pending, setPending] = useState([]);
  const [process, setProcess] = useState([]);
  const [completed, setCompleted] = useState([]);

  useEffect(() => {
    const newPending = [];
    const newProcess = [];
    const newCompleted = [];
    plans.map((item, index) => {
      if (item.status === "pending") {
        newPending.push({ index, value: item });
      } else if (item.status === "process") {
        newProcess.push({ index, value: item });
      } else if (item.status === "completed") {
        newCompleted.push({ index, value: item });
      }
    });
    setPending(newPending);
    setProcess(newProcess);
    setCompleted(newCompleted);
  }, [plans]);

  var have_one = [
    pending ? pending.length : 0,
    process ? process.length : 0,
    completed ? completed.length : 0,
  ];

  const total = have_one.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0); // 0 is the initial value of the accumulator

  return (
    <div className="dyBackground research-contents">
      <div className="dyTheme1 dyBorder1 rounded p-20 mb-20 d-flex  justify-content-between align-items-center">
        <div className="d-flex flex-column">
          <h4>{t("Plan.activity_plan")}</h4>
        </div>
        <div className="d-flex justify-content-end">
          <MCButton
            label={t("Plan.add_plan")}
            variant="primary"
            outline
            onClick={() => toggleModal("add-plan")}
            className="me-2"
          />
          {/* <AiSlideActivity /> */}
        </div>
      </div>

      <div className="dyTheme1 dyBorder1 rounded p-0 p-sm-3 mt-20">
        {total >= 1 ? (
          <Row>
            <Col lg="4" className="sprint_column p-2">
              <h5 className="text-center">
                {t("Plan.pending")} {pending.length}
              </h5>
              {pending.map((item) => {
                return (
                  <PlanComponent
                    key={item.index}
                    plan={item.value}
                    index={item.index}
                    investigationAlias={investigationAlias}
                    toggleModal={toggleModal}
                    plans={plans}
                    setPlans={setPlans}
                  />
                );
              })}
            </Col>

            <Col lg="4" className="sprint_column p-2">
              <h5 className="text-center">
                {t("Plan.in_process")} {process.length}
              </h5>
              {process.map((item) => {
                return (
                  <PlanComponent
                    key={item.index}
                    plan={item.value}
                    index={item.index}
                    investigationAlias={investigationAlias}
                    toggleModal={toggleModal}
                    plans={plans}
                    setPlans={setPlans}
                  />
                );
              })}
            </Col>

            <Col lg="4" className="sprint_column p-2">
              <h5 className="text-center">
                {t("Plan.finished")} {completed.length}
              </h5>
              {completed.map((item) => {
                return (
                  <PlanComponent
                    key={item.index}
                    plan={item.value}
                    index={item.index}
                    investigationAlias={investigationAlias}
                    toggleModal={toggleModal}
                    plans={plans}
                    setPlans={setPlans}
                  />
                );
              })}
            </Col>
          </Row>
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
};
