import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { Row, Col, Tabs, Tab, ProgressBar, Button } from "react-bootstrap";
import {
  getReportDetail,
  getInvestigationListByReportId,
  catalogByPart,
} from "../../../apis";
import { Monitor } from "../../Icon";
import { Loading } from "../../Loading/String/Loading";
import { Information } from "./Information/Information";
import { Care } from "./Care/Care";
import { InvestigationPage } from "./Investigation/Main/InvestigationPage";
import { Messages } from "./Messages/Messages";
import { Paperclip, OutlineDocumentText } from "../../Icon";
import { Users } from "./Users/Users";
import { useTranslation } from "react-i18next";
import { Breadcrum } from "../../MainComponents/Breadcrumb/Breadcrumb";
import { EthicsModal } from "../../Modal/EthicsModal";
import { PDFCreator } from "../../Report/All/pdf/PDFCreator";
import { parseDateToISOString4 } from "../../../helpers/dateTransform";
import { GeneralIconHeader } from "../../Title/GeneralHeader";
import { ReportHeader } from "../../Title/ReportHeader";
import { ReportHeader2 } from "../../Title/ReportHeader2";

import { TimeLine } from "./Care/TimeLine/TimeLine";
import { ModalAttachments } from "./Investigation/ModalAttachments/ModalAttachments";
import "./styles.scss";

export function getSafeParsedItem(key) {
  try {
    const item = localStorage.getItem(key);
    return JSON.parse(item);
  } catch (e) {
    console.error(`Error parsing JSON from localStorage for key "${key}":`, e);
    return null;
  }
}

export const initialCurrrentReprotLS = (id) => ({
  id,
  tab: "Información",
  "inv-alias": null,
  "inv-folder": "Datos",
});

// Details of raised reports
export const ReportViewer = () => {
  const [t] = useTranslation("report");
  const { id } = useParams();
  const navigate = useNavigate();

  // const activeTabLS = localStorage.getItem("tab-single-report") ?? "Información";
  const currentReportLS =
    getSafeParsedItem("current-report") ?? initialCurrrentReprotLS(id);
  // -----> GENERAL DATA REPORT <----- //
  // Features making up the report
  const [reportDetail, setReportDetail] = useState({});
  // Investigations in selected report
  const [investigationList, setInvestigationList] = useState([]);
  // Gravity category from catalog
  const [severityCat, setSeverityCat] = useState(null);
  // Causes of improper from catalog
  const [improperCat, setImproperCat] = useState(null);
  // Locking category from catalog
  const [lockedCat, setLockedCat] = useState(null);
  // Days before a report is considered expired
  const [expiredReport, setExpiredReport] = useState(0);
  // -----> REPORT VIEW TOOLS <-----//
  // Initial Report Tab
  const [activeTab, setActiveTab] = useState(
    currentReportLS?.tab ?? "Información"
  );
  // Attachments modal
  const [showModalAttachments, setShowModalAttachments] = useState(false);
  // Generate PDFs modal
  const [showModalPDFGenerator, setShowModalPDFGenerator] = useState(false);
  // A general report loading
  const [isLoading, setIsLoading] = useState(true);

  // On mount scene

  const [changeStatus, setchangeStatus] = useState();
  const handleMessage = (msg, status_anterior) => {
    setchangeStatus(msg);
    setActiveTab("Status de atención");
  };

  useEffect(() => {
    reportResponse();
  }, [id]);

  useEffect(() => {
    setchangeStatus(reportDetail?.status?.current);
  }, [reportDetail?.status?.current]);

  useEffect(() => {
    const newCurrentReportLS = { ...currentReportLS, tab: activeTab };
    localStorage.setItem("current-report", JSON.stringify(newCurrentReportLS));
  }, [activeTab]);

  // Cuando cambie el id del reporte que reinicie los datos de current-report en el LS
  useEffect(() => {
    if (id != currentReportLS?.id) {
      const newCurrentReprotLS = initialCurrrentReprotLS(id);
      localStorage.setItem(
        "current-report",
        JSON.stringify(newCurrentReprotLS)
      );
      setActiveTab(newCurrentReprotLS.tab);
    }
  }, []);

  //Get and set info from APIS
  const reportResponse = async () => {
    try {
      const [
        reportDetailApi,
        investigationListApi,
        severityCatApi,
        improperCatApi,
        lockedCatApi,
      ] = await Promise.all([
        getReportDetail(id),
        getInvestigationListByReportId(id),
        catalogByPart({ catalogue: "RD-102106", is_own: false, path: "/" }),
        catalogByPart({ catalogue: "RD-108101", is_own: false, path: "/" }),
        catalogByPart({ catalogue: "RD-108100", is_own: false, path: "/" }),
      ]);
      if(reportDetailApi === null){
        navigate("/reports/all");
      } else {
        setReportDetail(reportDetailApi);
        setInvestigationList(investigationListApi);
        setSeverityCat(severityCatApi);
        setImproperCat(improperCatApi);
        setLockedCat(lockedCatApi);
        setExpiredReport(reportDetailApi?.expired_report);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // Open PDF modal
  const handlePDFGenerator = () => {
    setShowModalPDFGenerator((showModalPDFGenerator) => !showModalPDFGenerator);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={`all-reports-page page`}>
          {/* Evidence Modal */}
          <ModalAttachments
            attachments={reportDetail?.attachments}
            show={showModalAttachments}
            onHide={() => setShowModalAttachments(false)}
            no_attachments={t("ReportViewer.no_attachments")}
          />
          {/* PDF Generator Modal */}
          <EthicsModal
            show={showModalPDFGenerator}
            onHide={handlePDFGenerator}
            modalContent={
              <PDFCreator closeModal={handlePDFGenerator} reportId={id} />
            }
            size={"lg"}
          />

          <ReportHeader
            icon="outline_document_text"
            title={`${reportDetail?.folio || t("ReportViewer.no_folio")}`}
            subtitle={`${reportDetail?.tracking_code}`}
            reportDetail={reportDetail}
          />

          <ReportHeader2
            reportDetail={reportDetail}
            setShowModalAttachments={setShowModalAttachments}
            setShowModalPDFGenerator={setShowModalPDFGenerator}
          />

          <div className="all-report-tab mt-20">
            {/* Dynamic Tabs active={"Menu Tab Selected"} */}
            <Tabs
              activeKey={activeTab}
              onSelect={setActiveTab}
              className="mb-1 dyTheme1 dyBorder1 rounded d-flex justify-content-evenly"
            >
              <Tab eventKey="Información" title={t("ReportViewer.information")}>
                <Information
                  reportDetail={reportDetail}
                  investigationList={investigationList}
                  severityCat={severityCat}
                  improperCat={improperCat}
                  lockedCat={lockedCat}
                  responseReport={reportResponse}
                  id={id}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  setShowModalAttachments={setShowModalAttachments}
                />
              </Tab>
              <Tab
                eventKey="Status de atención"
                title={t("ReportViewer.status_history")}
              >
                <TimeLine
                  reportDetail={reportDetail}
                  onMessage={handleMessage}
                />
                <Care
                  reportDetail={reportDetail}
                  changeStatus={changeStatus}
                  setRerenderAll={reportResponse}
                  setActiveTab={setActiveTab}
                />
              </Tab>
              <Tab eventKey="Usuarios" title={t("ReportViewer.assigns_users")}>
                <Users
                  idReport={id}
                  reportDetail={reportDetail}
                  // setRerenderAll={reportResponse}
                />
              </Tab>
              <Tab
                eventKey="Investigación"
                title={t("ReportViewer.investigation")}
              >
                <InvestigationPage
                  id={id}
                  investigationList={investigationList}
                  setRerenderAll={reportResponse}
                  reportDetail={reportDetail?.stepers}
                  form = {reportDetail?.form}
                />
              </Tab>
              <Tab eventKey="Mensajes" title={t("ReportViewer.messages")}>
                <Messages
                  id={id}
                  tracking_code={reportDetail?.tracking_code}
                  reportDetail={reportDetail}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
};
