import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "../colors"; // Importar los colores definidos




export const DoubleChart = ({ chartData, startDate, endDate }) => {
    const [chart, setChart] = useState(null);
    const chartDivId = useRef(`doublechart-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
    const diaInicio = startDate
    const diaFin = endDate
    const graphicTitle = chartData.title


    useEffect(() => {
        let newChart = null;

        const generateChart = async () => {
            try {

                // console.log("START DATE", diaInicio);

                var startDate = new Date(diaInicio).getTime();
                var endDate = new Date(diaFin).getTime();
                const endDatePlusOneDay = new Date(endDate);
                endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


                const root = am5.Root.new(chartDivId.current);
                root.setThemes([MyTheme.new(root)]);
                
                var container = root.container.children.push(am5.Container.new(root, {
                    width: am5.p100,
                    height: am5.p100,
                    layout: root.horizontalLayout
                }));

                var chart0 = container.children.push(am5percent.PieChart.new(root, {
                    innerRadius: am5.p50,
                    tooltip: am5.Tooltip.new(root, {})
                }));


                var series0 = chart0.series.push(am5percent.PieSeries.new(root, {
                    name: "Series",
                    valueField: "value",
                    categoryField: "category",
                    alignLabels: false,
                    legendLabelText: "[{fill}]{category}[/]",
                    legendValueText: "[bold {fill}]{value}[/]"
                }));

                series0.labels.template.setAll({
                    textType: "circular",
                    templateField: "dummyLabelSettings"
                });


                series0.ticks.template.set("forceHidden", true);

                var sliceTemplate0 = series0.slices.template;
                sliceTemplate0.setAll({
                    draggable: true,
                    templateField: "settings",
                    cornerRadius: 5
                });

                // Separator line
                container.children.push(am5.Line.new(root, {
                    layer: 1,
                    height: am5.percent(60),
                    y: am5.p50,
                    centerY: am5.p50,
                    strokeDasharray: [4, 4],
                    stroke: root.interfaceColors.get("alternativeBackground"),
                    strokeOpacity: 0.5
                }));

                // Label
                container.children.push(am5.Label.new(root, {
                    layer: 1,

                    y: am5.p50,
                    textAlign: "center",
                    rotation: -90,
                    isMeasured: false
                }));

                var chart1 = container.children.push(am5percent.PieChart.new(root, {
                    innerRadius: am5.p50,
                    tooltip: am5.Tooltip.new(root, {})
                }));

                var series1 = chart1.series.push(am5percent.PieSeries.new(root, {
                    valueField: "value",
                    categoryField: "category",
                    alignLabels: false,
                    legendLabelText: "[{fill}]{category}[/]",
                    legendValueText: "[bold {fill}]{value}[/]"
                }));




                series1.labels.template.setAll({
                    textType: "circular",
                    radius: 20,
                    templateField: "dummyLabelSettings"
                });

                series1.ticks.template.set("forceHidden", true);

                var sliceTemplate1 = series1.slices.template;
                sliceTemplate1.setAll({
                    draggable: true,
                    templateField: "settings",
                    cornerRadius: 5
                });

                var previousDownSlice;

                // change layers when down
                sliceTemplate0.events.on("pointerdown", function (e) {
                    if (previousDownSlice) {
                        //  previousDownSlice.set("layer", 0);
                    }
                    e.target.set("layer", 1);
                    previousDownSlice = e.target;
                });

                sliceTemplate1.events.on("pointerdown", function (e) {
                    if (previousDownSlice) {
                        // previousDownSlice.set("layer", 0);
                    }
                    e.target.set("layer", 1);
                    previousDownSlice = e.target;
                });

                sliceTemplate0.events.on("pointerup", function (e) {
                    series0.hideTooltip();
                    series1.hideTooltip();

                    var slice = e.target;
                    if (slice.x() > container.width() / 4) {
                        var index = series0.slices.indexOf(slice);
                        slice.dataItem.hide();

                        var series1DataItem = series1.dataItems[index];
                        series1DataItem.show();
                        series1DataItem.get("slice").setAll({ x: 0, y: 0 });

                        handleDummy(series0);
                        handleDummy(series1);
                    } else {
                        slice.animate({
                            key: "x",
                            to: 0,
                            duration: 500,
                            easing: am5.ease.out(am5.ease.cubic)
                        });
                        slice.animate({
                            key: "y",
                            to: 0,
                            duration: 500,
                            easing: am5.ease.out(am5.ease.cubic)
                        });
                    }
                });

                sliceTemplate1.events.on("pointerup", function (e) {
                    var slice = e.target;

                    series0.hideTooltip();
                    series1.hideTooltip();

                    if (slice.x() < container.width() / 4) {
                        var index = series1.slices.indexOf(slice);
                        slice.dataItem.hide();

                        var series0DataItem = series0.dataItems[index];
                        series0DataItem.show();
                        series0DataItem.get("slice").setAll({ x: 0, y: 0 });

                        handleDummy(series0);
                        handleDummy(series1);
                    } else {
                        slice.animate({
                            key: "x",
                            to: 0,
                            duration: 500,
                            easing: am5.ease.out(am5.ease.cubic)
                        });
                        slice.animate({
                            key: "y",
                            to: 0,
                            duration: 500,
                            easing: am5.ease.out(am5.ease.cubic)
                        });
                    }
                });




                var data =
                    chartData.data.map((item) => ({
                        category: item.label,
                        value: item.data[0].filter((value, index) => {
                            const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
                            if (startDate === 0 && endDate === 0) {
                                return true;
                            } else {
                                return date >= startDate && date <= endDatePlusOneDay;
                            }
                        }).length,

                    }));

                data.unshift({
                    category: "Dummy",
                    value: 10000,
                    settings: {
                        fill: am5.color(0xdadada),
                        stroke: am5.color(0xdadada),
                        fillOpacity: 0.3,
                        strokeDasharray: [4, 4],
                        tooltipText: null,
                        draggable: false
                    },
                    dummyLabelSettings: {
                        forceHidden: true
                    }
                });



                function handleDummy(series) {
                    // count visible data items
                    var visibleCount = 0;
                    am5.array.each(series.dataItems, function (dataItem) {
                        if (!dataItem.isHidden()) {
                            visibleCount++;
                        }
                    });
                    // if all hidden, show dummy
                    if (visibleCount == 0) {
                        series.dataItems[0].show();
                    } else {
                        series.dataItems[0].hide();
                    }
                }


                series0.data.setAll(data);
                series1.data.setAll(data);
                // console.log("SET ALL DATA", data);

                // hide all except dummy
                am5.array.each(series1.dataItems, function (dataItem) {
                    if (dataItem.get("category") != "Dummy") {
                        dataItem.hide(0);
                    }
                });

                series0.dataItems[0].hide(0);

                container.appear(1000, 100);

                var label = root.tooltipContainer.children.push(am5.Label.new(root, {
                    x: am5.p50,
                    y: am5.p50,
                    fill: am5.color(0x000000),
                    fontSize: 50
                }));

                // Add legend
                series0.labels.template.set("forceHidden", true);
                series0.ticks.template.set("forceHidden", true);
             
                series1.labels.template.set("forceHidden", true);
                series1.ticks.template.set("forceHidden", true);
             

                // var legend = container.children.push( 
                //     am5.Legend.new(root, {
                //       centerY: am5.percent(50),
                //       y: am5.percent(50),
                //       layout: root.verticalLayout
                //     })
                //   );
                  
                //   legend.data.setAll(series1.dataItems);
                //   console.log("DATA ITEMS", series0.dataItems);


                
                
                
                
                var title = container.children.push(am5.Label.new(root, {
                    text: graphicTitle,
                    fontSize: 20,
                    x: am5.percent(50),
                    y: am5.percent(0),
                    centerX: am5.percent(50),
                    visible: false 
                }));
                
                // Configuración de exportación
                var exporting = am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {
                        align: "right",
                        valign: "bottom"
                    }),
                    pdfOptions: {
                        addURL: false
                    }
                });
     
          
                  // Evento para mostrar el título solo durante la exportación a PDF
                  exporting.events.on("exportstarted", function (event) {
                    if (event.format === "pdf") {
                      title.set("visible", true); // Mostrar el título solo al exportar a PDF
                    }
                  });
          
                  exporting.events.on("exportfinished", function (event) {
                    title.set("visible", false); // Ocultar el título nuevamente después de exportar
                  });






                if (chart) {
                    chart.dispose();
                }

                setChart(newChart);
            } catch (error) {
                console.error("Error al cargar las bibliotecas de AmCharts:", error);
            }
        };

        generateChart();

        return () => {


            am5.array.each(am5.registry.rootElements, function (root) {
                if (root) {
                    if (root.dom.id == chartDivId.current) {
                        root.dispose();
                    }
                }
            });

            if (chart) {
                chart.dispose();
            }
        };
    }, [chartData, startDate, endDate]);

    return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default DoubleChart;