import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos

export const BarChart = ({ chartData, startDate, endDate, maximize }) => {
  const [chart, setChart] = useState(null);
  const chartDivId = useRef(`vertical-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
  const diaInicio = startDate;
  const diaFin = endDate;
  const maximizes = maximize;
  const graphicTitle = chartData.title




  useEffect(() => {
    let newChart = null;

    const generateChart = async () => {
      try {



        var startDate = new Date(diaInicio).getTime();
        var endDate = new Date(diaFin).getTime();
        const endDatePlusOneDay = new Date(endDate);
        endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


        const root = am5.Root.new(chartDivId.current);
        // root.setThemes([am5themes_Animated.new(root)]);
        root.setThemes([MyTheme.new(root)]);

        newChart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panY: false,
            layout: root.verticalLayout,
          })
        );

        const newData = chartData.data
        .map((item) => ({
            category: item.label,
            value: item.data[0].filter((value, index) => {
                const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
                if (startDate === 0 && endDate === 0) {
                    return true;
                } else {
                    return date >= startDate && date <= endDatePlusOneDay;
                }
            }).length,
        }))
        .filter(item => item.value > 0);

        const yAxis = newChart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
            }),
          })
        );


        var xAxis = newChart.xAxes.push(
          am5xy.CategoryAxis.new(root, {
            visible: true,
            renderer: am5xy.AxisRendererX.new(root, {}),
            categoryField: "category",
            })
            );
            
        if (maximizes == false) {
          var xAxis = newChart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
              forceHidden: true,
              visible: false,
              renderer: am5xy.AxisRendererX.new(root, {}),
              categoryField: "category",
            })
          );
        }

        
          

        xAxis.data.setAll(newData);

        const series = newChart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: "Value",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            categoryXField: "category",
            sequencedInterpolation: true,
            tooltip: am5.Tooltip.new(root, {
              dy: 30,
              pointerOrientation: "vertical",
              labelText: "{category} : {value}"
            })
          })
        );

        var cursor = newChart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineX.set("visible", false);
        cursor.lineY.set("visible", false);


        series.columns.template.adapters.add("fill", function (fill, target) {
          return newChart.get("colors").getIndex(series.columns.indexOf(target));
        });
        series.columns.template.adapters.add("stroke", function (stroke, target) {
          return newChart.get("colors").getIndex(series.columns.indexOf(target));
        });
        series.data.setAll(newData);


        // var exporting = am5plugins_exporting.Exporting.new(root, {
        //   menu: am5plugins_exporting.ExportingMenu.new(root, {
        //     align: "right",
        //     valign: "bottom"
        //   })
        // });




        var title = newChart.children.push(am5.Label.new(root, {
          text: graphicTitle,
          fontSize: 20,
          x: am5.percent(50),
          y: am5.percent(0),
          centerX: am5.percent(50),
          visible: false // Ocultar el título inicialmente
      }));
      
      // Configuración de exportación
      var exporting = am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
              align: "right",
              valign: "bottom"
          }),
          pdfOptions: {
              addURL: false
          }
      });
      
      // Evento para mostrar el título solo durante la exportación a PDF
      exporting.events.on("exportstarted", function(event) {
          if (event.format === "pdf") {
              title.set("visible", true); // Mostrar el título solo al exportar a PDF
          }
      });
      
      exporting.events.on("exportfinished", function(event) {
          title.set("visible", false); // Ocultar el título nuevamente después de exportar
      });




        series.appear();
        newChart.appear(1000, 100);


        if (chart) {
          chart.dispose();
        }

        setChart(newChart);
      } catch (error) {
        console.error("Error al cargar las bibliotecas de AmCharts:", error);
      }
    };

    if (typeof window !== 'undefined') {
      generateChart();
    }

    return () => {

      am5.array.each(am5.registry.rootElements, function (root) {
        if (root) {
          if (root.dom.id == chartDivId.current) {
            root.dispose();
          }
        }
      });



      if (chart) {
        chart.dispose();
      }
    };
  }, [chartData, startDate, endDate]);

  return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default BarChart;