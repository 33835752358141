import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Icon from "../../../Icon/Icon";
import { alpha, useTheme } from "@mui/material/styles";
import { Tooltip, Zoom } from "@mui/material";
import "./IconButton.scss";

/**
 * Customizable button, it is required to add the name of the Icon to be rendered for its correct operation.
 ** --> Icon <--
 * @param (iconName) String of icon name eg: "trash_can", "close_icon", "check_icon", etc.
 * @param (iconColor) Custom color of the selected icon.
 * The color name you provide in the component must match a color defined in the custom theme  created by useCustomTheme (useTheme hook)
 * @param (iconColorHover) Customize the color on mouse hover
 * * If the variant “primary”, “secondary”, “error”, “warning”, “info” or “success” is selected, the icon hover takes precedence over its respective contrast.  
 * @param (iconSize) Setting the selected icon size
 ** --> Button <---
 * @param (size) Button size ["small", "medium", "large"]
 * @param (disable) Button is disabled?
 * @param (outline) boolean true = "transparent background" : "solid background"
 * @param (color) background|border color
 * @param (ariaLabel) provides an accessible description of the button's purpose for screen reader users
 * @param (sx) Additional MUI online styles
 ** --> Tootltip <--
 * @param (placement) Tooltip position  ['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start','top']
 * @param (title) Text displayed in tooltip
 ** --> Event <--
 * @param (onClick) Assigned event execution
 
 */

export const EGIconButton = ({
  iconName = "",
  iconColor = "",
  iconColorHover = "",
  iconSize = 23,
  size = "small",
  disabled = false,
  color,
  colorHover = "grey[300]",
  outline = false,
  ariaLabel,
  sx = {},
  placement = "top",
  title,
  onClick,
  id,
  ...rest
}) => {
  const theme = useTheme();

  // Status for Icon Color
  const [currentIconColor, setCurrentIconColor] = React.useState(
    theme.palette[iconColor]?.main || theme.palette.grey[500]
  );

  // Update icon color based on props
  React.useEffect(() => {
    if (iconColor) {
      setCurrentIconColor(theme.palette[iconColor].main);
    } else if (!outline && !color && !iconColor) {
      setCurrentIconColor(theme.palette.grey[500]);
    } else if (!outline && color && !iconColor) {
      setCurrentIconColor(theme.palette.common.white);
    } else if (!outline && color && iconColor) {
      setCurrentIconColor(theme.palette[iconColor].main);
    }
  }, [iconColor, color, theme, outline]);

  // Hover effect for icon
  const handleMouseEnter = () => {
    if (!outline && color && !iconColor) {
      setCurrentIconColor(theme.palette.blueGray100.main);
    } else if (!outline && color && iconColor) {
      setCurrentIconColor(
        theme.palette[iconColor]?.light || theme.palette[iconColor]?.main
      );
    } else if (outline && !color && !iconColor && iconColorHover) {
      setCurrentIconColor(theme.palette[iconColorHover].main);
    } else if (outline && color && !iconColor) {
      setCurrentIconColor(
        theme.palette[color]?.contrastText ||
          theme.palette[iconColorHover]?.main ||
          theme.palette.grey[500]
      );
    } else if (outline && color && iconColorHover) {
      setCurrentIconColor(theme.palette[iconColorHover].main);
    }
  };

  const handleMouseLeave = () => {
    if (!outline && color && !iconColor) {
      setCurrentIconColor(theme.palette.common.white);
    } else if (!outline && color && iconColor) {
      setCurrentIconColor(theme.palette[iconColor].main);
    } else if (outline && !color && !iconColor && iconColorHover) {
      setCurrentIconColor(theme.palette.grey[500]);
    } else if (outline && color && !iconColor) {
      setCurrentIconColor(theme.palette.grey[500]);
    } else if (outline && color && iconColor) {
      setCurrentIconColor(theme.palette[iconColor].main);
    }
  };

  const disabledStyles = disabled
    ? {
        opacity: 0.5,
      }
    : {};

  // Customized styles for MUI
  const variantStyles = !outline
    ? {
        backgroundColor: `${theme.palette[color]?.main} !important`,
        "&:hover": {
          backgroundColor: `${
            theme.palette[color]?.dark || theme.palette.grey[300]
          } !important`,
        },
        ...disabledStyles,
      }
    : {
        border: !color
          ? `1px solid ${
              theme.palette[iconColor]?.main || theme.palette.grey[500]
            }`
          : `1px solid ${theme.palette[color]?.main || "transparent"}`,
        backgroundColor: `transparent`,
        "&:hover": {
          border: !color
            ? `1px solid ${
                theme.palette[iconColor]?.main || theme.palette.grey[500]
              }`
            : `1px solid ${theme.palette[color]?.main || "transparent"}`,

          backgroundColor: !color
            ? `${alpha(
                theme.palette[iconColor]?.main || theme.palette.grey[500],
                0.2
              )} !important`
            : `${alpha(theme.palette[color]?.main, 0.9)} !important`,
        },
        ...disabledStyles,
      };

  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 150 }}
    >
      <span>
        <IconButton
          onClick={onClick}
          size={size}
          disabled={disabled}
          aria-label={ariaLabel || title}
          sx={{ ...variantStyles, ...sx }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          id={id}
          {...rest}
        >
          <Icon
            name={iconName}
            size={iconSize}
            color={alpha(currentIconColor, disabled ? 0.5 : 1)}
          />
        </IconButton>
      </span>
    </Tooltip>
  );
};
