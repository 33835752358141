import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Icon from "../../Icon/Icon";
import "./Button.scss";

export const MCButton = ({
  className = "",
  label = "",
  variant = "",
  outline = false,
  width = "",
  size,
  icon,
  iconSize,
  style,
  onClick = () => {},
  disabled = false,
  ...rest
}) => {
  const { gTheme } = useSelector((state) => state.theme);

  const theme = gTheme !== "light" ? "dark" : "";

  return (
    <Button
      className={`mcbtn text-center ${theme} ${className} ${
        outline ? "outline" : ""
      }`}
      variant={variant}
      size={size}
      onClick={(e) => onClick(e)}
      disabled={disabled === true ? true : false}
      style={{
        ...(!isNaN(width) && { width }),
        ...style,
      }}
      {...rest}
    >
      <div className={`d-flex align-items-center justify-content-${icon ? "beetween" : "center"}`}>
        {icon && (
          <span className={`btn-icon px-2 ${outline ? "icon-outline" : ""}`}>
            <Icon name={icon} { ...(iconSize && { size: iconSize })}/>
          </span>
        )}
        <label className="pointer">{label}</label>
      </div>
    </Button>
  );
};
