import { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import  reportersc  from './catalogo.json'
import { ProgressBar } from "react-bootstrap";
import { parseDateToLgComun } from "../../../helpers/dateTransform";
import { getBitacora } from "../../../apis";
import { useLocation } from "react-router-dom";
import EGDatePicker from "../DatePicker/EGDatePicker";
import dayjs from 'dayjs';
import { MCButton, MCCheckbox, MCInput, MCLabel } from "../../MainComponents";
import {stylesBitacora} from './stylesBitacora.scss';
import { MCSelect } from "../../MainComponents/Select/Select";
import { Settings3 } from "../../Icon";
import {
    OutlineEye,
    OutlineMessages3,
    OutlineDeviceMessage,
    Outline3dCubeScan,
    OutlineAlarm,
    OutlineUserMinus,
    OutlineLock,
    Paperclip,
    OutlineDirectboxReceive,
    Monitor,
    Call,
    OutlineSms,
    OutlineMessages2,
    Mobile,
    Grammerly,
    CdIcon,
  } from "../../Icon";
  import { MCTooltip } from "../../MainComponents/Tooltip/Tooltip";
  import { GeneralIconHeader } from "../../Title/GeneralHeader";
  import { Breadcrum } from "../../MainComponents/Breadcrumb/Breadcrumb";
  import { Loading } from "../../Loading/String/Loading";
  import { Filter } from "../../Report/Lists/Filter";

export const Bitacora = () => {
      const lang = localStorage.getItem("lang");
      const optionsMonth = Object.keys(reportersc.Rutas).map((key) => ({
        value: key,    // Clave del objeto
        label: reportersc.Rutas[key],  // Valor del objeto
      }));
      const toggleDiv = () => {
        setShowFilters(!showFilters); // Cambia entre true y false
      };
  
      const [t] = useTranslation("report");
        // Inicializa el estado para el valor seleccionado
      const [monthSelected, setMonthSelected] = useState("");
      const [showFilters, setShowFilters] = useState(false);
      // Función que maneja el cambio de selección
      const selectMonth = (selectedOption) => {
          setMonthSelected(selectedOption);  // Acceder al value del objeto seleccionado
      };

      // Función que maneja eventos de teclado
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          
        }
      }
      const cleanDataDB =()=>{
        
        setCurrentPage(0);
        setMonthSelected(null);
        setSearchPath('');
        setSearchTerm('')
        setInicioF(today.subtract(7, 'day'));
        setFinF(today);
        
        
      }
    
    // ----- use Hooks ----- //
    const navigate = useNavigate();
    // ref to input search page
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    //----useState ----//
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(0); //Total Reports
    const [limit, setLimit] = useState(10); //Limit of results per consult
    const [currentPage, setCurrentPage] = useState(0); //Current Page
    const [offset, setOffset] = useState(0); //Set offset to getData
    const [next, setNext] = useState(null); //Next URL
    const [previous, setPrevious] = useState(null); //Prev URL
    const [pageNumLimit, setPageNumLimit] = useState(10); //Limit of pages on screen
    const [maxPageNum, setMaxPageNum] = useState(10); //Number of page max on screen
    const [minPageNum, setMinPageNum] = useState(0); //Number of page min on screen
    const [searchPag, setSearchPag] = useState(""); //Value of input Search
    const [listReports, setListReports] = useState(null); //Report Data
    const [expiredReports, setExpiredReports] = useState(null);
    const [folio, setFolio] = useState(""); //string
    const [tracking_code, setTracking_code] = useState(""); //string
    const [month_created, setMonth_created] = useState("01"); // 01 || 02 ... 11
    const [year_created, setYear_created] = useState("2023"); //YYYY
    const [before_created, setBefore_created] = useState(new Date()); //date
    const [after_created, setAfter_created] = useState(new Date()); //date
    const [r_start_created, setR_start_created] = useState(new Date()); //date
    const [r_end_created, setR_end_created] = useState(new Date()); //date
    const [created, setCreated] = useState(new Date()); // date
    const [open_days_less_than, setOpen_days_less_than] = useState(1); // number
    const [open_days_greater_than, setOpen_days_greater_than] = useState(1); // number
    const [r_start_open_days, setR_start_open_days] = useState(1); // number
    const [r_end_open_days, setR_end_open_days] = useState(1);
    const [status, setStatus] = useState(""); // date
    const today = dayjs();
    const [inicioF, setInicioF] = useState(today.subtract(7, 'day'));
    const [finF, setFinF] = useState(today);
    const [filtro, setFiltro]= useState()
    const [selectPath, setSelectPath]= useState()
    const [paramsForGetReport2, setParamsForGetReport2] = useState({
        start_date : inicioF?.format("YYYY-MM-DD"),
        end_date : finF?.format("YYYY-MM-DD"),
        page: 1,
        page_size: limit
    });
    const differenceInDays = finF.diff(inicioF, 'day')
    // Initial state of checkbox Filter
    const [checksFilter, setChecksFilter] = useState({
      folio: false,
      tracking_code: false,
      month_created: false,
      year_created: false,
      before_created: false,
      after_created: false,
      range_of_created: false,
      created: false,
      open_days_less_than: false,
      open_days_greater_than: false,
      open_days_range: false,
      status: false,
      report_classification: false,
      kind_of_grievance: false,
      kind_of_entryways: false,
      kind_of_severity: false,
    });
    const [reportClassificationSelected, setReportClassificationSelected] =
      useState(null);
    const [kind_of_grievance, setKind_of_grievance] = useState(null);
    const [kind_of_severity, setKind_of_severity] = useState(null);
    const [kind_of_entryways, setKind_of_entryways] = useState(null);
    
  
    const status_locales = {
      new: t("status.new"),
      process: t("status.process"),
      finish: t("status.finish"),
      pending: t("status.pending"),
      closed: t("status.closed"),
      draft: t("status.draft"),
    };
    
    const optionsStatus = [
      { value: "new", label: t("status.new") },
      { value: "process", label: t("status.process") },
      { value: "finish", label: t("status.finish") },
      { value: "pending", label: t("status.pending") },
      { value: "closed", label: t("status.closed") },
      { value: "draft", label: t("status.draft") },
    ];
  
    const limite = localStorage.getItem("limit");
  
    const location = useLocation();
  
    const [selectURL, setSelectURL] = useState(null)

    const [searchTerm, setSearchTerm] = useState(null);
    const [searchPath, setSearchPath] = useState(null);
    const [filteredData, setFilteredData] = useState(listReports); // Estado para guardar el resultado filtrado
  
    // Función para filtrar en múltiples campos
        useEffect(() => {
          
          if(searchTerm ){
            filterData(searchTerm)
          }
          
            if( searchTerm?.length==0 ){
              getData();
            }
          
      }, [searchTerm,searchPath]);
      
    const filterDataDB = () => {
      setParamsForGetReport2((prevState) => ({
        ...prevState,  
        page:null,
        path: monthSelected?.value,
        user_email: searchTerm
      }));
      
    }
  const filterData = (searchTerm) => {
    const filtered = listReports?.filter((item) => {
      const keys = Object?.keys(item);
      const matches = keys.some((key) => {
        const value = item[key]?.toString().toLowerCase();
        const isMatch = value?.includes(searchTerm.toLowerCase());
        return isMatch;
      });
      return matches;
    });
    setListReports(filtered);
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(e.target.value)
    setSearchTerm(term);
    //filterData(term);  // Filtra al cambiar el término de búsqueda
  };
    
    const setFrecuency = () => {
      setParamsForGetReport2((prevState) => ({
        ...prevState,  
        start_date: inicioF?.format("YYYY-MM-DD"),
        end_date: finF?.format("YYYY-MM-DD"),
      }));
    };
    const getData = (params) => {
      getBitacora(params).then((resp) => {
        setListReports(resp.results || []);
        setCount(resp.count || 0);
        setNext(resp.next);
        setPrevious(resp.previous);
        setIsLoading(false);
      });
    };
    
    
    // Este useEffect se asegurará de que `getData` se ejecute cuando `paramsForGetReport2` cambie
    useEffect(() => {
     
        getData(paramsForGetReport2);
    }, [paramsForGetReport2]);
    
    // UseEffect para manejar cambios en los otros valores como `limit`, `currentPage`, etc.
    useEffect(() => {
      setParamsForGetReport2((prevState) => ({
        start_date: inicioF?.format("YYYY-MM-DD"),
        end_date: finF?.format("YYYY-MM-DD"),
        page: currentPage === 0 ? 1 : currentPage,
        page_size: limit,
      }));
    }, [inicioF, finF, currentPage, limit,filtro]);
    
    //Fill with previous registers avaiable in DB
    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      if(location.pathname!='/reports/all'){
        setCurrentPage(0)
        let filtro=location.pathname.split('/')
        // let filtroParaPuebas =  
        const selectedOption = optionsStatus.find(option => option.value === filtro[filtro.length-1].replace(":",""));
  
        // Ahora puedes usar `selectedOption` como desees
        setSelectURL(selectedOption);
  
        filtro= {status:filtro[filtro.length-1].replace(":","")}
        setIsLoading(true);
        setListReports(null);
        checksFilter.status = true;
     
        const paramsForGetReport = {
          ...(checksFilter.status && { ...filtro }),
          offset:0,
          limit,
        };
        setStatus(selectedOption)
  
      }else{
        
        setCurrentPage(0)
        checksFilter.status = false;
        setStatus()
        setSelectURL();
      }
    }, []);
  
    const breadcrumbItems = [
      { label: t("ReportsList.home"), route: "/" },
      { label: t("Bitacora.title") },
    ];
  
    // Recall reports with params
    const handleReCallReports = (evt,pageNumber) => {
      const queryParams = new URLSearchParams(location.search);
      let filtro=location.pathname.split('/')
      setStatus(filtro[filtro.length-1].replace(":",""))
      setIsLoading(true);
      setListReports(null);
      const paramsForGetReport = {
        ...(checksFilter.folio && { folio }),
        ...(checksFilter.tracking_code && { tracking_code }),
        ...(checksFilter.month_created && { month_created }),
        ...(checksFilter.year_created && { year_created }),
        ...(checksFilter.before_created && { before_created }),
        ...(checksFilter.after_created && { after_created }),
        ...(checksFilter.range_of_created && { r_start_created, r_end_created }),
        ...(checksFilter.created && { created }),
        ...(checksFilter.open_days_less_than && { open_days_less_than }),
        ...(checksFilter.open_days_greater_than && { open_days_greater_than }),
        ...(checksFilter.report_classification && {
          report_classification: reportClassificationSelected.value,
        }),
        ...(checksFilter.open_days_range && {
          r_start_open_days,
          r_end_open_days,
        }),
        ...(checksFilter.kind_of_grievance && {
          kind_of_grievance: kind_of_grievance.value,
        }),
        ...(checksFilter.kind_of_severity && {
          kind_of_severity: kind_of_severity.value,
        }),
        ...(checksFilter.kind_of_entryways && {
          kind_of_entryways: kind_of_entryways.value,
        }),
        ...(checksFilter.status && { status }),
        offset,
        limit,
      };
      
      //-----onChange Event-----//
      if (evt?.target?.name === "limit") {
        // Files per page
        setLimit(evt.target.value);
        setParamsForGetReport2(prevState => ({
          ...prevState,  // Mantén el resto del estado igual
          page_size: evt.target.value
        }));
        setOffset(0);
        setCurrentPage(0);
        setMaxPageNum(pageNumLimit);
        setMinPageNum(0);
        setSearchPag("");
        //localStorage.setItem("limit", evt.target.value);
      } else if (evt?.target?.name === "searchingPage") {
        // Value in input search page
        paramsForGetReport["offset"] = evt.target.value - 1;
        paramsForGetReport["limit"] = limite || 10;
        setCurrentPage(evt.target.value - 1);
        setOffset(evt.target.value - 1);
      } else if (evt?.target?.name === "number") {
        // Index of each page on screen
        paramsForGetReport["offset"] = evt.target.value;
        setOffset(evt.target.value);
      } else if (evt?.currentTarget?.name === "section") {
        // Index for section pages divider
        paramsForGetReport["offset"] = evt.currentTarget.value;
        setOffset(evt.currentTarget.value);
      }
    };
  
    const caseFolioSort = (rowA, rowB) => {
      const a = rowA.user.toLowerCase();
      const b = rowB.user.toLowerCase();
      if (a > b) {
        return 1;
      }
  
      if (b > a) {
        return -1;
      }
  
      return 0;
    };
    const caseCreatedAtSort = (rowA, rowB) => {
      const a = rowA.created_at.toLowerCase();
      const b = rowB.created_at.toLowerCase();
  
      if (a > b) {
        return 1;
      }
  
      if (b > a) {
        return -1;
      }
  
      return 0;
    };
  
    const caseEntryWay = (rowA, rowB) => {
      const a = rowA.id;
      const b = rowB.id;
  
      if (a > b) {
        return 1;
      }
  
      if (b > a) {
        return -1;
      }
  
      return 0;
    };
  
    const caseOpenDays = (rowA, rowB) => {
      const a = rowA.open_days;
      const b = rowB.open_days;
  
      if (a > b) {
        return 1;
      }
  
      if (b > a) {
        return -1;
      }
  
      return 0;
    };
  
    const PresentIndicators = ({ indicators, entryway }) => {
      const indicatorItems = [];
      const iconIndicators = {
        has_plan: {
          text: t("ReportsList.has_plan"),
          element: <OutlineDeviceMessage className="mx-1 icon-indicator" />,
        },
        is_locked: {
          text: t("ReportsList.is_locked"),
          element: <OutlineLock className="mx-1 icon-indicator" />,
        },
        has_message: {
          text: t("ReportsList.has_message"),
          element: <OutlineDirectboxReceive className="mx-1 icon-indicator" />,
        },
        is_severity: {
          text: t("ReportsList.is_severity"),
          element: <OutlineAlarm className="mx-1 icon-indicator-is_severity" />,
        },
        is_anonymous: {
          text: t("ReportsList.is_anonymous"),
          element: <OutlineUserMinus className="mx-1 icon-indicator" />,
        },
        has_complement: {
          text: t("ReportsList.has_complement"),
          element: <Outline3dCubeScan className="mx-1 icon-indicator" />,
        },
        has_attachments: {
          text: t("ReportsList.has_attachments"),
          element: <Paperclip className="mx-1 icon-indicator" />,
        },
      };
  
      const iconEntryway = {
        WEBSITE: {
          element: <Monitor className="mx-1 icon-indicator" />,
        },
        PHONE: {
          element: <Call className="mx-1 icon-indicator" />,
        },
        EMAIL: {
          element: <OutlineSms className="mx-1 icon-indicator" />,
        },
        CHAT: {
          element: <OutlineMessages3 className="mx-1 icon-indicator" />,
        },
        WHATSAPP: {
          element: <OutlineMessages2 className="mx-1 icon-indicator" />,
        },
        APP: {
          element: <Mobile className="mx-1 icon-indicator" />,
        },
        OUTDOOR: {
          element: <Grammerly className="mx-1 icon-indicator" />,
        },
        OTHER: {
          element: <CdIcon className="mx-1 icon-indicator" />,
        },
      };
  
      let severity_element = null;
  
      // Add Entryway in Icon Indicator Form
      indicatorItems.push(
        <MCTooltip
          text={t(`ReportsList.entryways.${entryway}`)}
          position="bottom"
          color
          key={entryway}
        >
          <button className="btnIcon">{/*"iconEntryway[entryway].element"*/}</button>
        </MCTooltip>
      );
  
      if (indicators) {
        for (const [key, value] of Object.entries(indicators)) {
          if (value) {
            if (key !== "is_severity") {
              indicatorItems.push(
                <MCTooltip
                  text={iconIndicators[key].text}
                  position="bottom"
                  color
                  key={key}
                >
                  <button className="btnIcon">
                    {iconIndicators[key].element}
                  </button>
                </MCTooltip>
              );
            } else {
              severity_element = (
                <MCTooltip
                  text={iconIndicators[key].text}
                  position="bottom"
                  color
                  key={key}
                >
                  <button className="btnIcon">
                    {iconIndicators[key].element}
                  </button>
                </MCTooltip>
              );
            }
          }
        }
      }
  
      if (severity_element !== null) indicatorItems.push(severity_element);
  
      if (indicatorItems.length <= 0) {
        return <p className="my-auto"></p>;
      }
  
      return indicatorItems;
    };
  
    //Table columns with react-data-table-component
    const returnColumns = [
        {
        name: <h6>{t(/*"ReportsList.status"*/"Id")}</h6>,
        button: true,
        cell: (row) => {
            return ( <p className="my-auto tabletd">{row.id}</p> );
          },
          sortable: true,
          sortFunction: caseEntryWay,
        },
        {
          name: <h6>{t("Bitacora.event")}</h6>,
          button: true,
          cell: (row) => {
              return (
              
  
                  <p>{t("Rutas"+"."+row.path)}</p>
             
              );
          },
          },
        
        {
        name: <h6>{t("Bitacora.user")}</h6>,
        cell: (row) =>
            row.user ? (
            <p className="my-auto tabletd">{row.user}</p>
            ) : (
            <p className="my-auto tabletd">{t("Bitacora.no_subject")}</p>
            ),
            sortable: true,
            sortFunction: caseFolioSort,
        },
        {
        name: <h6>{t("Bitacora.date")}</h6>,
        selector: (row) =>
            row.created_at ? (
            <p className="my-auto tabletd">
                {parseDateToLgComun(row.created_at)}
            </p>
            ) : (
            <p className="my-auto tabletd">{t("ReportsList.no_date")}</p>
            ),
        sortable: true,
        sortFunction: caseCreatedAtSort,
        },
        {
          name: <h6>{t("Bitacora.params")}</h6>,
          selector: (row) => row.actions,
          cell: (row) =>
              row.actions ? (
                <div className="my-auto tabletd">
                {row.actions && Object.keys(row.actions).length > 0 ? (
                  Object.entries(row.actions).map(([key, value], idx) => (
                    <section key={key}>
                      {key + ": " + value}
                      <br />
                    </section>
                  ))
                ) : (
                  <span colSpan="2">{t("ReportsList.no_folio")}</span>
                )}
              </div>
              ) : (
              <p className="my-auto tabletd">{t("ReportsList.no_folio")}</p>
              ),
          
          },
    ];
    /** ------ PAGINATION ------
     * In this section determines the navigation between pages according to the distribution of the registered reports.
     * This, in turn, is determined with the Count/Limit calculation and the index is assigned to each button in the renderPagesNumber function.
     *  eg: 100/10 = 10 (reports per page)
     */
    //Navigate through the navigation pages
    const handlePagination = (evt) => {
      setSearchPag("");
      let pageNumber = Number(evt.target.id);
      setCurrentPage(pageNumber );
      if(pageNumber ===0){
        pageNumber=pageNumber+1
      }
      handleReCallReports(evt,pageNumber );
    };
    //Change page on previos or next registers
    const handlePage = async (url) => {
        const queryParams = new URLSearchParams(next)
        const paramsForGetReport2= {
            [currentPage ==0? null: "page"]: currentPage ==0? null : currentPage+1,
          }
      setIsLoading(true);
      try {
        await //getData(paramsForGetReport2.page==0? null : paramsForGetReport2);
        console.log("")
      } catch (error) {
        console.error(error); 
      }
    };
    // Distribution of results on pages according to selected rows
    const pages = [];
    const page = count / limit;
    for (let i = 0; i < Math.ceil(page); i++) {
      pages.push(i);
    }
    // Renders the buttons with the page numbers for navigation
    const renderPagesNumber = pages.map((number) => {
      if (number < maxPageNum && number >= minPageNum) {
        return (
          <button
            key={number}
            id={number}
            name="number"
            onClick={handlePagination}
            value={number * limit}
            className={
              currentPage === number
                ? "paginator__btnPag --activate"
                : "paginator__btnPag --scale09"
            }
            disabled={currentPage === number}
          >
            {number + 1}
          </button>
        );
      }
    });
    // Event on pressing 'Enter' key
    useEffect(() => {
      const keyDown = (evt) => {
        if (
          evt.key === "Enter" &&
          (evt.target === inputRef1.current || evt.target === inputRef2.current)
        ) {
          evt.preventDefault(evt);
          handleSearchPage(evt);
        }
      };
      document.addEventListener("keydown", keyDown);
      return () => {
        document.removeEventListener("keydown", keyDown);
      };
    });
    // Searching page on input
    const handleSearchPage = (evt) => {
      setSearchPag("");
      setCurrentPage(evt.target.value - 1);
      handleReCallReports(evt);
      setMaxPageNum(Number(evt.target.value));
      setMinPageNum(Number(evt.target.value) - pageNumLimit);
    };
    // If there are a next page
    const handleNext = () => {
      setCurrentPage(currentPage + 1);
      setOffset((currentPage + 1) * limit);
      handlePage(next);
      setSearchPag("");
      if (currentPage + 1 >= maxPageNum) {
        setMaxPageNum(Number(maxPageNum) + pageNumLimit);
        setMinPageNum(minPageNum + pageNumLimit);
      }
    };
    // If there are a previous page
    const handlePrev = () => {
      setCurrentPage(currentPage - 1);
      setOffset((currentPage - 1) * limit);
      handlePage(previous);
      setSearchPag("");
      if (currentPage - 1 < minPageNum) {
        setMaxPageNum(Number(maxPageNum) - pageNumLimit);
        setMinPageNum(minPageNum - pageNumLimit);
      }
    };
    // Go to the next section of pages
    const handleNextSection = (evt) => {
      setMaxPageNum(maxPageNum + pageNumLimit);
      setMinPageNum(minPageNum + pageNumLimit);
      setCurrentPage(minPageNum + pageNumLimit);
      handleReCallReports(evt);
    };
    // Go to previous section of pages
    const handlePrevSection = (evt) => {
      setMaxPageNum(maxPageNum - pageNumLimit);
      setMinPageNum(minPageNum - pageNumLimit);
      setCurrentPage(maxPageNum - pageNumLimit - 1);
      handleReCallReports(evt);
    };
    // Go to last page
    const handleLastPage = (evt) => {
      setMaxPageNum(pages.length);
      setMinPageNum(pages.length - pageNumLimit);
      handleReCallReports(evt);
      setCurrentPage(pages.length - 1);
    };
    // Go to first page
    const handleFirstPage = (evt) => {
      setMaxPageNum(pageNumLimit);
      setMinPageNum(0);
      handleReCallReports(evt);
      setCurrentPage(0);
    };
    // --- Only if the page limit is greater than 10, the tools are enabled to navigate between results. ---//
    // Navigate between next pages
    let pageIncrement = null;
    if (pages.length > maxPageNum) {
      pageIncrement = (
        <>
          <button
            className="paginator__btnSection --scale09"
            onClick={handleNextSection}
            value={(pageNumLimit + minPageNum) * limit}
            name="section"
          >
            <p>&#8250;&#8250;</p>
          </button>
          <input
            type="number"
            name="searchingPage"
            placeholder="..."
            value={searchPag}
            max={pages.length}
            min={1}
            onChange={(evt) => setSearchPag(evt.target.value)}
            className="paginator__searchPage"
            ref={inputRef2}
          />
          <button
            id={Number(pages.length) - 1}
            className="paginator__btnPag --scale09"
            onClick={handleLastPage}
            value={Math.abs(pages.length - 1) * limit}
            name="number"
          >
            {pages.length}
          </button>
        </>
      );
    }
    // Navigate between previous pages
    let pageDecrement = null;
    if (minPageNum >= 1) {
      pageDecrement = (
        <>
          <button
            className="paginator__btnPag --scale09"
            onClick={handleFirstPage}
            name="number"
            value={0}
          >
            1
          </button>
          <input
            type="number"
            name="searchingPage"
            placeholder="..."
            value={searchPag}
            max={pages.length}
            min={1}
            onChange={(evt) => setSearchPag(evt.target.value)}
            className="paginator__searchPage"
            ref={inputRef1}
          />
          <button
            className="paginator__btnSection --scale09"
            onClick={handlePrevSection}
            value={Math.abs(maxPageNum - pageNumLimit - 1) * limit}
            name="section"
          >
            <p>&#8249;&#8249;</p>
          </button>
        </>
      );
    }
  
    const [isDropdownOpen, setDropdownOpen] = useState(false);
  
    const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);
  
    const handleRowClick = (row) => {
      // Navegar a la ruta del reporte específico
      navigate(`/reports/${row.id}`);
    };

    
    return (
        <div className={`reportList page`}>
      {/* Reports Table Title */}
      
      <GeneralIconHeader
        title={t("Bitacora.title")}
        text={t("Bitacora.no_subject")}
        icon="outline_document_filter"
      />
     
      <Breadcrum items={breadcrumbItems} />
      { showFilters ? (
            <div className="dyTheme1 dyBorder1 p-sm-4 reportsTable spacing">
            <EGDatePicker
            label={t("Bitacora.start_date")}
            value={inicioF}  // Convertir el string a un objeto dayjs
            onChange={(newDate) => setInicioF(newDate)}  // Esta línea debe estar habilitada para que funcione correctamente
            maxDate={today}  // También aquí usa dayjs para minDate
            />
          
           <EGDatePicker
            label={t("Bitacora.end_date")}
            value={finF}  // Convertir el string a un objeto dayjs
            onChange={(newDate) => setFinF(newDate)}  // Esta línea debe estar habilitada para que funcione correctamente
            minDate={inicioF.add(1, 'day')}  // También aquí usa dayjs para minDate
            maxDate={inicioF.add(1, 'month')}  // También aquí usa dayjs para minDate
          />
          
          <button className="btnPlanes " onClick={filterDataDB}>{t("Bitacora.send")}</button>
            <div></div>
            <MCSelect
            className="mt-0 w-100 z-index"
            value={monthSelected}  // Valor seleccionado
            onChange={selectMonth} // Función para manejar el cambio
            options={optionsMonth} // Opciones generadas a partir del objeto
            onKeyDown={handleKeyDown}
          />
          <MCInput
            type="text"
            className="mt-0 w-100 form-control"
            value={searchTerm}
            onChange={/*handleSearch*/  (e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Ingresa correo"
          />
          <button className="btnPlanes " onClick={cleanDataDB}>{t("Bitacora.clean")}</button>
         </div>
      ) :(<></>) }
       
      <div className="dyTheme1 dyBorder1 p-sm-4 reportsTable">
        {isLoading ? (
          <Loading />
        ) : (
          // Start Report Table
          <div>
            {/* Table Sub-header */}
            <div className="dyBorder1 dyTheme2 rounded sub-header my-auto tabletd">
              <div className="sub-header__settings">
                <small className="ml-2 showContent">
                  {`${t("ReportsList.showing")} ${count} ${t(
                    "ReportsList.report"
                  )}${count > 1 ? "s" : ""}`}
                </small>
              </div>
              <div className="filters" onClick={toggleDiv}>
              <small
                id="filter-svg"
                className="d-flex btnFilters"
                //onClick={() => setShowModal(!showModal)}
              >
                {t("Filter.filter")} <Settings3 className="my-auto" />
              </small>
              </div>
            </div>
            {/* TableBody */}
            {!isLoading && listReports && (
              <div className="dataTable">
                <DataTable
                  direction="ltr"
                  subHeaderAlign="left"
                  columns={returnColumns}
                  data={listReports} 
                  fixedHeader
                  responsive
                  highlightOnHover
                  
                />
                {/* Paginator */}
                <nav className="paginator dyTheme1">
                  {/* Select row per page */}
                  <div className="paginator__filesPerPage">
                    <small className="p-1">{t("ReportsList.per_page")}: </small>
                    <select
                      className="paginator__select"
                      name="limit"
                      value={limit}
                      onChange={handleReCallReports}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                  <div className="paginator__btnsAction">
                    <div className="paginator__renderPagesNumber">
                      {pageDecrement}
                      <button
                        className={`paginator__btnSection --scale09 ${
                          !previous ? "--disabled" : ""
                        }`}
                        onClick={handlePrev}
                        disabled={!previous}
                      >
                        <p>&#8249;</p>
                      </button>
                      {renderPagesNumber}
                      <button
                        className={`paginator__btnSection --scale09 ${
                          !next ? "--disabled" : ""
                        }`}
                        onClick={handleNext}
                        disabled={!next}
                      >
                        <p>&#8250;</p>
                      </button>
                      {pageIncrement}
                    </div>
                  </div>
                  <div>
                    <small className="paginator__results">
                      {`${t("ReportsList.results")}: ${
                        count === 0 ? 0 : Number(offset) + 1
                      } ${t("ReportsList.to")} ${
                        listReports.length + Number(offset)
                      } ${t("ReportsList.of")} ${Number(count)}`}
                    </small>
                  </div>
                </nav>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
        
      );
}