import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map.js";
import map from "@amcharts/amcharts5-geodata/mexicoLow.js";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "../colors"; // Importar los colores definidos




export const GeoXTypeChart = ({ chartData, startDate, endDate }) => {
  const [chart, setChart] = useState(null);
  const chartDivId = useRef(`geoxtypechart-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
  const diaInicio = startDate
  const diaFin = endDate
  const graphicTitle = chartData.title


  useEffect(() => {
    let newChart = null;

    const generateChart = async () => {
      try {


        var startDate = new Date(diaInicio).getTime();
        var endDate = new Date(diaFin).getTime();
        const endDatePlusOneDay = new Date(endDate);
        endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


        const root = am5.Root.new(chartDivId.current);
        root.setThemes([MyTheme.new(root)]);

        newChart = root.container.children.push(
          am5map.MapChart.new(root, {
            projection: am5map.geoMercator()
          })
        );
        var polygonSeries = newChart.series.push(
          am5map.MapPolygonSeries.new(root, {
            geoJSON: map
          })
        );
        polygonSeries.mapPolygons.template.setAll({
          fill: root.interfaceColors.get("alternativeBackground"),
          fillOpacity: 0.15,
          strokeWidth: 0.5,
          stroke: root.interfaceColors.get("background"),

        });
        var circleTemplate = am5.Template.new({
          tooltipText: "{name}: {value}"
        });
        var bubbleSeries = newChart.series.push(
          am5map.MapPointSeries.new(root, {
            calculateAggregates: true,
            valueField: "value",
            polygonIdField: "id",

          })
        );
        bubbleSeries.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 10,
              templateField: "circleTemplate",


            }, circleTemplate),
          });
        });

        bubbleSeries.set("heatRules", [{
          target: circleTemplate,
          min: 3,
          max: 30,
          key: "radius",
          dataField: "value"
        }]);



        function interpolateColor(value, min, max) {
          const mid = (max + min) / 2;

          let r, g, b;

          if (value <= mid) {
            // De naranja a rojo
            r = 255;
            g = Math.round(255 - 255 * ((value - min) / (mid - min)));
            b = 0;
          } else {
            // De rojo a naranja
            r = Math.round(255 * ((max - value) / (max - mid)));
            g = 255;
            b = 0;
          }

          // Asegurarse de que los valores estén en el rango 0-255
          r = Math.max(0, Math.min(255, r));
          g = Math.max(0, Math.min(255, g));
          b = Math.max(0, Math.min(255, b));

          return `rgb(${r},${g},${b})`;
        }


        var colors = am5.ColorSet.new(root, {});
        const minLength = 1;
        const maxLength = Math.max(...chartData.data
          .filter(item => item.data.length !== 0)
          .map(item => item.data[0].filter((value, index) => {
            const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
            if (startDate === 0 && endDate === 0) {
              return true;
            } else {
              return date >= startDate && date <= endDatePlusOneDay;
            }
          }).length)
        );


        const processedDatados = chartData.data
          .filter(item => item.data.length !== 0);


        const processedData = chartData.data
          .filter(item => item.data.length !== 0)
          .map((item) => {
            if (item.data[2].length === 0) {
              return {
                category: item.label,
                circleTemplate: {
                  fill: am5.color(interpolateColor(0, minLength, maxLength)),
                  tooltipText: "{name}: {value}"
                },
                value: 0,
                id: null,
                name: item.label,
              };
            }

            const filteredData = item.data[2].filter(obj => obj.catalogue === 'RD-102107099');
            var valueState = filteredData.length > 0 ? filteredData[0].value.split('::').pop() : null;

            const filteredItems = item.data[0].filter((value, index) => {
              let valuecito = item.data[5][index]['path'] === "type::hight" ? 1 : 0;
              return valuecito > 0 ? valuecito : 0;
            });

            const value = filteredItems.filter((value, index) => {
              const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
              if (startDate === 0 && endDate === 0) {
                return true;
              } else {
                return date >= startDate && date <= endDatePlusOneDay;
              }
            }).length;
            const circleColor = interpolateColor(value, minLength, maxLength);
            return {
              category: item.label,
              circleTemplate: {
                fill: am5.color(circleColor),
                tooltipText: "{name}: {value}"
              },
              value: value,
              id: valueState,
              name: item.label,
            };
          });

        const filteredProcessedData = processedData.filter(item => item.value !== 0);

        bubbleSeries.data.setAll(filteredProcessedData);

        // var exporting = am5plugins_exporting.Exporting.new(root, {
        //   menu: am5plugins_exporting.ExportingMenu.new(root, {
        //     align: "right",
        //     valign: "bottom"
        //   })
        // });

        var title = newChart.children.push(am5.Label.new(root, {
          text: graphicTitle,
          fontSize: 20,
          x: am5.percent(50),
          y: am5.percent(0),
          centerX: am5.percent(50),
          visible: false // Ocultar el título inicialmente
        }));

        // Configuración de exportación
        var exporting = am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
            align: "right",
            valign: "bottom"
          }),
          pdfOptions: {
            addURL: false
          }
        });

        // Evento para mostrar el título solo durante la exportación a PDF
        exporting.events.on("exportstarted", function (event) {
          if (event.format === "pdf") {
            title.set("visible", true); // Mostrar el título solo al exportar a PDF
          }
        });

        exporting.events.on("exportfinished", function (event) {
          title.set("visible", false); // Ocultar el título nuevamente después de exportar
        });






        newChart.legend = am5.Legend.new(root, {});
        newChart.appear(1000, 100);
        if (chart) {
          chart.dispose();
        }
        setChart(newChart);
      } catch (error) {
        console.error("Error al cargar las bibliotecas de AmCharts:", error);
      }
    };


    generateChart();

    return () => {
      am5.array.each(am5.registry.rootElements, function (root) {
        if (root) {
          if (root.dom.id == chartDivId.current) {
            root.dispose();
          }
        }
      });

      if (chart) {
        chart.dispose();
      }
    };
  }, [chartData, startDate, endDate]);

  return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default GeoXTypeChart;