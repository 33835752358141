import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "../colors"; // Importar los colores definidos

export const DispersionChart = ({ chartData, startDate, endDate }) => {
  // console.log("CHARTDATA", chartData);

  const [chart, setChart] = useState(null);
  const chartDivId = useRef(
    `DispersionChart-${Math.random().toString(36).substr(2, 9)}`
  ); // Genera un ID único para cada gráfico
  const diaInicio = startDate;
  const diaFin = endDate;
  useEffect(() => {
    let newChart = null;

    const generateChart = async () => {
      try {
        var startDate = new Date(diaInicio).getTime();
        var endDate = new Date(diaFin).getTime();
        const endDatePlusOneDay = new Date(endDate);
        endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);

        const root = am5.Root.new(chartDivId.current);
        // root.setThemes([am5themes_Animated.new(root)]);
        root.setThemes([MyTheme.new(root)]);

        newChart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            layout: root.verticalLayout,
            wheelY: "zoomXY",
            pinchZoomX: true,
            pinchZoomY: true,
          })
        );

        var cursor = newChart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", true);

        var date = new Date();
        date.setHours(0, 0, 0, 0);

        function removeDuplicatesByValue(newData) {
          const uniqueValues = new Set();
          const uniqueData = [];

          newData.forEach(item => {
            if (!uniqueValues.has(item.value)) {
              uniqueValues.add(item.value);
              uniqueData.push(item);
            }
          });

          return uniqueData;
        }

        var date; // Declaramos date aquí para que sea accesible fuera del bucle forEach
        const newData = chartData.data.reduce((acc, item) => {
          if (item.label === "Todos los Reportes" || item.label === "Ofensores por Reportes") {
              item.data[0].forEach((_, index) => {
                  if (item.label === "Todos los Reportes") {
                      date = new Date(Date.now() - item.data[1][index] * 24 * 60 * 60 * 1000);
                  }
      
                  const listita = [];
                  const severity = [];
                  let color = "";
      
                  chartData.data.forEach((innerItem) => {
                      if (innerItem.label === "Todos los Reportes") {
                          innerItem.data[5].forEach((subelement, index) => {
                              
                        
                                      if (subelement.path == "type::hight" || subelement.label == "Alta") {
                                          color = "#FF0B0B";
                                          subelement.value = "Alta";
                                      } else if (subelement.path == "type::low" || subelement.label == "Baja") {
                                          color = "#0FFF0B";
                                          subelement.value = "Baja";
                                      } else if (subelement.path == "type::medium" || subelement.label == "Media") {
                                          color = "#FFB50B";
                                          subelement.value = "Media";
                                      } else if (subelement.path == "type::null" || subelement.label == "Nula") {
                                          color = "#0BFFF4";
                                          subelement.value = "Nula";
                                      } else if (subelement.path == "type::other" || subelement.label == "Otra") {
                                          color = "#C40BFF";
                                          subelement.value = "Otra";
                                      }
                                      severity.push({
                                          id_report: innerItem.data[0][index],
                                          severity: subelement.value,
                                          color: color
                                      });
                                  
                       
                          });
      
                          innerItem.data[0].forEach((element, index) => {
                              const elementId = element;
                              const date = new Date(Date.now() - innerItem.data[1][index] * 24 * 60 * 60 * 1000).getTime();
                              const severidadReporte = severity.filter((item) => item.id_report === elementId);
                              if (severidadReporte.length > 0 && severidadReporte[0].severity !== "Otra") {
                                  listita.push({
                                      date: date,
                                      value: elementId,
                                      severity: severidadReporte[0].severity,
                                      color: severidadReporte[0].color
                                  });
                              }
                          });
                      }
                  });
      
                  // Conteo de coincidencias en gravedad
                  const severityCounts = {};
                  listita.forEach(item => {
                      const key = item.date + '-' + item.severity;
                      severityCounts[key] = Math.max((severityCounts[key] || 0), listita.filter(i => i.date === item.date && i.severity === item.severity).length);
                      item.severityCount = severityCounts[key];
                  });
      
                  acc.push(...listita);
              });
          }
          return acc;
      }, []);

        const newDataWithoutDuplicates = removeDuplicatesByValue(newData);





        // Transformar los datos a un formato final
        const transformedData = newDataWithoutDuplicates.map((data) => ({
          value: data.value,
          date: new Date(data.date).getTime(),
          severity: data.severity,
          color: data.color,
          severityCount: data.severityCount,
        }));
        transformedData.sort((a, b) => b.date - a.date);


        var cursor = newChart.set(
          "cursor",
          am5xy.XYCursor.new(root, {
            behavior: "none",
          })
        );
        cursor.lineY.set("visible", true);

        const xAxis = newChart.xAxes.push(am5xy.DateAxis.new(root, {
          maxDeviation: 0,
          baseInterval: {
            timeUnit: "day",
            count: 1
          },
          renderer: am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
            minGridDistance: 200,
            minorLabelsEnabled: true
          }),
          tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.set("minorDateFormats", {
          day: "dd",
          month: "MM"
        });

        // var yAxis = newChart.yAxes.push(
        //   am5xy.ValueAxis.new(root, {
        //     renderer: am5xy.AxisRendererY.new(root, {
        //       inversed: false,
        //     }),
        //     tooltip: am5.Tooltip.new(root, {}),
        //   })
        // );

       
        // yAxis.children.moveValue(
        //   am5.Label.new(root, {
        //     rotation: -90,
        //     text: "Numero de implicados",
        //     y: am5.p50,
        //     centerX: am5.p50,
        //   }),
        //   0
        // );

        var yRenderer = am5xy.AxisRendererY.new(root, {
          visible: false,
          minGridDistance: 20,
          inversed: true
        });
        
        yRenderer.grid.template.set("visible", false);
        
        var yAxis = newChart.yAxes.push(
          am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            renderer: yRenderer,
            categoryField: "severity"
          })
        );


        var series = newChart.series.push(
          am5xy.LineSeries.new(root, {
            calculateAggregates: true,
            xAxis: xAxis,
            yAxis: yAxis,
            // valueYField: "value", //este es el valor correcto
            categoryYField: "severity", //este es el valor correcto
            valueXField: "date", //este es el valo correcto
            valueField: "value",
            seriesTooltipTarget: "bullet",
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: "horizontal",
              labelText:
                "[bold]{title}[/]Numero de Reportes: {severityCount}",
            }),
          })
        );

        series.strokes.template.set("visible", false);

        var circleTemplate = am5.Template.new({});
        circleTemplate.adapters.add("fill", function (fill, target) {
          var dataItem = target.dataItem;
          if (dataItem) {
            return am5.Color.fromString(dataItem.dataContext.color);
          }
          return fill;
        });
        series.bullets.push(function () {
          var bulletCircle = am5.Circle.new(
            root,
            {
              radius: 5,
              fill: series.get("fill"),
              fillOpacity: 0.8,
            },
            circleTemplate
          );
          return am5.Bullet.new(root, {
            sprite: bulletCircle,
          });
        });

        series.set("heatRules", [
          {
            target: circleTemplate,
            min: 10,
            max: 10,
            dataField: "value",
            key: "radius",
          },
        ]);


        newChart.set(
          "cursor",
          am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            snapToSeries: [series],
          })
        );

        newChart.set(
          "scrollbarX",
          am5.Scrollbar.new(root, {
            orientation: "horizontal",
          })
        );

        newChart.set(
          "scrollbarY",
          am5.Scrollbar.new(root, {
            orientation: "vertical",
          })
        );


        yAxis.data.setAll([
          { severity: "Alta" },
          { severity: "Media" },
          { severity: "Baja" },
          { severity: "Nula" },
        ]);

        series.data.setAll(
            transformedData.map((item) => ({
                value: item.value,
                date: item.date,
                color: item.color,
                severity: item.severity,
                severityCount: item.severityCount,
            }))
        );

        var exporting = am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
            align: "right",
            valign: "bottom",
          }),
        });

        series.appear(1000);
        newChart.appear(1000, 100);

        if (chart) {
          chart.dispose();
        }

        setChart(newChart);
      } catch (error) {
        console.error("Error al cargar las bibliotecas de AmCharts:", error);
      }
    };

    if (typeof window !== "undefined") {
      generateChart();
    }

    return () => {
      am5.array.each(am5.registry.rootElements, function (root) {
        if (root) {
          if (root.dom.id == chartDivId.current) {
            root.dispose();
          }
        }
      });

      if (chart) {
        chart.dispose();
      }
    };
  }, [chartData, startDate, endDate]);

  return (
    <div
      id={chartDivId.current}
      style={{ width: "100%", height: "500px" }}
    ></div>
  );
};

export default DispersionChart;
