import _ from "lodash";
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { patchInvestigation } from "../../../../../apis";
import { MCInput } from "../../../../MainComponents/Input/Input";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { MCTooltip } from "../../../../MainComponents/Tooltip/Tooltip";
import { EGIconButton } from "../../../../MainComponents/Button/IconButton/IconButton";
import { useTranslation } from "react-i18next";
import { getInvestigation } from "../../../../../apis";
import { CloseIcon } from "../../../../../components/Icon";



export const AddPlan = ({ investigationAlias, formValues, toggleModal }) => {
  const [investigation, setInvestigation] = useState("");
  useEffect(() => {

    const onMounted = async () => {
      try {
        const investigation = await getInvestigation(investigationAlias);
        if (investigation) {
          setInvestigation(investigation?.response?.plans);
        }
      } catch (err) {
        console.log(err);
      }
    };
    onMounted();


  }, []);



  const [t] = useTranslation("investigation");

  const [kind, setKind] = useState(""); // pending || process || completed
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const [todos, setTodos] = useState([]);
  const [todoInput, setTodoInput] = useState("");
  const [party, setParty] = useState([]);
  const [partyInput, setPartyInput] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editIndexParty, setEditIndexParty] = useState(null);

  const handleSubmit = async (evt) => {
    let newPlans;
    evt.preventDefault();
    if (investigation === null) {
      newPlans = [];
    } else {
      newPlans = _.cloneDeep(investigation);
    }
    // console.log("NEW PLANS:", investigation);
    // newPlans.push({
    //   kind,
    //   name,
    //   details,
    //   deadline: 0,
    //   party: ["vbn"],
    //   todo: ["fgh"],
    //   status: "pending",
    //   created_at: new Date(),
    // });
    newPlans.push({
      kind,
      name,
      details,
      deadline: 0,
      party,
      todos,
      status: "pending",
      created_at: new Date(),
    });

    const objToSave = {
      folder: {
        plans: newPlans,
      },
    };
    try {
      const resp = await patchInvestigation(investigationAlias, objToSave);
    } catch (error) {
      console.log(error);
    }
    toggleModal(false);
  };




  const handleAddTodo = () => {
    if (todoInput.trim() === "") {
      // Evitar que se agregue una cadena vacía o espacios
      return;
    }
  
    if (editIndex !== null) {
      // Edit existing todo
      const updatedTodos = todos.map((todo, index) =>
        index === editIndex ? todoInput : todo
      );
      setTodos(updatedTodos);
      setEditIndex(null);
    } else {
      // Add new todo
      setTodos([...todos, todoInput]);
    }
  
    setTodoInput("");
  };

  const handleEditTodo = (index) => {
    setTodoInput(todos[index]);
    setEditIndex(index);
  };

  const handleDeleteTodo = (index) => {
    setTodos(todos.filter((_, i) => i !== index));
  };







  const handleAddParty = () => {
    if (partyInput.trim() === "") {
      // Evitar que se agregue una cadena vacía o espacios
      return;
    }
    if (editIndexParty !== null) {
      const updatedParty = party.map((party, index) =>
        index === editIndexParty ? partyInput : party
      );
      setParty(updatedParty);
      setEditIndexParty(null);
    } else {
      setParty([...party, partyInput])
    }
    setPartyInput("");
  };

  const handleDeleteParty = (index) => {
    setParty(party.filter((_, i) => i !== index));
  };


  const handleEditParty = (index) => {
    setPartyInput(party[index]);
    setEditIndexParty(index);
  };


  const initialDate = new Date();

  return (
    <div className="modal-add-plan">
      <Row>
        <h5>{t("Plan.add_activity_plan")}</h5>
      </Row>

      <Row>
        <Col md={6} lg={6} className="input-container">
          <small className="my-2 d-block">{t("Plan.date")}:</small>
          <MCInput type="date" selected={initialDate} />
        </Col>
        <Col md={6} lg={6} className="input-container">
          <small className="my-2 d-block">{t("Plan.name")}:</small>
          <MCInput
            type="text"
            placeholder={t("Plan.enter_plan_name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Col>
        <Col md={12} lg={12} className="input-container">
          <small className="my-2 d-block">{t("Plan.description")}:</small>
          <MCInput
            type="textarea"
            placeholder={t("Plan.enter_plan_description")}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </Col>







        <Col md={6} lg={6} className="input-container">
          <small className="my-2 d-block">
            {t("Plan.to_do_list")}:
          </small>

          <ul>
            {todos.map((item, idx) => (
              <li key={idx} className="d-flex align-items-center">
                <span>{item}</span>
                {/* <MCButton
                  label=""
                  icon="edit_icon"
                  variant="secondary"
                  size="sm"
                  className="ms-2"
                  onClick={() => handleEditTodo(idx)}
                /> */}
                {/* <MCButton
            label=""
            icon={<CloseIcon />} 
            variant="danger"
            size="sm"
            className="ms-2"
            onClick={() => handleDeleteTodo(idx)}
          /> */}
                <div>
                <EGIconButton
                    iconName={"outline_edit"}
                    title={t("Plan.edit")}
                    placement="bottom"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditTodo(idx)}
                    />
                  <EGIconButton
                    onClick={() => handleDeleteTodo(idx)}
                    style={{ 
                      cursor: "pointer",
                      maxWidth: "24px"
                    }}
                    color="error"
                    iconColor="white"
                    title={t("Plan.cancel")}
                    iconName="close_icon"
                  />
                  {/* <CloseIcon /> */}
                </div>

              </li>
            ))}
          </ul>
          <div className="d-flex align-items-center">
            <MCInput
              type="text"
              placeholder={t("Plan.enter_to_do")}
              value={todoInput}
              onChange={(e) => setTodoInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAddTodo();
                }
              }}
            />
            <MCTooltip position="bottom" text={t("Plan.add_task")}>
              <div className="me-2">
                <MCButton
                  label=""
                  icon="add_icon"
                  variant="primary"
                  size="sm"
                  className="add-icon ms-1 p-0"
                  width={34}
                  outline
                  onClick={handleAddTodo}
                />
              </div>

          


            </MCTooltip>
          </div>
        </Col>













        <Col md={6} lg={6} className="input-container">
          <small className="my-2 d-block">
            {t("Plan.add_people_task")}:
          </small>
          <ul>
            {party.map((item, idx) => (
              <li key={idx} className="d-flex align-items-center">
                <span>{item}</span>
                <div>
                <EGIconButton
                    iconName={"outline_edit"}
                    title={t("Plan.edit")}
                    placement="bottom"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditParty(idx)}
                    />
                  <EGIconButton
                    onClick={() => handleDeleteParty(idx)}
                    style={{ 
                      cursor: "pointer",
                      maxWidth: "24px"
                    }}
                    color="error"
                    iconColor="white"
                    title={t("Plan.cancel")}
                    iconName="close_icon"
                  />
                  {/* <CloseIcon /> */}
                </div>
               
              </li>
            ))}
          </ul>
          <div className="d-flex align-items-center">
            <MCInput
              type="text"
              placeholder={t("Plan.select_people_task")}
              value={partyInput}
              onChange={(e) => setPartyInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAddParty();
                }
              }}
            />
            <MCTooltip position="bottom" text={t("Plan.add_person")}>
              <div className="me-2">
                <MCButton
                  label=""
                  icon="add_icon"
                  variant="primary"
                  size="sm"
                  className="add-icon ms-1 p-0"
                  width={34}
                  outline
                  onClick={handleAddParty}
                />
              </div>
            </MCTooltip>
          </div>
        </Col>







      </Row>

      <Row>
        <div className="mt-4 d-flex justify-content-end">
          <MCButton
            label={t("Plan.cancel")}
            variant="primary"
            outline
            onClick={() => toggleModal("just-close")}
            className="me-2"
          />
          <MCButton
            label={t("Plan.save")}
            variant="primary"
            outline
            onClick={handleSubmit}
          />
        </div>
      </Row>
    </div>
  );
};
