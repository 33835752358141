import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos

export const GeneroChart = ({ chartData, startDate, endDate }) => {
    const [chart, setChart] = useState(null);
    const chartDivId = useRef(`vertical-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
    const diaInicio = startDate
    const diaFin = endDate
    const graphicTitle = chartData.title



    useEffect(() => {
        let newChart = null;

        const generateChart = async () => {
            try {

                // console.log("CHARTDATA", chartData);


                var startDate = new Date(diaInicio).getTime();
                var endDate = new Date(diaFin).getTime();
                const endDatePlusOneDay = new Date(endDate);
                endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


                const root = am5.Root.new(chartDivId.current);
                root.setThemes([MyTheme.new(root)]);

                newChart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        panX: false,
                        panY: false,
                        wheelX: "panX",
                        wheelY: "zoomX",
                        layout: root.verticalLayout
                    })
                );

                // console.log("chart created", chartData);

                const newData = chartData.data.map((item) => ({
                    category: item.label,
                    value: item.data[0].filter((value, index) => {
                        const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
                        if (startDate === 0 && endDate === 0) {
                            return true;
                        } else {
                            return date >= startDate && date <= endDatePlusOneDay;
                        }
                    }).length,
                }));

                // console.log("chart newData", newData);

                let totalPersonas = 0
                totalPersonas = newData[0]["value"] + newData[1]["value"]
                let cienpersonas = 0
                if (totalPersonas < 100)
                    cienpersonas = 10;

                // console.log("TOTAL PERSONAS", totalPersonas);
                let cantidadHombres = newData[1]["value"];
                let cantidadMujeres = newData[0]["value"];



                var rowSize = 10;
                var colSize = 1;

                function generateData(count) {
                    var row = 1;
                    var col = 1;
                    var data = [];
                    for (var i = 0; i < count; i++) {
                        data.push({
                            x: col + "",
                            y: row + ""
                        });
                        col++;
                        if (col > rowSize) {
                            row++;
                            col = 1;
                        }
                    }
                    return data;
                }

                function generateCategories(count) {
                    var data = [];
                    for (var i = 0; i < count; i++) {
                        data.push({
                            cat: (i + 1) + ""
                        });
                    }
                    return data;
                }



                var xAxis = newChart.xAxes.push(am5xy.CategoryAxis.new(root, {
                    categoryField: "cat",
                    renderer: am5xy.AxisRendererX.new(root, {})
                }));
                var xRenderer = xAxis.get("renderer");
                xRenderer.labels.template.set("forceHidden", true);
                xRenderer.grid.template.set("forceHidden", true);
                xAxis.data.setAll(generateCategories(rowSize));

                var yAxis1 = newChart.yAxes.push(am5xy.CategoryAxis.new(root, {
                    categoryField: "cat",
                    renderer: am5xy.AxisRendererY.new(root, {})
                }));
                var yRenderer1 = yAxis1.get("renderer");
                yRenderer1.labels.template.set("forceHidden", true);
                yRenderer1.grid.template.set("forceHidden", true);
                yAxis1.data.setAll(generateCategories(colSize));

                yAxis1.children.unshift(
                    am5.Label.new(root, {
                        text: "[#247ba0]Hombres[/]\n[#247ba0]" + cantidadHombres + "[/][#999999]/" + totalPersonas + "[/]",
                        fontSize: 32,
                        y: am5.p50,
                        centerY: am5.p50
                    })
                );

                var yAxis2 = newChart.yAxes.push(am5xy.CategoryAxis.new(root, {
                    categoryField: "cat",
                    renderer: am5xy.AxisRendererY.new(root, {}),
                    marginTop: 20
                }));
                var yRenderer2 = yAxis2.get("renderer");
                yRenderer2.labels.template.set("forceHidden", true);
                yRenderer2.grid.template.set("forceHidden", true);
                yAxis2.data.setAll(generateCategories(colSize));

                yAxis2.children.unshift(
                    am5.Label.new(root, {
                        text: "[#f25f5c]Mujeres[/]\n[#f25f5c]" + cantidadMujeres + "[/][#999999]/" + totalPersonas + "[/]",
                        fontSize: 32,
                        y: am5.p50,
                        centerY: am5.p50
                    })
                );

                newChart.leftAxesContainer.set("layout", root.verticalLayout);

                function makeSeries(name, yAxis, data, color, path) {
                    var series = newChart.series.push(am5xy.ColumnSeries.new(root, {
                        name: name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        categoryYField: "y",
                        openCategoryYField: "y",
                        categoryXField: "x",
                        openCategoryXField: "x",
                        clustered: false
                    }));

                    series.columns.template.setAll({
                        width: am5.percent(100),
                        height: am5.percent(100),
                        fillOpacity: 0,
                        strokeOpacity: 0
                    });


                    series.bullets.push(function (root) {
                        return am5.Bullet.new(root, {
                            locationX: 0.5,
                            locationY: 0.5,
                            sprite: am5.Graphics.new(root, {
                                fill: color,
                                svgPath: path,
                                centerX: am5.p50,
                                centerY: am5.p50,
                                scale: 0.8
                            })
                        });

                    });

                    series.data.setAll(data);

                    series.appear();
                    return series;
                }


                var femaleColor = am5.color(0xf25f5c);
                var maleColor = am5.color(0x247ba0);
                var placeholderColor = am5.color(0x999999);

                var maleIcon = "M 25.1 10.7 c 2.1 0 3.7 -1.7 3.7 -3.7 c 0 -2.1 -1.7 -3.7 -3.7 -3.7 c -2.1 0 -3.7 1.7 -3.7 3.7 C 21.4 9 23 10.7 25.1 10.7 z M 28.8 11.5 H 25.1 h -3.7 c -2.8 0 -4.7 2.5 -4.7 4.8 V 27.7 c 0 2.2 3.1 2.2 3.1 0 V 17.2 h 0.6 v 28.6 c 0 3 4.2 2.9 4.3 0 V 29.3 h 0.7 h 0.1 v 16.5 c 0.2 3.1 4.3 2.8 4.3 0 V 17.2 h 0.5 v 10.5 c 0 2.2 3.2 2.2 3.2 0 V 16.3 C 33.5 14 31.6 11.5 28.8 11.5 z";
                var femaleIcon = "M 18.4 15.1 L 15.5 25.5 c -0.6 2.3 2.1 3.2 2.7 1 l 2.6 -9.6 h 0.7 l -4.5 16.9 H 21.3 v 12.7 c 0 2.3 3.2 2.3 3.2 0 V 33.9 h 1 v 12.7 c 0 2.3 3.1 2.3 3.1 0 V 33.9 h 4.3 l -4.6 -16.9 h 0.8 l 2.6 9.6 c 0.7 2.2 3.3 1.3 2.7 -1 l -2.9 -10.4 c -0.4 -1.2 -1.8 -3.3 -4.2 -3.4 h -4.7 C 20.1 11.9 18.7 13.9 18.4 15.1 z M 28.6 7.2 c 0 -2.1 -1.6 -3.7 -3.7 -3.7 c -2 0 -3.7 1.7 -3.7 3.7 c 0 2.1 1.6 3.7 3.7 3.7 C 27 10.9 28.6 9.2 28.6 7.2 z";


                cantidadHombres = Math.floor((cantidadHombres / totalPersonas) * 10);
                cantidadMujeres = Math.floor((cantidadMujeres / totalPersonas) * 10);

                if (cienpersonas === 10) {
                    var maleSeriesMax = makeSeries("Male", yAxis1, generateData(cienpersonas), placeholderColor, maleIcon, false);
                    var maleSeries = makeSeries("Male", yAxis1, generateData(cantidadHombres), maleColor, maleIcon, true);
                    var femaleSeriesMax = makeSeries("Female", yAxis2, generateData(cienpersonas), placeholderColor, femaleIcon, false);
                    var femaleSeries = makeSeries("Female", yAxis2, generateData(cantidadMujeres), femaleColor, femaleIcon, true);
                } else {
                    var maleSeriesMax = makeSeries("Male", yAxis1, generateData(totalPersonas), placeholderColor, maleIcon, false);
                    var maleSeries = makeSeries("Male", yAxis1, generateData(cantidadHombres), maleColor, maleIcon, true);
                    var femaleSeriesMax = makeSeries("Female", yAxis2, generateData(totalPersonas), placeholderColor, femaleIcon, false);
                    var femaleSeries = makeSeries("Female", yAxis2, generateData(cantidadMujeres), femaleColor, femaleIcon, true);
                }


                // var exporting = am5plugins_exporting.Exporting.new(root, {
                //     menu: am5plugins_exporting.ExportingMenu.new(root, {
                //         align: "right",
                //         valign: "bottom"
                //     })
                // });


                var title = newChart.children.push(am5.Label.new(root, {
                    text: graphicTitle,
                    fontSize: 20,
                    x: am5.percent(50),
                    y: am5.percent(0),
                    centerX: am5.percent(50),
                    visible: false // Ocultar el título inicialmente
                }));

                // Configuración de exportación
                var exporting = am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {
                        align: "right",
                        valign: "bottom"
                    }),
                    pdfOptions: {
                        addURL: false
                    }
                });

                // Evento para mostrar el título solo durante la exportación a PDF
                exporting.events.on("exportstarted", function (event) {
                    if (event.format === "pdf") {
                        title.set("visible", true); // Mostrar el título solo al exportar a PDF
                    }
                });

                exporting.events.on("exportfinished", function (event) {
                    title.set("visible", false); // Ocultar el título nuevamente después de exportar
                });





                newChart.appear(1000, 100);


                if (chart) {
                    chart.dispose();
                }

                setChart(newChart);
            } catch (error) {
                console.error("Error al cargar las bibliotecas de AmCharts:", error);
            }
        };

        if (typeof window !== 'undefined') {
            generateChart();
        }

        return () => {

            am5.array.each(am5.registry.rootElements, function (root) {
                if (root) {
                    if (root.dom.id == chartDivId.current) {
                        root.dispose();
                    }
                }
            });



            if (chart) {
                chart.dispose();
            }
        };
    }, [chartData, startDate, endDate]);

    return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default GeneroChart;