import { useEffect, useState } from 'react';
import { Indicators } from '../../Indicator/Indicators';
import { TableIndicator } from './tableIndicators/TableIndicator';

export const GraphicComplements = ({ chartData, right = false }) => {
    const [has_indicators, setHasIndicators] = useState(false);

    useEffect(() => {
        if (Object.keys(chartData.indicators).length > 0) setHasIndicators(true);
    }, []);

    return (
        has_indicators ?
        <Indicators indicatorsData={chartData.indicators} right />
        :
        <TableIndicator dataTable={chartData} />
    );
};