import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as icon from "./../../../../../assets/icons/icon-map.js";
// import * as icon from "./../../../../../assets/icons/vuesax-outline-security-time.svg"
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme, barrasestadostackchart } from "./../colors"; // Importar los colores definidos


export const BarrasEstadoStack = ({ chartData, startDate, endDate }) => {
    const [chart, setChart] = useState(null);
    const chartDivId = useRef(`BarrasEstadoStack-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico

    const diaInicio = startDate
    const diaFin = endDate


    useEffect(() => {
        let newChart = null;

        const generateChart = async () => {
            try {


                var startDate = new Date(diaInicio).getTime();
                var endDate = new Date(diaFin).getTime();
                const endDatePlusOneDay = new Date(endDate);
                endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


                const root = am5.Root.new(chartDivId.current);
                // root.setThemes([am5themes_Animated.new(root)]);
                root.setThemes([barrasestadostackchart.new(root)]);



                const newDataValue = chartData.data.map((item) => {
                    return {
                        category: item.label,
                        value: item.data[0].filter((value, index) => {
                            const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
                            if (startDate === 0 && endDate === 0) {
                                return true;
                            } else {
                                return date >= startDate && date <= endDatePlusOneDay;
                            }
                        }).length,
                        metadata: item.data[2],
                        severity: item.data[5]

                    };
                });
                let listita = [];
                newDataValue.forEach(element => {
                    if (element.value !== 0) {
                        listita.push({
                            category: element.category,
                            value: element.value,
                            metadata: element.metadata,
                            severity: element.severity
                        })
                    }
                });

                let listitaCompleta = [];






                listita.forEach(element => {
                    let countLow = 0;
                    let countMedium = 0;
                    let countHigh = 0;
                    let countOther = 0;

                    element.severity.forEach(subelement => {
                        switch (subelement.path) {
                            case "type::low":
                                countLow++;
                                break;
                            case "type::medium":
                                countMedium++;
                                break;
                            case "type::hight":
                                countHigh++;
                                break;
                            default:
                                break;
                        }
                    });
                    if (countLow == 0) {
                        countLow = undefined;
                    }
                    if (countMedium == 0) {
                        countMedium = undefined;
                    }
                    if (countHigh == 0) {
                        countHigh = undefined;
                    }
                    if (countLow !== undefined || countMedium !== undefined || countHigh !== undefined) {
                        listitaCompleta.push({
                            state: element.category,
                            low: countLow,
                            medium: countMedium,
                            high: countHigh,
                        });
                    }
                });

                // Función para obtener la suma total de denuncias
                function sumaDenuncias(item) {
                    return (item.low || 0) + (item.medium || 0) + (item.high || 0);
                }

                // Ordenar la lista `listitaCompleta` por la suma de denuncias de menor a mayor
                listitaCompleta.sort((a, b) => sumaDenuncias(a) - sumaDenuncias(b));



                newChart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        panX: false,
                        panY: false,
                        wheelX: "panY",
                        wheelY: "zoomY",
                        paddingLeft: 0,
                        layout: root.verticalLayout
                    })
                );

                newChart.set("scrollbarY", am5.Scrollbar.new(root, {
                    orientation: "vertical"
                }));


                var yRenderer = am5xy.AxisRendererY.new(root, {});
                var yAxis = newChart.yAxes.push(am5xy.CategoryAxis.new(root, {
                    categoryField: "state",
                    renderer: yRenderer,
                    tooltip: am5.Tooltip.new(root, {})
                }));

                yRenderer.grid.template.setAll({
                    location: 1
                })

                yAxis.data.setAll(listitaCompleta);

                var xAxis = newChart.xAxes.push(am5xy.ValueAxis.new(root, {
                    min: 0,
                    maxPrecision: 0,
                    renderer: am5xy.AxisRendererX.new(root, {
                        minGridDistance: 40,
                        strokeOpacity: 0.1
                    })
                }));

                var legend = newChart.children.push(am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.p50
                }));

                function makeSeries(name, fieldName) {
                    var series = newChart.series.push(am5xy.ColumnSeries.new(root, {
                        name: name,
                        stacked: true,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        baseAxis: yAxis,
                        valueXField: fieldName,
                        categoryYField: "state"
                    }));

                    series.columns.template.setAll({
                        tooltipText: "{name}: {valueX}",
                        tooltipY: am5.percent(90)
                    });


                    series.columns.template.setAll({
                        strokeOpacity: 0,
                        cornerRadiusBR: 10,
                        cornerRadiusTR: 10,
                        cornerRadiusBL: 10,
                        cornerRadiusTL: 10,
                        maxHeight: 50,
                        fillOpacity: 0.8
                    });

                    series.data.setAll(listitaCompleta);


                    series.appear();

                    series.bullets.push(function () {
                        return am5.Bullet.new(root, {
                            sprite: am5.Label.new(root, {
                                text: "{valueX}",
                                fill: root.interfaceColors.get("alternativeText"),
                                centerY: am5.p50,
                                centerX: am5.p50,
                                populateText: true
                            })
                        });
                    });

                    legend.data.push(series);
                }





                var cursor = newChart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineX.set("visible", false);
                cursor.lineY.set("visible", false);



                var exporting = am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {
                        align: "right",
                        valign: "bottom"
                    })
                });

                // series.appear();

                makeSeries("Baja", "low");
                makeSeries("Media", "medium");
                makeSeries("Alta", "high");


                newChart.appear(1000, 100);



                if (chart) {
                    chart.dispose();
                }
                setChart(newChart);
            } catch (error) {
                console.error("Error al cargar las bibliotecas de AmCharts:", error);
            }
        };

        if (typeof window !== 'undefined') {
            generateChart();
        }

        return () => {


            am5.array.each(am5.registry.rootElements, function (root) {
                if (root) {
                    if (root.dom.id == chartDivId.current) {
                        root.dispose();
                    }
                }
            });



            if (chart) {
                chart.dispose();
            }
        };
    }, [chartData, startDate, endDate]);

    return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default BarrasEstadoStack;
