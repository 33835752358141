import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "../../../Icon/Icon";
import { alpha, CircularProgress, Stack, useTheme } from "@mui/material";

/**
 * @param ariaLabel Auxiliary navigational assistance for people with disabilities
 * @param color Refers to the initial theme of the button, including background, icon and text.
 *  [light, primary, secondary, error, warning, info, success]
 * @param disable Button disabled in functionality and color
 * @param disableElevation Effect that includes a boxShadow and a click effect
 * @param fullWidth Occupies the full available width
 * @param href If you want to use it as a link
 * @param iconColor Customizable icon color, if no value is provided it will take the variant to the color of the button
 * @param iconColorHover Customizable icon color when hovering over, if no value is provided it will take the variant to the color of the button.
 * @param iconName The name of the icon to be invoked is taken (eg: 'trash_can', 'paper_clip', etc)
 * @param iconSize Customizable button icon size
 * @param iconPosition "start"||"end" Left or right position of the icon in combination with the provided label
 * @param loadingIndicator Custom loading text, appears when loading=true
 * @param onClick onClick listening event
 * @param borderColor Custom border color, if no value is provided it will be 'transparent' in the contained variant (default) or the same color as the color passed in the outlined variant
 * @param size "small"||"medium"||"large" - General initial button size
 * @param sx additional styles
 * @param variant "contained"(default)||"outlined"||"text"
 */

export const EGButton = ({
  ariaLabel = "",
  color = "light",
  disabled = false,
  disableElevation = true,
  fullWidth = false,
  href = "",
  iconColor = "",
  iconColorHover = "",
  iconName = "",
  iconSize = 17,
  iconPosition = "start", // "start"|"end"
  label = "",
  loading = false,
  loadingColor = "primary",
  loadingPosition = "center",
  loadingIndicator,
  onClick,
  borderColor = "",
  size = "small",
  sx = {},
  variant = "contained",
}) => {
  const theme = useTheme();

  // Handling of the initial icon color and hover color
  const [currentIconColor, setCurrentIconColor] = useState(
    (() => {
      if (variant === "contained") {
        return (
          theme.palette[iconColor]?.main ||
          theme.palette[color]?.contrastText ||
          theme.palette.grey[600]
        );
      }
      if (variant === "outlined") {
        return (
          theme.palette[iconColor]?.main ||
          theme.palette[color]?.main ||
          theme.palette.secondary.main
        );
      }
    })()
  );
  // Initial button size is defined
  const sizeStyles = () => {
    switch (size) {
      case "small":
        return { minHeight: "1.9rem", fontSize: "0.8rem" };
      case "medium":
        return { minHeight: "2.2rem", fontSize: "1rem" };
      case "large":
        return { minHeight: "2.5rem", fontSize: "1.5rem" };
      default:
        return {};
    }
  };
  // Customizable border color
  const buttonBorder = (() => {
    if (borderColor) {
      return `2px solid ${
        theme.palette[borderColor]?.main || theme.palette.blueGray500.main
      }`;
    }
    if (color === "light") {
      return `1px solid ${
        theme.palette[borderColor]?.main || theme.palette.blueGray500.main
      }`;
    }
    if (variant === "outlined") {
      {
        return `2px solid ${
          theme.palette[color]?.main || theme.palette.blueGray500.main
        }`;
      }
    }
    return "transparent";
  })();
  // Customizable shadow-box color
  const shadowColor = (() => {
    if (borderColor) {
      return theme.palette[borderColor]?.main || theme.palette.blueGray500.main;
    }
    if (color === "light") {
      return theme.palette[borderColor]?.main || theme.palette.blueGray500.main;
    }
    if (variant === "outlined") {
      {
        return theme.palette[color]?.main || theme.palette.blueGray500.main;
      }
    }
    return "transparent";
  })();
  // Text color according to button
  const colorText = (() => {
    if (variant === "contained") {
      return theme.palette[color]?.contrastText || theme.palette.secondary.main;
    }
    if (variant === "outlined") {
      return theme.palette[color]?.main || theme.palette.secondary.main;
    }
  })();
  // Handling of icon color when hovering
  const handleMouseEnter = () => {
    if (iconColorHover) {
      setCurrentIconColor(theme.palette[iconColorHover]?.main);
    } else if (variant === "contained") {
      setCurrentIconColor(
        theme.palette[color]?.contrastText || theme.palette.grey[500]
      );
    } else if (variant === "outlined") {
      setCurrentIconColor(
        theme.palette[color]?.contrastText || theme.palette.secondary.main
      );
    }
  };
  // Restore the original color of the icon when exiting the hover
  const handleMouseLeave = () => {
    setCurrentIconColor(
      (() => {
        if (variant === "contained") {
          return (
            theme.palette[iconColor]?.main ||
            theme.palette[color]?.contrastText ||
            theme.palette.grey[600]
          );
        }
        if (variant === "outlined") {
          return (
            theme.palette[iconColor]?.main ||
            theme.palette[color]?.main ||
            theme.palette.secondary.main
          );
        }
      })()
    );
  };
  // Background color in 'outlined' variant
  const buttonOpacityHover = (color) => {
    return theme.palette[color].main + "99";
  };
  // Selected icon rendering
  const renderIcon = () => {
    if (!iconName || loading) return null;
    return (
      <Icon
        name={iconName}
        size={iconSize}
        color={alpha(currentIconColor, disabled ? 0.5 : 1)}
        className="mx-1"
      />
    );
  };
  const icon = renderIcon();

  // Button background color (only if variant === "light")
  const lightBackgroundGradient =
    variant === "contained"
      ? `linear-gradient(to bottom, ${theme.palette.common.white}, ${theme.palette.blueGray200.main} 140%)`
      : "transparent";
  // Button background hover color (only if variant === "light")
  const lightHoverGradient =
    variant === "contained"
      ? `linear-gradient(to bottom, ${theme.palette.common.white}, ${theme.palette.blueGray500.main} 140%)`
      : `linear-gradient(to bottom, ${theme.palette.common.white}, ${theme.palette.blueGray200.main} 140%)`;

  // Conditional rendering of the load indicator
  const renderLoadingIndicator = () => {
    if (!loading) return null;

    // If it contains a loadingIndicator and Icon
    if (loadingIndicator && iconName) {
      return (
        <Stack
          spacing={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={iconPosition === "start" ? "row" : "row-reverse"}
        >
          {/* Display circle instead of icon */}
          <CircularProgress
            color={loadingColor || theme.palette[color]?.main}
            size={iconSize + 2}
          />
          {/*  Show loadingIndicator text instead of the label */}
          <span>{loadingIndicator}</span>
        </Stack>
      );
    }

    // If it contains a loadingIndicator but no icon
    if (loadingIndicator) {
      return loadingIndicator;
    }

    // If it does not contain a loadingIndicator, display only the circle
    return <CircularProgress color={loadingColor} size={iconSize + 1} />;
  };

  return (
    <LoadingButton
      aria-label={ariaLabel || label}
      aria-busy={loading}
      color={color}
      disabled={disabled}
      disableElevation={disableElevation}
      fullWidth={fullWidth}
      href={href}
      startIcon={iconPosition === "start" && label ? icon : null}
      endIcon={iconPosition === "end" && label ? icon : null}
      variant={variant}
      size={size}
      onClick={onClick}
      loading={loading}
      loadingIndicator={renderLoadingIndicator()}
      loadingPosition={loadingPosition}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        boxShadow: `0 0 1px 0 ${shadowColor}`,
        fontWeight: 600,
        background: color === "light" ? lightBackgroundGradient : undefined,
        color: colorText,
        border: buttonBorder,
        display: "flex",
        alignItems: "center",
        justifyContent: !label ? "center" : undefined,
        px: 2,
        py: 0.6,
        "&:hover": {
          background:
            variant === "contained"
              ? theme.palette[color]?.dark || lightHoverGradient
              : color === "light"
              ? lightHoverGradient
              : buttonOpacityHover(color),
          border: buttonBorder,
          color:
            variant === "outlined"
              ? theme.palette[color]?.contrastText
              : undefined,
        },
        ...sizeStyles(),
        ...sx,
      }}
    >
      {/* If there is no label, the icon is centered */}
      {label || (!label && icon)}
    </LoadingButton>
  );
};
