import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Modal } from "react-bootstrap";
import { MCButton } from "../../../MainComponents";
import { useTranslation } from "react-i18next";
import { GeneralDataResolution } from "./GeneralDataResolution";
import { CorrectivePreventiveMeasures } from "./CorrectivePreventiveMeasures";
import { Sanctions } from "./Sanctions";
import { RemunerationsCompensations } from "./RemunerationsCompensations";
import useResolutions from "./useResolutions";
import "./Resolution.scss";

import { getReportDetail } from "../../../../apis";

export const Resolutions = ({ setActiveTab }) => {
  const [t] = useTranslation("report");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [activeContent, setActiveContent] = useState(1);
  const [reportDetail, setReportDetail] = useState();
  const [loading, setLoading] = useState(true); // Estado de carga
  const [peopleInReport, setPeopleInReport] = useState();

  const location = useLocation();

  // Obtener la URL completa
  const currentUrl = location.pathname;

  // Extraer el ID de la URL
  const id = currentUrl.split("/").pop(); // Esto obtiene el último segmento de la URL

  const reportResponse = async () => {
    try {
      const [reportDetailApiStatus] = await Promise.all([getReportDetail(id)]);
      setReportDetail(reportDetailApiStatus.status.current);




      for (const steper of reportDetailApiStatus.stepers) {
        if (steper.form) {
          // Iteramos a través de los elementos del formulario
          for (const formElement of steper.form) {
            if (formElement.key === "involucrados") {
              setPeopleInReport(formElement.value);
              break; 
            }
          }
        }
      }

      
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Cambia el estado de carga a false una vez que los datos se han recibido
    }
  };

  useEffect(() => {
    reportResponse();
  }, []);

  const openModal = (idx, resolutionName) => {
    setItemToDelete({ idx, resolutionName });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setItemToDelete(null);
  };

  const resolution = useResolutions({
    itemToDelete,
    closeModal,
    peopleInReport
  });

  // Navigation between tabs
  const handleTabChange = (newTab) => {
    if (resolution.editResolution) {
      const confirm = window.confirm(
        t("Resolution.if_you_leave_unsaved_changes_will_be_lost")
      );
      if (!confirm) {
        return;
      } else {
        resolution.setEditResolution(false);
        resolution.getResolutions();
      }
    }
    setActiveContent(newTab);
  };
  // Prevents leaving without saving
  useEffect(() => {
    const hdlBeforeUnload = (e) => {
      if (resolution.editResolution) {
        const message = t(
          "Resolution.if_you_leave_unsaved_changes_will_be_lost"
        );
        e.returnValue = message;
        return message;
      }
    };
    window.addEventListener("beforeunload", hdlBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", hdlBeforeUnload);
    };
  }, [resolution.editResolution]);
  // Titles menu and sections
  const resolutionTabs = [
    { title: t("Resolution.resolution"), section: 1 },
    { title: t("Resolution.corrective_preventive_measures"), section: 2 },
    { title: t("Resolution.sanctions"), section: 3 },
    { title: t("Resolution.remunerations_compesation"), section: 4 },
  ];

  if (loading) {
    return (
      <div className="loading-container">
        <p>Cargando...</p>{" "}
        {/* Aquí podrías colocar un spinner u otro indicador de carga */}
      </div>
    );
  }

  return reportDetail === "finish" ? (
    <>
      {/* Confirmation modal */}
      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <h4>{t("Resolution.confirm_action")}</h4>
        </Modal.Header>
        <Modal.Body>
          <pre className="mb-2 text-center" style={{ fontSize: "1.1rem" }}>
            {t("Resolution.are_you_sure_you_want_delete_this_element")}
          </pre>
          <div className="resolutionModal mt-4">
            <MCButton
              label={t("Resolution.cancel")}
              variant="secondary"
              onClick={closeModal}
            />
            <MCButton
              label={t("Resolution.delete")}
              variant="danger"
              onClick={resolution.hdlRemoveResolution}
            />
          </div>
        </Modal.Body>
      </Modal>
      {/* Menu Tabs */}
      <div className="resolutions__menu">
        {resolutionTabs.map((tabs) => (
          <button
            key={tabs.section}
            onClick={() => handleTabChange(tabs.section)}
            className={`menuItem ${
              activeContent === tabs.section ? "active" : ""
            }`}
          >
            <small>{tabs.title}</small>
          </button>
        ))}
      </div>
      {/* Content */}
      <Row className="rounded">
        {/* General Data Resolutions */}
        {activeContent === 1 && (
          <GeneralDataResolution
            resolution={resolution}
            setActiveTab={setActiveTab}
            reportDetail={reportDetail}
          />
        )}
        {/* Measures */}
        {activeContent === 2 && (
          <CorrectivePreventiveMeasures
            resolution={resolution}
            openModal={openModal}
            handleTabChange={handleTabChange}
            reportDetail={reportDetail}
          />
        )}
        {/* Sanctions */}
        {activeContent === 3 && (
          <Sanctions
            resolution={resolution}
            setActiveTab={setActiveTab}
            openModal={openModal}
            handleTabChange={handleTabChange}
            reportDetail={reportDetail}
            peopleInReport={peopleInReport}
          />
        )}
        {/* Remunerations & compensations */}
        {activeContent === 4 && (
          <RemunerationsCompensations
            resolution={resolution}
            setActiveTab={setActiveTab}
            openModal={openModal}
            handleTabChange={handleTabChange}
            reportDetail={reportDetail}
            peopleInReport={peopleInReport}
          />
        )}
      </Row>
    </>
  ) : (
    <>
      {/* Confirmation modal */}
      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <h4>{t("Resolution.confirm_action")}</h4>
        </Modal.Header>
        <Modal.Body>
          <pre className="mb-2 text-center" style={{ fontSize: "1.1rem" }}>
            {t("Resolution.are_you_sure_you_want_delete_this_element")}
          </pre>
          <div className="resolutionModal mt-4">
            <MCButton
              label={t("Resolution.cancel")}
              variant="secondary"
              onClick={closeModal}
            />
            <MCButton
              label={t("Resolution.delete")}
              variant="danger"
              onClick={resolution.hdlRemoveResolution}
            />
          </div>
        </Modal.Body>
      </Modal>
      {/* Menu Tabs */}

      {/* Content */}
      <Row className="rounded">
        {/* General Data Resolutions */}

        <GeneralDataResolution
          resolution={resolution}
          setActiveTab={setActiveTab}
          reportDetail={reportDetail}
        />

        {/* Measures */}

        <CorrectivePreventiveMeasures
          resolution={resolution}
          openModal={openModal}
          handleTabChange={handleTabChange}
          reportDetail={reportDetail}
        />

        {/* Sanctions */}

        <Sanctions
          resolution={resolution}
          setActiveTab={setActiveTab}
          openModal={openModal}
          handleTabChange={handleTabChange}
          reportDetail={reportDetail}
        />

        {/* Remunerations & compensations */}

        <RemunerationsCompensations
          resolution={resolution}
          setActiveTab={setActiveTab}
          openModal={openModal}
          handleTabChange={handleTabChange}
          reportDetail={reportDetail}
        />
      </Row>
    </>
  );
};
