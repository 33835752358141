import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import "./TableIndicator.scss";

export const TableIndicator = ({ dataTable }) => {
  const [t] = useTranslation("dashboard");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({});

  const columns = [
    {
      name: t("TableIndicator.indicator"),
      selector: (row) => row.title,
      sortable: true,
      grow: 2,
    },
    {
      name: t("TableIndicator.amount"),
      selector: (row) => row.count,
      sortable: true,
    },
    {
      name: t("TableIndicator.percentage"),
      selector: (row) => row.percentage,
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid #E5E5E5",
      },
    },
  };

  // useEffect(() => {
  //   // Sum of all data
  //   const totalData = dataTable.data.reduce((a, b) => a + b.data[0].length, 0);
  //   // total
  //   setTotal({
  //     title: "Total",
  //     count: totalData,
  //     percentage: "100%",
  //   });
  //   // Build data
  //   const itemData = dataTable.data.map((item) => {
  //     // Count data
  //     const count = item.data[0].length;
  //     // Percentage data
  //     const percentage = `${((count * 100) / totalData).toFixed(2)}%`;

  //     return {
  //       title: item.label,
  //       count,
  //       percentage,
  //     };
  //   });
  //   // Set data
  //   console.log("ITEM DATA", itemData);
  //   setData(itemData);
  // }, []);

  useEffect(() => {
    const totalData = dataTable.data.reduce((a, b) => a + b.data[0].length, 0);
    
    setTotal({
      title: "Total",
      count: totalData,
      percentage: "100%",
    });
  
    const itemData = dataTable.data
      .map((item) => {
        const count = item.data[0].length;
  
        if (count > 0) {
          const percentage = `${((count * 100) / totalData).toFixed(2)}%`;
          
          return {
            title: item.label,
            count,
            percentage,
          };
        }
        return null; 
      })
      .filter(item => item !== null); 
    // console.log("ITEM DATA", itemData);
    setData(itemData);
  }, []);

  return (
    <div className="table-container">
      <DataTable columns={columns} data={data} customStyles={customStyles} />
      <DataTable
        noHeader={true}
        noTableHead={true}
        columns={columns}
        data={[total]}
      />
    </div>
  );
};
