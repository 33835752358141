import { Col, Modal, Row } from "react-bootstrap"
import { MCButton, MCCheckbox, MCInput, MCLabel } from "../../../MainComponents"
import { useState } from "react"
import { useEffect } from "react"
import { enqueueSnackbar } from "notistack"
import { createUser } from "../../../../apis/apiUsers"
import { useTranslation } from "react-i18next";

const investigationRoles = [
  {
    label: "Responsable Investigación",
    value: "investigation_responsable"
  },
  {
    label: "Plan de investigación",
    value: "investigation_plan"
  },
  {
    label: "Recursos de la investigación",
    value: "investigation_resources"
  },
  {
    label: "Diseño de entrevistas",
    value: "interview_design"
  },
  {
    label: "Ejecución de entrevistas",
    value: "interview_executor"
  },
  {
    label: "Testigo de entrvista",
    value: "interview_witness"
  },
  {
    label: "Apoyo de entrevista",
    value: "interview_help"
  },
  {
    label: "Traductor",
    value: "translator"
  },
  {
    label: "Capturista",
    value: "data_entry_operator"
  },
  {
    label: "Facilitador de evidencia",
    value: "evidence_facilitator"
  },
  {
    label: "Custodia de evidencia",
    value: "evidence_custody"
  },
  {
    label: "Análisis de evidencia",
    value: "evidence_analysis"
  },
  {
    label: "Asesor de investigación",
    value: "investigation_advisor"
  },
  {
    label: "Informe de investigación",
    value: "investigation_report"
  },
  {
    label: "Aprovacion de investigación",
    value: "investigation_aprove"
  },
  {
    label: "Otras actividades de investigación",
    value: "investigation_other"
  },
]

const resolutionRoles = [
  {
    label: "Responsable de resolución",
    value: "resolution_responsable"
  },
  {
    label: "Sanciones",
    value: "sanctions"
  },
  {
    label: "Medidas correctivas",
    value: "corrective_actions"
  },
  {
    label: "Asesor de resolución",
    value: "resolution_advisor"
  },
  {
    label: "Informe de resolución",
    value: "resolution_report"
  },
  {
    label: "Aprovación de la resolución",
    value: "resolution_aprove"
  },
  {
    label: "Otras actividades de resolución",
    value: "resolution_other"
  },
]

const initUserForm = {
  username: [],
  email: [],
  password: [],
}

// {
//   "email": "test.ethicsglobal+9@gmail.com",
//   "password": "T3st&ng#",
//   "first_name": "Template",
//   "last_name": "Prueba T1",
//   "username": "templete_1"
// }

const RolesList = ({ items, userId, values, onChange=()=>{} }) => {

  const [selecteds, setSelecteds] = useState(values);

  useEffect(() => {
    onChange(selecteds);
  }, [selecteds]);

  function onSelected (value, checked) {
    if (checked === true) {
      const itExist = selecteds.includes(value);
      if (itExist) return;
      setSelecteds([...selecteds, value]);
    }
    if (checked === false) {
      const filtredSelecteds = selecteds.filter(item=>item!=value);
      setSelecteds(filtredSelecteds);
    }
  }

  return(
    <ul className="ps-0">
      {
        items.map((item,idx)=>
          <li key={item.value} style={{ listStyle: "none" }} className="d-flex mb-2">
            <MCCheckbox 
              id={`${userId}-${idx}-${item.value}`}
              className="me-2"
              onChange={(e)=>onSelected(item.value, e.target.checked)} 
              checked={selecteds.includes(item.value)} 
            />
            <label className="pointer" htmlFor={`${userId}-${idx}-${item.value}`}>{item.label}</label>
          </li>
        )
      }

    </ul>
  )
}

const ErrorMessages = ({ messages = [] }) => {
  return messages.map((item, idx) => (
    <small key={idx} style={{ color: "red", display: "block" }}>{ item }</small>
  ))
}

export const ModalAssignUser = ({ user, handleChange, setIsLoading, registred = true, target, show, onHide }) => {

  const [t] = useTranslation("users");

  const [username, setUsername] = useState(user?.name ?? "");
  const [isRegistred] = useState(registred);
  const [details, setDetails] = useState("");
  const [rolesInvestigation, setRolesInvestigation] = useState([]);
  const [rolesResolution, setRolesResolution] = useState([]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState(initUserForm);
  const [tryToCreate, setTryToCreate] = useState(false);

  useEffect(() => {
    const assignDetails = user?.assign_details;
    setDetails(assignDetails?.details || "");
    setRolesInvestigation(assignDetails?.rolesInvestigation || []);
    setRolesResolution(assignDetails?.rolesResolution || []);
  }, [user, show]);

  async function handleSubmitExternal() {
    setValidationErrors(initUserForm);
    setTryToCreate(true);
    try {
      const data = await createUser({ username, first_name: username, email, password });
      handleChange(
        { 
          id: data.data.data.id, 
          email: data.data.data.id,
          first_name: username
        }, 
        ()=>{}, // setIsLoading 
        "external", // target
        rolesInvestigation, 
        rolesResolution, 
        details
      );
      setUsername("");
      setEmail("");
      setPassword("");
      setRolesInvestigation([]);
      setRolesResolution([]);
      onHide();
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 3000,
      });
    }
  }

  function handleUpdate() {
    handleChange(user, setIsLoading, "update", rolesInvestigation, rolesResolution, details);
    onHide();
    setUsername("");
    setEmail("");
    setPassword("");
    setRolesInvestigation([]);
    setRolesResolution([]);
  }

  function handleAssign () {
    handleChange(user, setIsLoading, "inactive", rolesInvestigation, rolesResolution, details);
    onHide();
    setUsername("");
    setEmail("");
    setPassword("");
    setRolesInvestigation([]);
    setRolesResolution([]);
  }

  function handleUnlink() {
    handleChange(user, setIsLoading, "active");
    onHide();
    setUsername("");
    setEmail("");
    setPassword("");
    setRolesInvestigation([]);
    setRolesResolution([]);
  }

  function cleanAll () {
    onHide();
    setValidationErrors(initUserForm);
    setUsername("");
    setEmail("");
    setPassword("");
    setRolesInvestigation([]);
    setRolesResolution([]);
  }

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Row>
        <Col md={8} className="flex-grow-1">
          {
            isRegistred
            ? <>
                {/* <h4>Usuario asignado: </h4> */}
                <h4>{t("User.assigned_user_one")}</h4>
                
                <MCInput 
                  value={user?.name}
                  onChange={(e)=>setUsername(e.target.value)}
                  disabled
                />
              </>
            : <>
                {/* <h4>Nombre de usuario: </h4> */}
                <h4>{t("User.user_name")}</h4>

                <MCInput 
                  value={username}
                  isValid={ !tryToCreate ? true : validationErrors.username.length === 0 ? true : false }
                  onChange={(e)=>setUsername(e.target.value)}
                />
                {
                  tryToCreate && validationErrors.username.length > 0 &&
                  <ErrorMessages messages={validationErrors.username} />
                }
                <h4>{t("User.email")}</h4>
                <MCInput 
                  value={email}
                  isValid={ !tryToCreate ? true : validationErrors.email.length === 0 ? true : false }
                  onChange={(e)=>setEmail(e.target.value)}
                  />
                {
                  tryToCreate && validationErrors.email.length > 0 &&
                  <ErrorMessages messages={validationErrors.email} />
                }
                <h4>{t("User.password")} </h4>
                <MCInput 
                  value={password}
                  isValid={ !tryToCreate ? true : validationErrors.password.length === 0 ? true : false }
                  onChange={(e)=>setPassword(e.target.value)}
                />
                {
                  tryToCreate && validationErrors.password.length > 0 &&
                  <ErrorMessages messages={validationErrors.password} />
                }
              </>
          }
        </Col>
        <Col md={4} className="d-block d-lg-flex pb-2">
          <div className={`d-flex align-items-center align-items-xl-end me-2 ${!isRegistred ? "disabled-filter" : ""}`}>
            <MCCheckbox 
              className="me-2 mt-0"
              checked={isRegistred} 
              disabled={!isRegistred}
            />
            <MCLabel 
              text={t("User.registered_user")}
            />
          </div>
          <div className={`d-flex align-items-center align-items-xl-end ${isRegistred ? "disabled-filter" : ""}`}>
            <MCCheckbox 
              className="me-2 mt-0"
              checked={!isRegistred} 
              disabled={isRegistred}
            />
            <MCLabel 
              text={t("User.external_user")}
            />
          </div>
        </Col>
      </Row>

      <hr  className="my-4" />

      <Row>
        <Col lg={4}>
          <h6>{t("User.investigation_roles")}</h6>
          <RolesList items={investigationRoles} userId={user?.id} values={rolesInvestigation} onChange={setRolesInvestigation} />
        </Col>
        <Col lg={4}>
          <h6>{t("User.resolution_roles")}</h6>
          <RolesList items={resolutionRoles} userId={user?.id} values={rolesResolution} onChange={setRolesResolution} />
        </Col>
        <Col lg={4}>
          <h6>{t("User.details")}</h6>
          <MCInput 
            type="textarea"
            value={details}
            onChange={(e)=>setDetails(e.target.value)}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col className={`d-lg-flex ${target === "active" ? "justify-content-between" : "justify-content-end" }`}>
          {
            target === "active" &&
            <MCButton variant="danger" outline label="Desvincular usuario" onClick={handleUnlink} />
          }

          <div style={{ justif: "end" }}>
            {
              target === "active"
              ? <MCButton variant="primary" label="Actualizar usuario" className="mt-2 mt-lg-0" onClick={ handleUpdate } />
              : <MCButton variant="primary" label="Asignar usuario" className="mt-2 mt-lg-0" onClick={isRegistred ? handleAssign : handleSubmitExternal } />
            }
            <MCButton variant="primary" outline label="Cancelar" className="mt-2 mt-lg-0 ms-lg-2" onClick={cleanAll} />
          </div>
        </Col>
      </Row>
      
    </Modal>
  )
}
