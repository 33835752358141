import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import "./Information.scss";
import { parseDateToLgComun4 } from "../../../../helpers/dateTransform";
import { MCButton } from "../../../MainComponents";
import EGAccordion from "../../../MainComponents/Accordion/EGAccordion/EGAccordion";
import {
  OutlineDirectNormal,
  OutlineDocumentText,
  OutlineLocation,
  Paperclip2,
  PersonalId,
} from "../../../Icon";
import Comments from "./Comments";
import { Complements } from "./Complements";
import { AdvisorReview } from "./AdvisorReview";
import {
  utilitiesAdvisorReview as utilitiesAdvisorReviewApi,
  detailAdvisorReview,
} from "../../../../apis/apiAdvisorReview";
import { EGButton } from "../../../MainComponents/Button/EGButton/EGButton";

export const InvestigationSummary = ({
  reportDetail,
  setShowModalAttachments,
}) => {
  const { lang } = useSelector((state) => state.lang);
  const [t] = useTranslation("information");
  const theme = useTheme();

  // useEffect(() => {
  //   try {
  //     detailAdvisorReview(reportDetail?.form_id).then((resp) =>
  //       console.log(resp)
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, []);

  const CapturedInformation = () => {
    ////////// Obtaining report form label //////////
    const getFormLabel = (label, lang, default_value = "") => {
      if (typeof label === "string") {
        return label;
      } else {
        if (lang in label) {
          return label[lang];
        } else {
          return default_value;
        }
      }
    };
    return (
      <>
        {reportDetail?.stepers?.map((item, index) => (
          // Section container
          <Row className="capturedInfo my-1 py-1" key={index}>
            {/* Section title */}
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              width="100%"
              key={item.name}
            >
              {index === 0 ? (
                <PersonalId
                  color={theme.palette.secondary.main}
                  className="capturedInfo__circleIcon"
                />
              ) : index === 1 ? (
                <OutlineLocation
                  color={theme.palette.secondary.main}
                  className="capturedInfo__circleIcon"
                />
              ) : index === 2 ? (
                <OutlineDocumentText
                  className="capturedInfo__circleIcon"
                  color={theme.palette.secondary.main}
                />
              ) : (
                <OutlineDirectNormal
                  className="capturedInfo__circleIcon"
                  color={theme.palette.secondary.main}
                />
              )}
              <Typography variant="h6" color={theme.palette.primary.main}>
                {item.locales ? getFormLabel(item.locales, lang) : item.title}
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                }}
              />
            </Stack>
            {/* Section Content */}
            <Stack
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row"
              flexWrap="wrap"
            >
              {item?.form?.map((form, idx) => {
                let is_array = false;

                if (form.key === "subject" || form.key === "description") {
                  return <div key={idx}></div>;
                } else {
                  let the_label = form.label;

                  if (form.locale != undefined) {
                    the_label = form.locale;
                  }

                  let show_value = form.text_value;

                  if (form.locales_value != null) {
                    show_value = getFormLabel(
                      form.locales_value,
                      lang,
                      show_value
                    );
                  }

                  if (Array.isArray(show_value)) {
                    show_value = show_value.map((item, index) => (
                      <span key={index} className={`involved ${item.type}`}>
                        {item.name} ({item.occupation})
                        <button>
                          {t(`information.involved_${item.type}`)}
                        </button>
                      </span>
                    ));

                    is_array = true;
                  }

                  if (typeof show_value === 'boolean') {
                    return null; // No mostrar nada si es booleano
                  }


                  return (
                    <Stack className={is_array ? "involveds" : ""} key={idx}>
                      {/* Involved list */}
                      {is_array ? (
                        <Stack
                          className="involved_field"
                          direction={"row"}
                          m={1}
                        >
                          <Typography variant="subtitle1" color="secondary">
                            {`${t("information.report_involveds")}:`}
                          </Typography>
                          <div className="--detail">{show_value}</div>
                        </Stack>
                      ) : (
                        // Form
                        <Stack key={idx} m={1}>
                          {/* Question */}
                          <Typography variant="subtitle1" color="secondary">
                            {getFormLabel(the_label, lang)}&nbsp;
                          </Typography>
                          {/* Answer */}
                          <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{
                              borderBottom: `1px solid ${theme.palette.blueGray100.main}`,
                              px: "5px",
                              mt: "-3px",
                              fontSize: "0.9rem",
                            }}
                          >
                            {show_value ? String(show_value) : "-----"}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  );
                }
              })}
            </Stack>
          </Row>
        ))}
      </>
    );
  };

  const panels = [
    {
      id: "panel1",
      title: t("information.captured_information"),
      titleBackgrundColor: theme.palette.primary.main,
      titleColor: theme.palette.primary.contrastText,
      contentBorderColor: theme.palette.primary.main,
      content: <CapturedInformation />,
    },
    // {
    //   id: "panel2",
    //   title: t("AdvisorReview.advisor_review"),
    //   titleBackgrundColor: theme.palette.secondary.main,
    //   titleColor: theme.palette.secondary.contrastText,
    //   contentBorderColor: theme.palette.secondary.main,
    //   content:
    //     "Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    // },
    ...((reportDetail?.complements?.length > 0 && [
      {
        id: "panel3",
        title: `${t("information.report_complements")}`,
        content: <Complements complements={reportDetail?.complements} />,
      },
    ]) ||
      []),
  ];

  return (
    <div className="p-20">

      <div className="evidence-btn">
        <EGButton
          iconName="paperclip_2"
          label={`${reportDetail?.attachments.length === 0
            ? t("information.no_evidence")
            : t("information.evidence")
            }`}
          size="small"
          onClick={() => setShowModalAttachments(true)}
          disabled={reportDetail.attachments.length === 0}
        />
      </div>


      <Typography
        variant="body2"
        color="blueGray600.main"
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          {`${t("information.generated_report")}: ${parseDateToLgComun4(
            reportDetail?.created_at,
            t("information.locale")
          ) || t("information.no_updates")
            }`}
        </Stack>
        {/* <Stack>Recibido por: Oficial Ético</Stack> */}
      </Typography>

      <Typography variant="h6" mt={0.5}>
        Folio: {reportDetail?.folio}
      </Typography>



      <Box
        className="investigation__header rounded dyTheme2 py-10 px-20"
        mt={1}
        sx={{ border: `1px solid ${theme.palette.primary.main}` }}
      >
        <Typography
          variant="h5"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {reportDetail?.subject || t("information.no_subject")}
        </Typography>
        <Typography variant="h6" color="primary" mt={0.5}>
          {`${t("information.report_description")}:`}
        </Typography>
        <Comments
          padding={0}
          maxLength={500}
          text={reportDetail?.description || t("information.no_description")}
        />
      </Box>

      <div className="mt-20">
        <EGAccordion panels={panels} defaultExpanded="panel1" />
      </div>
    </div>
  );
};
