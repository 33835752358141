import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck'
import { useNavigate } from "react-router-dom";
import './styles.scss';
import { login, retrieveCatalogueByPath } from "../../apis";
import { getReports, getReportsWithPagination } from "../../apis";

import { searchNavbarAPI } from '../../apis/apiReports';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import {
    SearchIcon2,
    OutlineMessages3,
    Monitor,
    Call,
    OutlineSms,
    OutlineMessages2,
    Mobile,
    Grammerly,
    CdIcon,
    OutlineSmsTracking,
    OutlineWhatsapp,
} from "../Icon";

import { utilitiesDashboard } from "../../apis/apiDashboard";

import { MCButton } from "../../components/MainComponents";

import MultipleSelectDefault from '../MainComponents/MUISelectDefault/MultipleSelectDefault';

import { useLocation } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createReducer } from '@reduxjs/toolkit';







const SimpleSearch = ({ placeholder, onChange, options, value, onKeyDown, searchTermSecondary, onSubmit = () => { }, ...rest }, props) => {
    const [t] = useTranslation("report");
    const navigate = useNavigate();
    const [showDetails, setShowDetails] = useState(false);
    const modalRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchTerm, setSearchTerm] = useState(searchTermSecondary);
    const [searchResults, setSearchResults] = useState([]);
    const [changeLabel, setChangeLabel] = useState(false);
    const [kindOfEntrywaysValue, setKindOfEntrywaysValue] = useState([]);
    const [kindOfStatusValue, setKindOfStatusValue] = useState([]);
    const [kindOfSeverity, setKindOfSeverity] = useState([]);
    const [kindOfSeverityValue, setKindOfSeverityValue] = useState([]);
    const [stateData, setStateData] = useState({ utilities: [] });
    const location = useLocation();
    const [paginaciones, setPaginaciones] = useState({});
    const [restartOffset, setRestartOffset] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(0); //Total Reports
    const [limit, setLimit] = useState(10); //Limit of results per consult
    const [currentPage, setCurrentPage] = useState(0); //Current Page
    const [offset, setOffset] = useState(0); //Set offset to getData
    const [next, setNext] = useState(null); //Next URL
    const [previous, setPrevious] = useState(null); //Prev URL
    const [pageNumLimit, setPageNumLimit] = useState(10); //Limit of pages on screen
    const [maxPageNum, setMaxPageNum] = useState(10); //Number of page max on screen
    const [minPageNum, setMinPageNum] = useState(0); //Number of page min on screen
    const [searchPag, setSearchPag] = useState(""); //Value of input Search






    useEffect(() => {
        setSearchTerm(searchTermSecondary)
    }, [searchTerm, searchTermSecondary]);


    const kindOfEntryways = [
        {
            value: "WEBSITE",
            label: t("entryways.website"),
            key: "entryways.website"
        },
        {
            value: "PHONE",
            label: t("entryways.phone"),
            key: "entryways.phone"
        },
        {
            value: "EMAIL",
            label: t("entryways.email"),
            key: "entryways.email"
        },
        {
            value: "CHAT",
            label: t("entryways.chat"),
            key: "entryways.chat"
        },
        {
            value: "WHATSAPP",
            label: t("entryways.whatsapp"),
            key: "entryways.whatsapp"
        },
        {
            value: "APP",
            label: t("entryways.app"),
            key: "entryways.app"
        },
        {
            value: "OUTDOOR",
            label: t("entryways.outdoor"),
            key: "entryways.outdoor"
        },
        {
            value: "OTHER",
            label: t("entryways.other"),
            key: "entryways.other"
        },
    ];

    const optionsStatus = [
        {
            value: "new",
            label: t("status.new"),
            key: "status.new"
        },
        {
            value: "process",
            label: t("status.process"),
            key: "status.process"
        },
        {
            value: "finish",
            label: t("status.finish"),
            key: "status.finish"
        },
        {
            value: "pending",
            label: t("status.pending"),
            key: "status.pending"
        },
        {
            value: "closed",
            label: t("status.closed"),
            key: "status.closed"
        },
        {
            value: "draft",
            label: t("status.draft"),
            key: "status.draft"
        },
    ];

    const iconEntryway = {
        WEBSITE: {
            element: <Monitor className="icon-indicator" />,
        },
        PHONE: {
            element: <Call className="icon-indicator" />,
        },
        EMAIL: {
            element: <OutlineSmsTracking className=" icon-indicator" />,
            // element: <OutlineSms className=" icon-indicator" />,
        },
        CHAT: {
            element: <OutlineMessages3 className=" icon-indicator" />,
        },
        WHATSAPP: {
            element: <OutlineWhatsapp className=" icon-indicator" />,
        },
        APP: {
            element: <Mobile className="icon-indicator" />,
        },
        OUTDOOR: {
            element: <Grammerly className=" icon-indicator" />,
        },
        OTHER: {
            element: <CdIcon className="icon-indicator" />,
        },
    };

    useEffect(() => {
        // Cierra el modal cuando la URL cambie
        setSearchTerm('');
        setShowDetails(false);
        setKindOfSeverityValue([]);
        setKindOfEntrywaysValue([]);
        setKindOfStatusValue([]);
    }, [location, setShowDetails]);

    //Maneja los cambios de estado en los selects 
    const handleKindOfEntrywaysChange = (selectedOptions) => {
        setChangeLabel(true);
        const selectedValue = selectedOptions.map(options => options.value);
        setKindOfEntrywaysValue(selectedValue);
    };


    const handleKindOfSeverityChange = (event) => {
        setChangeLabel(true);
        const selectedValue = event.target.value;
        // Actualiza el estado aquí según lo necesites
        setKindOfSeverityValue((prevValues) => {
            if (prevValues.includes(selectedValue)) {
                return prevValues.filter((value) => value !== selectedValue);
            } else {
                return [...prevValues, selectedValue];
            }
        });
    };

    const handleKindOfStatusChange = (selectedOptions) => {
        setChangeLabel(true);
        const selectedValue = selectedOptions.map(options => options.value);
        setKindOfStatusValue(selectedValue);
    };

    const transformData = (data, key = "path") => {
        return data.map((item) => {
            const path = item[key];
            return {
                value: path,
                label: item.label,
            };
        });
    };


    const getOptionsFromElement = (element) => {
        return element.childs.map((item) => {
            const valueParts = item.path.split("::");
            const value = valueParts.length > 1 ? valueParts[valueParts.length - 1] : item.path;

            const containsOWN = item.path.includes("OWN");

            return {
                label: item.locales[t("lang")] ? item.locales[t("lang")] : item.label,
                value: value,
                containsOWN: containsOWN
            };
        });
    };

    const getElementFromUtilities = (path) => {
        if (!stateData.utilities || !Array.isArray(stateData.utilities)) {
            console.warn(`Utilities not found in stateData or stateData.utilities is not an array.`);
            return [];
        }

        const elements = stateData.utilities.filter((item) => item.path === path);
        if (elements.length === 0) {
            console.warn(`Elements with path ${path} not found.`);
        }
        return elements;
    };

    const getOptionsFromElementUtilities = (path) => {
        const elements = getElementFromUtilities(path);
        let allOptions = [];


        elements.forEach((element) => {
            const options = getOptionsFromElement(element);
            allOptions = allOptions.concat(options);
        });

        return allOptions;
    };




    useEffect(() => {
        getCatalogue("/", "reportClassification");
        getCatalogue("type::grievance", "kindOfGrievance");
        getCatalogue("/", "kindOfSeverity", "RD-102106");
    }, []);
    ////////////////////////////////////////////////////////////////
    // CALL TO APIS
    ////////////////////////////////////////////////////////////////

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await utilitiesDashboard();
                setStateData(response);
            } catch (error) {
                console.error("Error fetching utilities dashboard data: ", error);
            }
        };
        fetchData();
    }, []);
    const getCatalogue = async (
        path = "/",
        origin = "reportClassification",
        catalogue = "RC-100",
        is_own = false
    ) => {
        const response = await retrieveCatalogueByPath({ is_own, catalogue, path });
        if (response.status === 200) {
            if (origin === "kindOfSeverity") {
                setKindOfSeverity(transformData(response.data.data, "path_locales"));
            }
        }
        return [];
    };
    const searchAndFilter = async (reiniciar) => {

        let pagecount = offset;
        if (reiniciar == true) {
            setOffset(0);
            pagecount = 0;
            setCurrentPage(0);
        }


        const data = {
            entryway: `${kindOfEntrywaysValue}`,
            status: `${kindOfStatusValue}`,
            folio: searchTerm,
            severity: `${kindOfSeverityValue}`,
            offset: pagecount
        };
        try {
            const response = await searchNavbarAPI(data);
            const limitedResults = response.results.reports;
            setSearchResults(limitedResults);
            setCount(response.count);
            

        } catch (error) {
            console.error(error);
        }
        setRestartOffset(false);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                searchAndFilter();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [kindOfEntrywaysValue, kindOfStatusValue, searchTerm, kindOfSeverityValue]);


    //aply filters and redirect

    useEffect(() => {
        searchAndFilter();
        // }, [searchTerm, kindOfEntrywaysValue, kindOfStatusValue, kindOfSeverityValue]);
        // }, [searchTerm]);
    }, [offset]);
    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const goToReport = (result) => {
        navigate(`/reports/${result.id}`)
        setSearchTerm('');
        setShowDetails(false);
        setKindOfSeverityValue([]);
        setKindOfEntrywaysValue([]);
        setKindOfStatusValue([]);
    };








    /** ------ PAGINATION ------
   * In this section determines the navigation between pages according to the distribution of the registered reports.
   * This, in turn, is determined with the Count/Limit calculation and the index is assigned to each button in the renderPagesNumber function.
   *  eg: 100/10 = 10 (reports per page)
   */



    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);


    const limite = localStorage.getItem("limit");



    // Recall reports with params
    const handleReCallReports = (evt) => {
        const queryParams = new URLSearchParams(location.search);

        //-----onChange Event-----//
        if (evt?.target?.name === "limit") {
            // Files per page
            setLimit(evt.target.value);
            setOffset(0);
            setCurrentPage(0);
            setMaxPageNum(pageNumLimit);
            setMinPageNum(0);
            setSearchPag("");
            localStorage.setItem("limit", evt.target.value);
        } else if (evt?.target?.name === "searchingPage") {
            // Value in input search page
            setCurrentPage(evt.target.value - 1);
            setOffset(evt.target.value - 1);
        } else if (evt?.target?.name === "number") {
            setOffset(evt.target.value);
        } else if (evt?.currentTarget?.name === "section") {
            setOffset(evt.currentTarget.value);
        }
        // Get data into response
    };
    //Navigate through the navigation pages
    const handlePagination = (evt) => {
        setSearchPag("");
        let pageNumber = Number(evt.target.id);
        setCurrentPage(pageNumber);
        handleReCallReports(evt);
    };
    //Change page on previos or next registers
    const handlePage = async (url) => {
        setIsLoading(true);
        try {
            await getReportsWithPagination(url).then((resp) => {
                setNext(resp.next);
                setPrevious(resp.previous);
                setIsLoading(false);
            });
        } catch (error) {
            console.error(error);
        }
    };
    // Distribution of results on pages according to selected rows
    const pages = [];
    const page = count / limit;
    for (let i = 0; i < Math.ceil(page); i++) {
        pages.push(i);
    }
    
    // Renders the buttons with the page numbers for navigation
    const renderPagesNumber = pages.map((number) => {
        if (number < maxPageNum && number >= minPageNum) {
            return (
                <button
                    key={number}
                    id={number}
                    name="number"
                    onClick={handlePagination}
                    value={number * limit}
                    className={
                        currentPage === number
                            ? "paginator__btnPag --activate"
                            : "paginator__btnPag --scale09"
                    }
                    disabled={currentPage === number}
                >
                    {number + 1}
                </button>
            );
        }
    });
    // Event on pressing 'Enter' key
    useEffect(() => {
        const keyDown = (evt) => {
            if (
                evt.key === "Enter" &&
                (evt.target === inputRef1.current || evt.target === inputRef2.current)
            ) {
                evt.preventDefault(evt);
                handleSearchPage(evt);
            }
        };
        document.addEventListener("keydown", keyDown);
        return () => {
            document.removeEventListener("keydown", keyDown);
        };
    });
    // Searching page on input
    const handleSearchPage = (evt) => {
        setSearchPag("");
        setCurrentPage(evt.target.value - 1);
        handleReCallReports(evt);
        setMaxPageNum(Number(evt.target.value));
        setMinPageNum(Number(evt.target.value) - pageNumLimit);
    };
    // If there are a next page

    // Go to the next section of pages
    const handleNextSection = (evt) => {
        setMaxPageNum(maxPageNum + pageNumLimit);
        setMinPageNum(minPageNum + pageNumLimit);
        setCurrentPage(minPageNum + pageNumLimit);
        handleReCallReports(evt);
    };
    // Go to previous section of pages
    const handlePrevSection = (evt) => {
        setMaxPageNum(maxPageNum - pageNumLimit);
        setMinPageNum(minPageNum - pageNumLimit);
        setCurrentPage(maxPageNum - pageNumLimit - 1);
        handleReCallReports(evt);
    };
    // Go to last page
    const handleLastPage = (evt) => {
        setMaxPageNum(pages.length);
        setMinPageNum(pages.length - pageNumLimit);
        handleReCallReports(evt);
        setCurrentPage(pages.length - 1);
    };
    // Go to first page
    const handleFirstPage = (evt) => {
        setMaxPageNum(pageNumLimit);
        setMinPageNum(0);
        handleReCallReports(evt);
        setCurrentPage(0);
    };
    // --- Only if the page limit is greater than 10, the tools are enabled to navigate between results. ---//
    // Navigate between next pages
    let pageIncrement = null;
    if (pages.length > maxPageNum) {
        pageIncrement = (
            <>
                <button
                    className="paginator__btnSection --scale09"
                    onClick={handleNextSection}
                    value={(pageNumLimit + minPageNum) * limit}
                    name="section"
                >
                    <p>&#8250;&#8250;</p>
                </button>
                <input
                    type="number"
                    name="searchingPage"
                    placeholder="..."
                    value={searchPag}
                    max={pages.length}
                    min={1}
                    onChange={(evt) => setSearchPag(evt.target.value)}
                    className="paginator__searchPage"
                    ref={inputRef2}
                />
                <button
                    id={Number(pages.length) - 1}
                    className="paginator__btnPag --scale09"
                    onClick={handleLastPage}
                    value={Math.abs(pages.length - 1) * limit}
                    name="number"
                >
                    {pages.length}
                </button>
            </>
        );
    }
    // Navigate between previous pages
    let pageDecrement = null;
    if (minPageNum >= 1) {
        pageDecrement = (
            <>
                <button
                    className="paginator__btnPag --scale09"
                    onClick={handleFirstPage}
                    name="number"
                    value={0}
                >
                    1
                </button>
                <input
                    type="number"
                    name="searchingPage"
                    placeholder="..."
                    value={searchPag}
                    max={pages.length}
                    min={1}
                    onChange={(evt) => setSearchPag(evt.target.value)}
                    className="paginator__searchPage"
                    ref={inputRef1}
                />
                <button
                    className="paginator__btnSection --scale09"
                    onClick={handlePrevSection}
                    value={Math.abs(maxPageNum - pageNumLimit - 1) * limit}
                    name="section"
                >
                    <p>&#8249;&#8249;</p>
                </button>
            </>
        );
    }
    return (
        <div>
            <>
                <div className="search-details-searcher" ref={modalRef}>
                    <div className="search-details-section-searcher">
                        <Row>
                            <Col xs={12} md={9} xxl={9} className="search-results-section" >
                                <div className="search-details-content" >
                                    <div className="search-details-rows">
                                        <p className="paginator__results">
                                            {isNaN(count) || count === undefined
                                                ? changeLabel === false ? (
                                                    <p>{t("searchBar.last_report_received")}</p>
                                                ) : (
                                                    <p>{t("searchBar.related_reports")}</p>
                                                )  // Mostrar "reportes relacionados" si count es NaN o undefined
                                                : `${t("ReportsList.results")}: ${count === 0 ? 0 : Number(offset) + 1
                                                } ${t("ReportsList.to")} ${searchResults?.length + Number(offset)
                                                } ${t("ReportsList.of")} ${Number(count)}`}
                                        </p>
                                        {/* {changeLabel === false ? (
                                            <p>{t("searchBar.last_report_received")}</p>
                                        ) : (
                                            <p>{t("searchBar.related_reports")}</p>
                                        )} */}
                                        <div className="search-details-divider"></div>
                                        {searchResults.map((result, index) => (
                                            <Row key={index}
                                                onClick={() => goToReport(result)}
                                                className={`search-details-row ${selectedItem === result.id ? 'selected' : ''}`}
                                            >
                                                <Col xs={2} md={2} xxl={2}>
                                                    <div className={"search-details-column-icon" + result.status.current}>
                                                        {iconEntryway[result.entryway]?.element}
                                                    </div>
                                                </Col>
                                                <Col xs={10} md={10} xxl={10}>
                                                    <Row>
                                                        <Col xs={8} md={6} xxl={6} >
                                                            <div className="search-details-column-folio">
                                                                <p>{result.folio}</p>
                                                            </div>
                                                        </Col>
                                                        <Col xs={4} md={6} xxl={3} >
                                                            <div className="search-details-column-tracking">
                                                                <p>{result.tracking_code}</p>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} md={12} xxl={3} >
                                                            <div className="search-details-column-subject-container">
                                                                <p>{result.subject}</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={3} xxl={3} className="vertical-line">
                                <div>
                                    <div className="section" >
                                        <div className="scroll-container">
                                            <div className="severity">
                                                <h6>{t("searchBar.filterByStatus")}</h6>
                                                <FormGroup>
                                                    {Object.keys(kindOfSeverity).map((key) => {
                                                        return (
                                                            <FormControlLabel
                                                                key={key}
                                                                control={
                                                                    <Checkbox
                                                                        checked={kindOfSeverityValue.includes(kindOfSeverity[key].value)}
                                                                        onChange={handleKindOfSeverityChange}
                                                                        value={kindOfSeverity[key].value}
                                                                        sx={{
                                                                            color: '#009ed7',
                                                                            '&.Mui-checked': {
                                                                                color: '#009ed7',
                                                                            },
                                                                            fontSize: '12px',
                                                                            fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
                                                                            fontWeight: 700
                                                                        }}
                                                                    />
                                                                }
                                                                label={kindOfSeverity[key].label}
                                                                className="custom-form-control-label"
                                                            />
                                                        );
                                                    })}
                                                </FormGroup>
                                            </div>
                                            <div className="entryway" >
                                                <MultipleSelectDefault
                                                    title={t("searchBar.filterByEntryWay")}
                                                    options={kindOfEntryways}
                                                    onChange={handleKindOfEntrywaysChange}
                                                />
                                            </div>
                                            <div className="entry-medium">
                                                <MultipleSelectDefault
                                                    title={t("searchBar.report_status")}
                                                    options={optionsStatus}
                                                    onChange={handleKindOfStatusChange}
                                                />
                                            </div>
                                            <div>
                                                <MCButton
                                                    label={t("searchBar.apply_filter")}
                                                    variant="primary"
                                                    width={180}
                                                    onClick={() => {
                                                        setRestartOffset(true);
                                                        searchAndFilter(true);
                                                        setChangeLabel(true);
                                                    }}
                                                    className="apply-filters"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/* Paginator */}
                        <nav className="paginator dyTheme1">
                            {/* Select row per page */}
                            <div className="paginator__btnsAction">
                                <div className="paginator__renderPagesNumber">
                                    {renderPagesNumber}
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </>

        </div>
    );
}


export default SimpleSearch;


