import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  BoldDocumentText,
  BoldProgrammingArrow,
  OutlineDocumentText,
  OutlineEdit,
  OutlineEye,
  TrashCan,
} from "../../../Icon";
import { EGIconButton } from "../../../MainComponents/Button/IconButton/IconButton";
import { useTranslation } from "react-i18next";
import { EGSpeedDial } from "../../../MainComponents/Button/SpeedDial/SpeedDial";
import { Col, Modal, Row } from "react-bootstrap";
import { parseDateToDDMMYYYY2 } from "../../../../helpers/dateTransform";
import { useSnackbar } from "notistack";
import {
  changeRelated,
  createInvestigationAssign,
  createRelated,
  deleteInvestigationAssign,
  deleteRelated,
  getInvestigationListByReportId,
} from "../../../../apis";
import { Loading } from "../../../Loading/String/Loading";
import Comments from "./Comments";
import Icon from "../../../Icon/Icon";
import useUnsavedChangesWarning from "../../../../hooks/useUnsavedChangesWarning";
import { ReactComponent as Ethiquin } from "../../../../assets/icons/alerta_robot.svg";
import { MCButton } from "../../../MainComponents";
import "./Information.scss";

export const ReportRelated = ({
  relatedList,
  investigationList,
  responseReport,
  id,
  openModalAction,
  closeModal,
  showDefaultElements = 2,
}) => {
  ////////// Custom Hooks //////////
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [t] = useTranslation("information");

  ////////// States ////////////
  // ----> Report Related <----- //
  const [editing, setEditing] = useState(false);
  // Prevents leaving without saving
  useUnsavedChangesWarning(editing);
  const [loading, setLoading] = useState(false);
  // Modification of the related report
  const [uploadRelated, setUploadRelated] = useState(false);
  // Want to add details?
  const [addDetails, setAddDetails] = useState(false);
  // Folio of the report to be related
  const [relatedFolio, setRelatedFolio] = useState("");
  // TrackingCode of report to be related
  const [trackingCode, setTrackingCode] = useState("");
  // Related report detail
  const [relatedComment, setRelatedComment] = useState("");

  // ----> Assign Report to Selected Investigation  <----- //
  // Selected report
  const [selectedReport, setSelectedReport] = useState([]);
  // Assign/Unlink investigations
  const [showModal, setShowModal] = useState(false);
  // Assignment/unlink confirmation
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  // Investigation to be related/unlinked
  const [investigationToModify, setInvestigationToModify] = useState(null);
  // Action type (add o remove)
  const [actionType, setActionType] = useState(null);
  // Selection of investigation
  const [selectedInvestigation, setSelectedInvestigation] = useState([]);
  // Loading of available investigation information
  const [loadingInv, setLoadingInv] = useState(false);
  // Save the changes temporarily, but do not apply them until confirmation
  const [prevTempInvState, setPrevTempInvState] = useState([]);
  // Limit the number of related reports displayed
  const [showAllRprtRltd, setShowAllRprtRltd] = useState(false);
  const toggleShowAllRprtRltd = () => {
    setShowAllRprtRltd((show) => !show);
  };
  //  Calculation of report related displayed on screen
  const reportRelatedLength = relatedList?.length - showDefaultElements;
  let viewMore;
  if (reportRelatedLength === 1) {
    viewMore = `${t("information.view")} 1 ${t("information.related_report")}`;
  } else {
    viewMore = `${t("information.view")} ${reportRelatedLength} ${t(
      "information.more_related_reports"
    )}`;
  }

  ////////// Report Related //////////
  // Save/upload report related
  const handleRelatedSave = async () => {
    const dataToSend = {
      report_identifier: Number(id),
      report_to_relate_identifier: uploadRelated ? trackingCode : relatedFolio,
      comment: relatedComment,
    };

    // Upload related report details
    if (uploadRelated) {
      try {
        const resp = await changeRelated(dataToSend).then((resp) => {
          if (resp) {
            setRelatedFolio("");
            setTrackingCode("");
            setRelatedComment("");
            setEditing(false);
            enqueueSnackbar(
              t("information.linked_report_successfully_updated"),
              { variant: "success" }
            );
            responseReport();
            setUploadRelated(false);
          } else {
            return;
          }
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      // Create a new related report
      try {
        const resp = await createRelated(dataToSend).then((resp) => {
          if (resp) {
            setRelatedFolio("");
            setRelatedComment("");
            setEditing(false);
            enqueueSnackbar(t("information.successfully_linked_report"), {
              variant: "success",
            });
            responseReport();
          } else {
            return;
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
  // Cancel report relationship process
  const hdlCancelRelated = () => {
    setRelatedComment("");
    setTrackingCode("");
    setRelatedFolio("");
    setEditing(false);
    setUploadRelated(false);
    setSelectedInvestigation([]);
  };

  ////////// Assign Investigation //////////
  // Modal for assigning available investigations
  const hdlOpenModal = async (reportRelated) => {
    setShowModal(true);
    setSelectedReport(reportRelated);
    // Investigations of the selected report
    const assignedInvestigation = await getInvestigationListByReportId(
      reportRelated.id_report
    ).finally(setLoadingInv(false));
    // Investigation matches are determined
    const filteredAssignedInvestigations = investigationList.filter((inv) =>
      assignedInvestigation.some((assigned) => assigned.alias === inv.alias)
    );
    // Save the changes temporarily, but do not apply them until confirmation
    setSelectedInvestigation(filteredAssignedInvestigations);
  };
  // Close Assign Investigation Modal
  const hdlCloseModal = () => {
    setShowModal(false);
    setSelectedInvestigation([]);
    setSelectedReport([]);
  };
  // Prepare confirmation <Dialog/> (MUI)
  const hdlConfirmAction = (investigation, type) => {
    setConfirmDialogOpen(true); // Abrir el diálogo
    setInvestigationToModify(investigation);
    setActionType(type);
  };
  // When clicking on 'Confirm' in <Dialog/> (MUI)
  const hdlConfirmDialog = async () => {
    // Add/assign available investigation
    if (actionType === "add") {
      try {
        await createInvestigationAssign(
          investigationToModify.alias,
          selectedReport.id_report
        );
        enqueueSnackbar(t("information.investigation_assigned_successfully"), {
          variant: "success",
        });
      } catch (error) {
        console.error("Error adding investigation:", error);
      }
      // Remove/Unlinked previous assign investigation
    } else if (actionType === "remove") {
      try {
        await deleteInvestigationAssign(
          investigationToModify.alias,
          selectedReport.id_report
        );
        enqueueSnackbar(t("information.investigation_successfully_unlinked"), {
          variant: "success",
        });
      } catch (error) {
        console.error("Error removing investigation:", error);
      }
    }
    // Close & clean Dialog
    setConfirmDialogOpen(false);
    setInvestigationToModify(null);
    setActionType(null);
  };
  // Handle the closing of the unconfirmed <Dialog/>
  const hdlCancelDialog = () => {
    // Revert the temporary state to the previous state
    setSelectedInvestigation(prevTempInvState);
    setConfirmDialogOpen(false);
    setInvestigationToModify(null);
    setActionType(null);
  };
  // Handle changes in Autocomplete
  const handleInvestigationChange = (event, newValue) => {
    // Saves a “previous” state before opening the dialogue
    setPrevTempInvState(selectedInvestigation);
    // Is detected which one is not yet assigned
    const addedInvestigations = newValue.filter(
      (item) =>
        !selectedInvestigation.some((selected) => selected.alias === item.alias)
    );
    // The selected one is removed from the existing ones
    const removedInvestigations = selectedInvestigation.filter(
      (item) => !newValue.some((selected) => selected.alias === item.alias)
    );
    // <Dialog/> opens before confirming
    if (addedInvestigations.length > 0) {
      hdlConfirmAction(addedInvestigations[0], "add");
    } else if (removedInvestigations.length > 0) {
      hdlConfirmAction(removedInvestigations[0], "remove");
    }
    // Save the changes temporarily, but do not apply them until confirmation
    setSelectedInvestigation(newValue);
  };
  // Determination of the investigation title
  const getInvTitle = (investigation) => {
    return `#${investigation?.id} - ${
      investigation?.advance
        ? t("information.advanced")
        : t("information.basic")
    } ${parseDateToDDMMYYYY2(investigation?.created_at)}`;
  };

  // Delete Related Report Selected
  const removeRelatedSave = async (report) => {
    try {
      const resp = await deleteRelated({
        report_identifier: Number(id),
        report_to_relate_identifier: String(
          report.tracking_code || report.folio
        ),
      }).then((resp) => {
        if (resp) {
          enqueueSnackbar(
            t("information.report_has_been_successfully_unlinked"),
            { variant: "success" }
          );
          closeModal();
          responseReport();
        } else {
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="relatedReport">
      <Modal centered show={showModal} size="md">
        <Modal.Header>
          <Stack
            display="flex"
            alignItems="center"
            flexDirection="row"
            flexWrap="nowrap"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h4" mx="auto">
              {t("information.assign_investigation")}
            </Typography>
            <EGIconButton
              iconName="cancel_circle"
              iconColor="primary"
              onClick={hdlCloseModal}
            />
          </Stack>
        </Modal.Header>
        <Modal.Body className="mx-auto w-100">
          {loadingInv ? (
            <Loading />
          ) : (
            <Autocomplete
              multiple
              id="report-related"
              options={investigationList}
              disableClearable={true}
              value={selectedInvestigation}
              onChange={handleInvestigationChange}
              sx={{ width: "95%", mx: "auto" }}
              getOptionLabel={(option) => getInvTitle(option)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    sx={{
                      backgroundColor: theme.palette.blueGray100.main,
                      fontSize: 12,
                    }}
                    {...getTagProps({ index })}
                    label={
                      <Stack
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        flexDirection="row"
                        flexWrap="nowrap"
                      >
                        <Icon
                          size={18}
                          name={`${
                            option.advance
                              ? "outline_data"
                              : "outline_hierarchy"
                          }`}
                          color={
                            option.advance
                              ? theme.palette.primary.main
                              : theme.palette.success.main
                          }
                        />
                        <Typography variant="body1" mx={1}>
                          {getInvTitle(option)}
                        </Typography>
                      </Stack>
                    }
                  />
                ))
              }
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;

                return (
                  <Box
                    key={key}
                    {...optionProps}
                    sx={{
                      maxHeight: 47,
                      p: 2,
                      my: 0.5,
                      mx: 1,
                      borderRadius: 1.5,
                      border: `${theme.palette.blueGray50.main} 1px solid`,
                    }}
                  >
                    <Checkbox size="small" checked={selected} />
                    <Icon
                      size={18}
                      name={`${
                        option.advance ? "outline_data" : "outline_hierarchy"
                      }`}
                      color={
                        option.advance
                          ? theme.palette.primary.main
                          : theme.palette.success.main
                      }
                    />
                    <Typography variant="body1" mx={1}>
                      {getInvTitle(option)}
                    </Typography>
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t(
                    "information.assign_or_unlink_any_investigation"
                  )}:`}
                  placeholder={t("information.investigation_selection")}
                />
              )}
            />
          )}
        </Modal.Body>
      </Modal>
      {/* Confirm Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={hdlCancelDialog}
        PaperProps={{
          sx: {
            border: `2px solid ${theme.palette.red.main}`,
            borderRadius: "10px",
            p: 2,
          },
        }}
        maxWidth="sm"
        fullWidth
      >
        <Row>
          <Col sm={4} className="d-none d-sm-block m-auto p-2">
            <Ethiquin className="p-2" />
          </Col>
          <Col className="p-2">
            <Typography variant="h3" color={theme.palette.pink.main}>
              {actionType === "add"
                ? t("information.assign_investigation")
                : t("information.unlink_report")}
            </Typography>
            <DialogContent>
              <Typography
                variant="h6"
                marginY={2}
                color={theme.palette.primary.main}
              >
                {actionType === "add"
                  ? t("information.are_you_sure_linking_this_investigation", {
                      name: getInvTitle(investigationToModify),
                    })
                  : t("information.are_you_sure_to_unlink_this_report", {
                      name: getInvTitle(investigationToModify),
                    })}
              </Typography>
            </DialogContent>
            <DialogActions>
              <MCButton
                variant="secondary"
                onClick={hdlCancelDialog}
                label={t("information.cancel")}
                outline
                className="mx-1 w-50"
              />
              <MCButton
                onClick={hdlConfirmDialog}
                variant="primary"
                label={t("information.confirm")}
                className="w-50"
              />
            </DialogActions>
          </Col>
        </Row>
      </Dialog>

      <>
        <Stack
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          mb={1}
        >
          <Stack
            display="flex"
            alignItems="center"
            direction="row"
            justifyContent="start"
          >
            <Box
              sx={{
                backgroundColor: theme.palette.pink.main,
                borderRadius: "6px",
                px: "3px",
                mr: 1,
              }}
            >
              <BoldProgrammingArrow
                color={theme.palette.primary.contrastText}
                size={18}
              />
            </Box>
            <Typography variant="h6">{`${
              relatedList && relatedList.length > 0
                ? t("information.related_reports")
                : t("information.add_folio_related_case")
            }`}</Typography>
          </Stack>
          <Stack
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
            spacing={1}
          >
            <>
              {editing && (
                <EGIconButton
                  disabled={loading || relatedFolio.trim() === ""}
                  title={t("information.save")}
                  iconName="check_icon"
                  color="success"
                  placement="bottom"
                  onClick={handleRelatedSave}
                />
              )}
              <EGIconButton
                disabled={loading}
                iconName={editing ? "close_icon" : "add_square"}
                color={editing ? "error" : ""}
                iconColor={editing ? "" : "ethicsBlue"}
                title={editing ? t("information.cancel") : t("information.add")}
                placement="bottom"
                onClick={editing ? hdlCancelRelated : () => setEditing(true)}
              />
            </>
          </Stack>
        </Stack>
      </>
      <>
        {editing ? (
          <div>
            <TextField
              fullWidth
              type="search"
              label={"Folio:"}
              placeholder={`${t("information.add_related_report")}`}
              className="mt-2"
              disabled={uploadRelated}
              value={relatedFolio}
              onChange={(e) => setRelatedFolio(e.target.value)}
            />
            {addDetails ? (
              <TextField
                id="outlined-multiline-static"
                label={`${t("information.details")}:`}
                placeholder={t("information.enter_report_folio_tracking_code")}
                value={relatedComment}
                onChange={(e) => {
                  setRelatedComment(e.target.value);
                }}
                multiline
                rows={4}
                fullWidth
                className="mt-3"
              />
            ) : (
              <Stack display="flex" alignItems="end" marginTop="0.2rem">
                <Link onClick={() => setAddDetails(true)} underline="hover">
                  + {t("information.add_details")}
                </Link>
              </Stack>
            )}
          </div>
        ) : (
          <>
            {/* Show less report related */}
            {showAllRprtRltd && relatedList.length > showDefaultElements && (
              <Stack
                my={1}
                spacing={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                flexWrap="nowrap"
              >
                <span className="--line my-auto"></span>
                <Link
                  underline="hover"
                  align="center"
                  color="primary"
                  onClick={toggleShowAllRprtRltd}
                >
                  {t("information.see_less")}
                </Link>
                <span className="--line my-auto"></span>
              </Stack>
            )}

            {relatedList && relatedList.length > 0 && (
              <div className="mb-2">
                {relatedList
                  .slice(0, showAllRprtRltd ? relatedList.length : 2)
                  .map((reportRelated, i) => {
                    // Action Buttons of EGSpeedDial
                    const actions = [
                      {
                        key: "view",
                        icon: (
                          <OutlineEye color={theme.palette.secondary.main} />
                        ),
                        name: t("information.view_report"),
                        onClick: () =>
                          window.open(
                            `/reports/${reportRelated?.id_report}`,
                            "_blank",
                            "noopener,noreferrer"
                          ),
                      },
                      ...((Array.isArray(investigationList) &&
                        investigationList?.length > 0 && [
                          {
                            key: "assign",
                            icon: (
                              <OutlineDocumentText
                                color={theme.palette.teal.main}
                              />
                            ),
                            name: t("information.assign_investigation"),
                            onClick: () => hdlOpenModal(reportRelated),
                          },
                        ]) ||
                        []),
                      {
                        key: "edit",
                        icon: (
                          <OutlineEdit color={theme.palette.primary.main} />
                        ),
                        name: t("information.edit"),
                        onClick: () => {
                          setEditing(true);
                          setRelatedFolio(reportRelated.folio);
                          setTrackingCode(reportRelated.tracking_code);
                          setRelatedComment(reportRelated.comment);
                          setUploadRelated(true);
                        },
                      },
                      {
                        key: "delete",
                        icon: <TrashCan color={theme.palette.error.main} />,
                        name: t("information.delete"),
                        onClick: () => {
                          openModalAction(
                            () => removeRelatedSave(reportRelated),
                            t("information.unlink_report"),
                            t(
                              "information.unlinking_this_report_cause_lose_direct_access_and_associated_information"
                            ),
                            t("information.this_action_cannot_be_reversed"),
                            t(
                              "information.are_you_sure_you_want_perform_this_action"
                            )
                          );
                        },
                      },
                    ];

                    return (
                      <Box
                        key={i}
                        sx={{
                          backgroundColor: theme.palette.pink.main,
                          position: "relative",
                        }}
                        className="rounded my-3"
                      >
                        <EGSpeedDial
                          direction="left"
                          sx={{ position: "absolute", top: 8, right: 10 }}
                          actions={actions}
                          tooltipPlacement="bottom"
                        />
                        <Typography
                          variant="h6"
                          display="flex"
                          alignItems="center"
                          maxWidth="75%"
                          sx={{
                            wordBreak: "break-all",
                            color: theme.palette.primary.contrastText,
                            py: "15px",
                            px: "5px",
                          }}
                        >
                          <BoldDocumentText
                            color={theme.palette.primary.contrastText}
                            className="mx-2"
                          />
                          {reportRelated.folio}
                        </Typography>
                        {reportRelated.comment && (
                          <div className="comment">
                            <Comments text={reportRelated.comment} />
                          </div>
                        )}
                      </Box>
                    );
                  })}
                {/* Show more report relateds */}
                {!showAllRprtRltd &&
                  relatedList.length > showDefaultElements && (
                    <Stack
                      my={1}
                      spacing={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="row"
                      flexWrap="nowrap"
                    >
                      <span className="--line my-auto"></span>
                      <Link
                        underline="hover"
                        align="center"
                        color="primary"
                        onClick={toggleShowAllRprtRltd}
                      >
                        {viewMore}
                      </Link>
                      <span className="--line my-auto"></span>
                    </Stack>
                  )}
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};
