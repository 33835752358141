import SearchBar from "../../SearchBar/Search";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { ProgressBar } from "react-bootstrap";
import { getReports, getReportsWithPagination } from "../../../apis";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { parseDateToLgComun } from "../../../helpers/dateTransform";
import {
  OutlineEye,
  OutlineMessages3,
  OutlineDeviceMessage,
  Outline3dCubeScan,
  OutlineAlarm,
  OutlineUserMinus,
  OutlineLock,
  Paperclip,
  OutlineDirectboxReceive,
  Monitor,
  Call,
  OutlineSms,
  OutlineMessages2,
  Mobile,
  Grammerly,
  CdIcon,
  OutlineHierarchy,
  OutlineRouting2,
  OutlineClipboardTick,
  OutlineArchiveBook,
  OutlineDocumentLike,
  OutlineBuyCrypto,
} from "../../Icon";
import { Filter } from "./Filter";
import { GeneralIconHeader } from "../../Title/GeneralHeader";
import { Loading } from "../../Loading/String/Loading";
import { MCTooltip } from "../../MainComponents/Tooltip/Tooltip";
import { Breadcrum } from "../../MainComponents/Breadcrumb/Breadcrumb";
import "./styles.scss";
// Status ClassName Btn Color
const returnStatusClass = (status) => {
  if (status === "new") return "status-new";
  if (status === "finish") return "status-finish";
  if (status === "pending") return "status-pending";
  if (status === "closed") return "status-closed";
  if (status === "process") return "status-process";
  if (status === "draft") return "status-default";
};
// Status Label
const returnStatusLabel = (status, translates) => {
  if (status === "new") return translates["new"];
  if (status === "finish") return translates["finish"];
  if (status === "pending") return translates["pending"];
  if (status === "closed") return translates["closed"];
  if (status === "process") return translates["process"];
  if (status === "draft") return translates["draft"];
};

// All reports authorized to view
export const ReportsList = () => {
  const [t] = useTranslation("report");

  // ----- use Hooks ----- //
  const navigate = useNavigate();
  // ref to input search page
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  //----useState ----//
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0); //Total Reports
  const [limit, setLimit] = useState(10); //Limit of results per consult
  const [currentPage, setCurrentPage] = useState(0); //Current Page
  const [offset, setOffset] = useState(0); //Set offset to getData
  const [next, setNext] = useState(null); //Next URL
  const [previous, setPrevious] = useState(null); //Prev URL
  const [pageNumLimit, setPageNumLimit] = useState(10); //Limit of pages on screen
  const [maxPageNum, setMaxPageNum] = useState(10); //Number of page max on screen
  const [minPageNum, setMinPageNum] = useState(0); //Number of page min on screen
  const [searchPag, setSearchPag] = useState(""); //Value of input Search
  const [listReports, setListReports] = useState(null); //Report Data
  const [expiredReports, setExpiredReports] = useState(null);
  const [folio, setFolio] = useState(""); //string
  const [tracking_code, setTracking_code] = useState(""); //string
  const [month_created, setMonth_created] = useState("01"); // 01 || 02 ... 11
  const [year_created, setYear_created] = useState("2023"); //YYYY
  const [before_created, setBefore_created] = useState(new Date()); //date
  const [after_created, setAfter_created] = useState(new Date()); //date
  const [r_start_created, setR_start_created] = useState(new Date()); //date
  const [r_end_created, setR_end_created] = useState(new Date()); //date
  const [created, setCreated] = useState(new Date()); // date
  const [open_days_less_than, setOpen_days_less_than] = useState(1); // number
  const [open_days_greater_than, setOpen_days_greater_than] = useState(1); // number
  const [r_start_open_days, setR_start_open_days] = useState(1); // number
  const [r_end_open_days, setR_end_open_days] = useState(1);
  const [status, setStatus] = useState(""); // date
  // Initial state of checkbox Filter
  const [checksFilter, setChecksFilter] = useState({
    folio: false,
    tracking_code: false,
    month_created: false,
    year_created: false,
    before_created: false,
    after_created: false,
    range_of_created: false,
    created: false,
    open_days_less_than: false,
    open_days_greater_than: false,
    open_days_range: false,
    status: false,
    report_classification: false,
    kind_of_grievance: false,
    kind_of_entryways: false,
    kind_of_severity: false,
  });
  const [reportClassificationSelected, setReportClassificationSelected] =
    useState(null);
  const [kind_of_grievance, setKind_of_grievance] = useState(null);
  const [kind_of_severity, setKind_of_severity] = useState(null);
  const [kind_of_entryways, setKind_of_entryways] = useState(null);

  const status_locales = {
    new: t("status.new"),
    process: t("status.process"),
    finish: t("status.finish"),
    pending: t("status.pending"),
    closed: t("status.closed"),
    draft: t("status.draft"),
  };

  const optionsStatus = [
    { value: "new", label: t("status.new") },
    { value: "process", label: t("status.process") },
    { value: "finish", label: t("status.finish") },
    { value: "pending", label: t("status.pending") },
    { value: "closed", label: t("status.closed") },
    { value: "draft", label: t("status.draft") },
  ];

  const limite = localStorage.getItem("limit");

  const location = useLocation();

  const [selectURL, setSelectURL] = useState(null)

  //Fill with previous registers avaiable in DB
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if(location.pathname!='/reports/all'){
      console.log(" ");
      setCurrentPage(0)
      let filtro=location.pathname.split('/')
      // let filtroParaPuebas =  


      const selectedOption = optionsStatus.find(option => option.value === filtro[filtro.length-1].replace(":",""));

      // Ahora puedes usar `selectedOption` como desees
      setSelectURL(selectedOption);

      filtro= {status:filtro[filtro.length-1].replace(":","")}
      setIsLoading(true);
      setListReports(null);
      checksFilter.status = true;
   
      const paramsForGetReport = {
        ...(checksFilter.status && { ...filtro }),
        offset:0,
        limit,
      };
      getReports(paramsForGetReport, queryParams.get("order_by")).then((resp) => {
        setListReports(resp.results?.reports || [])
        setCount(resp.count || 0);
        setNext(resp.next);
        setPrevious(resp.previous);
        setExpiredReports(resp.results?.expired_report || 0);
        setIsLoading(false);
      });
      setStatus(selectedOption)

    }else{
      console.log(" ");
      setCurrentPage(0)

      checksFilter.status = false;
      setStatus()
      setSelectURL();

      getReports(null, queryParams.get("order_by")).then((resp) => {
        setListReports(resp.results?.reports || []);
        setCount(resp.count || 0);
        setNext(resp.next);
        setPrevious(resp.previous);
        setExpiredReports(resp.results?.expired_report || 0);
        setIsLoading(false);
      });
    }
  }, [location.pathname]);

  const breadcrumbItems = [
    { label: t("ReportsList.home"), route: "/" },
    { label: t("ReportsList.all_reports") },
  ];

  // Recall reports with params
  const handleReCallReports = (evt) => {
    const queryParams = new URLSearchParams(location.search);
    let filtro=location.pathname.split('/')
    // console.log(filtro[filtro.length-1].replace(":",""))
    setStatus(filtro[filtro.length-1].replace(":",""))
    // console.log(status)
    // console.log(location.pathname)
    setIsLoading(true);
    setListReports(null);

    const paramsForGetReport = {
      ...(checksFilter.folio && { folio }),
      ...(checksFilter.tracking_code && { tracking_code }),
      ...(checksFilter.month_created && { month_created }),
      ...(checksFilter.year_created && { year_created }),
      ...(checksFilter.before_created && { before_created }),
      ...(checksFilter.after_created && { after_created }),
      ...(checksFilter.range_of_created && { r_start_created, r_end_created }),
      ...(checksFilter.created && { created }),
      ...(checksFilter.open_days_less_than && { open_days_less_than }),
      ...(checksFilter.open_days_greater_than && { open_days_greater_than }),
      ...(checksFilter.report_classification && {
        report_classification: reportClassificationSelected.value,
      }),
      ...(checksFilter.open_days_range && {
        r_start_open_days,
        r_end_open_days,
      }),
      ...(checksFilter.kind_of_grievance && {
        kind_of_grievance: kind_of_grievance.value,
      }),
      ...(checksFilter.kind_of_severity && {
        kind_of_severity: kind_of_severity.value,
      }),
      ...(checksFilter.kind_of_entryways && {
        kind_of_entryways: kind_of_entryways.value,
      }),
      ...(checksFilter.status && { status }),
      offset,
      limit,
    };
  
    //-----onChange Event-----//
    if (evt?.target?.name === "limit") {
      // Files per page
      paramsForGetReport["limit"] = evt.target.value;
      paramsForGetReport["offset"] = 0;
      setLimit(evt.target.value);
      setOffset(0);
      setCurrentPage(0);
      setMaxPageNum(pageNumLimit);
      setMinPageNum(0);
      setSearchPag("");
      localStorage.setItem("limit", evt.target.value);
    } else if (evt?.target?.name === "searchingPage") {
      // Value in input search page
      paramsForGetReport["offset"] = evt.target.value - 1;
      paramsForGetReport["limit"] = limite || 10;
      setCurrentPage(evt.target.value - 1);
      setOffset(evt.target.value - 1);
    } else if (evt?.target?.name === "number") {
      // Index of each page on screen
      paramsForGetReport["offset"] = evt.target.value;
      setOffset(evt.target.value);
    } else if (evt?.currentTarget?.name === "section") {
      // Index for section pages divider
      paramsForGetReport["offset"] = evt.currentTarget.value;
      setOffset(evt.currentTarget.value);
    }
    // Get data into response
    getReports(paramsForGetReport, queryParams.get("order_by")).then((resp) => {
      // console.log("GET-REPORTS response:", resp);
      setListReports(resp.results?.reports || []);
      setCount(resp.count || 0);
      setNext(resp.next);
      setPrevious(resp.previous);
      setExpiredReports(resp.results?.expired_report || 0);
      setIsLoading(false);
    });
  };

  const caseFolioSort = (rowA, rowB) => {
    const a = rowA.folio.toLowerCase();
    const b = rowB.folio.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const caseCreatedAtSort = (rowA, rowB) => {
    const a = rowA.created_at.toLowerCase();
    const b = rowB.created_at.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseEntryWay = (rowA, rowB) => {
    const a = rowA.entryway.toLowerCase();
    const b = rowB.entryway.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseOpenDays = (rowA, rowB) => {
    const a = rowA.open_days;
    const b = rowB.open_days;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const PresentIndicators = ({ indicators, entryway, classification }) => {
    const indicatorItems = [];
    const iconIndicators = {
      has_plan: {
        text: t("ReportsList.has_plan"),
        element: <OutlineDeviceMessage className="mx-1 icon-indicator" />,
      },
      is_locked: {
        text: t("ReportsList.is_locked"),
        element: <OutlineLock className="mx-1 icon-indicator" />,
      },
      has_message: {
        text: t("ReportsList.has_message"),
        element: <OutlineDirectboxReceive className="mx-1 icon-indicator" />,
      },
      is_severity: {
        text: t("ReportsList.is_severity"),
        element: <OutlineAlarm className="mx-1 icon-indicator-is_severity" />,
      },
      is_anonymous: {
        text: t("ReportsList.is_anonymous"),
        element: <OutlineUserMinus className="mx-1 icon-indicator" />,
      },
      has_complement: {
        text: t("ReportsList.has_complement"),
        element: <Outline3dCubeScan className="mx-1 icon-indicator" />,
      },
      has_attachments: {
        text: t("ReportsList.has_attachments"),
        element: <Paperclip className="mx-1 icon-indicator" />,
      },
      has_relateds: {
        text: t("ReportsList.has_relateds"),
        element: <OutlineHierarchy className="mx-1 icon-indicator" />
      },
      has_followup: {
        text: t("ReportsList.has_followup"),
        element: <OutlineRouting2 className="mx-1 icon-indicator" />
      }
    };

    const iconEntryway = {
      WEBSITE: {
        element: <Monitor className="mx-1 icon-indicator" />,
      },
      PHONE: {
        element: <Call className="mx-1 icon-indicator" />,
      },
      EMAIL: {
        element: <OutlineSms className="mx-1 icon-indicator" />,
      },
      CHAT: {
        element: <OutlineMessages3 className="mx-1 icon-indicator" />,
      },
      WHATSAPP: {
        element: <OutlineMessages2 className="mx-1 icon-indicator" />,
      },
      APP: {
        element: <Mobile className="mx-1 icon-indicator" />,
      },
      OUTDOOR: {
        element: <Grammerly className="mx-1 icon-indicator" />,
      },
      OTHER: {
        element: <CdIcon className="mx-1 icon-indicator" />,
      },
    };

    const iconType = {
      GRIEVANCE: {
        element: <OutlineClipboardTick className="mx-1 icon-indicator" />,
      },
      QUESTION: {
        element: <OutlineArchiveBook className="mx-1 icon-indicator" />,
      },
      SUGGESTION: {
        element: <OutlineDocumentLike className="mx-1 icon-indicator" />,
      },
      UNKNOWN: {
        element: <OutlineBuyCrypto className="mx-1 icon-indicator" />,
      }
    }

    const getTypeLabel = (type) => {
      switch (type) {
        case 'type::grievance':
          return 'GRIEVANCE';
        case 'type::questions':
          return 'QUESTION';
        case 'type::suggestion':
          return 'SUGGESTION';
        default:
          return 'UNKNOWN';
      }
    };


    let severity_element = null;

    let typeLabel = getTypeLabel(classification);

    // Add type indicator
    indicatorItems.push(
      <MCTooltip
        text={t(`ReportsList.types.${typeLabel}`)}
        position="bottom"
        color
        key={typeLabel}
      >
        <button className="btnIcon">{iconType[typeLabel]?.element}</button>
      </MCTooltip>
    );
    

    // Add Entryway in Icon Indicator Form
    indicatorItems.push(
      <MCTooltip
        text={t(`ReportsList.entryways.${entryway}`)}
        position="bottom"
        color
        key={entryway}
      >
        <button className="btnIcon">{iconEntryway[entryway].element}</button>
      </MCTooltip>
    );
    

    if (indicators) {
      for (const [key, value] of Object.entries(indicators)) {
        if (value) {
          if (key !== "is_severity") {
            indicatorItems.push(
              <MCTooltip
                text={iconIndicators[key]?.text}
                position="bottom"
                color
                key={key}
              >
                <button className="btnIcon">
                  {iconIndicators[key]?.element}
                </button>
              </MCTooltip>
            );
          } else {
            severity_element = (
              <MCTooltip
                text={iconIndicators[key].text}
                position="bottom"
                color
                key={key}
              >
                <button className="btnIcon">
                  {iconIndicators[key].element}
                </button>
              </MCTooltip>
            );
          }
        }
      }
    }

    if (severity_element !== null) indicatorItems.push(severity_element);

    if (indicatorItems.length <= 0) {
      return <p className="my-auto"></p>;
    }

    return indicatorItems;
  };

  //Table columns with react-data-table-component
  const returnColumns = [
    // {
    //   name: "",
    //   button: true,
    //   cell: (row) => {
    //     return (
    //       <div class={`${returnStatusClass(
    //         row.status_
    //       )}-cell`}>
    //         <div class="indicator"></div>
    //       </div>
    //     );
    //   },
    // },

    {
      name: <h6>{t("ReportsList.status")}</h6>,
      button: true,
      cell: (row) => {
        return (
          <button
            onClick={() => navigate(`/reports/${row.id}`)}
            className={`${returnStatusClass(
              row.status_
            )} w-100 my-auto tabletd btnStatus`}
          >
            {returnStatusLabel(row.status_, status_locales)}
          </button>
        );
      },
    },

    {
      name: <h6>{t("ReportsList.folio")}</h6>,
      selector: (row) => row.folio,
      cell: (row) =>
        row.folio ? (
          <p className="my-auto tabletd">{row.folio}</p>
        ) : (
          <p className="my-auto tabletd">{t("ReportsList.no_folio")}</p>
        ),
      sortable: true,
      sortFunction: caseFolioSort,
    },
    // {
    //   name: <h6>{t("ReportsList.entryway")}</h6>,
    //   cell: (row) =>
    //     row.entryway ? (
    //       <p className="my-auto tabletd">
    //         {t(`ReportsList.entryways.${row.entryway}`)}
    //       </p>
    //     ) : (
    //       <p className="my-auto tabletd">{t("Reportslist.no_entryway")}</p>
    //     ),
    //     sortable: true,
    //     sortFunction: caseEntryWay
    // },
    {
      name: <h6>{t("ReportsList.subject")}</h6>,
      cell: (row) =>
        row.subject ? (
          <p className="my-auto tabletd">{row.subject}</p>
        ) : (
          <p className="my-auto tabletd">{t("ReportsList.no_subject")}</p>
        ),
    },

    {
      name: <h6>{t("ReportsList.indicators")}</h6>,
      selector: (row) => (
        <div
          style={{
            margin: "2px 0",
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-between",
          }}
        >
          <PresentIndicators
            indicators={row.indicators}
            entryway={row.entryway}
            classification={row.classification}
          />
        </div>
      ),
    },
    {
      name: <h6>{t("ReportsList.Open_days")}</h6>,
      cell: (row) => {
        // console.log("STATUS");
        // console.log(row.status);
        const currentDate = new Date();
        const closedDate = row.status?.assigned
          ? new Date(row.status.assigned)
          : null;
        const timeDifference = Math.abs(currentDate - closedDate);
        const daysDifference = Math.ceil(
          timeDifference / (1000 * 60 * 60 * 24)
        );

        return (
          <div>
            {row.status_ === "closed" ? (
              <p className="my-auto tabletd">
                {t("ReportsList.close_days")} {daysDifference}{" "}
                {t("ReportsList.days_ago")}
              </p>
            ) : (
              <>
                <p className="my-auto tabletd">
                  {row.open_days} {t("ReportsList.Open_days")} &gt;{" "}
                  {expiredReports} {t("ReportsList.days")}
                </p>
                <ProgressBar
                  animated
                  now={(row.open_days * 100) / expiredReports}
                  variant={row.open_days > expiredReports ? "danger" : "info"}
                />
              </>
            )}
          </div>
        );
      },
      sortable: true,
      sortFunction: caseOpenDays,
    },
    {
      name: <h6>{t("ReportsList.created_at")}</h6>,
      selector: (row) =>
        row.created_at_time_zone ? (
          <p className="my-auto tabletd">
            {parseDateToLgComun(row.created_at_time_zone)}
          </p>
        ) : (
          <p className="my-auto tabletd">{t("ReportsList.no_date")}</p>
        ),
      sortable: true,
      sortFunction: caseCreatedAtSort,
    },
    {
      name: <h6>{t("ReportsList.actions")}</h6>,
      button: true,
      cell: (row) => {
        return (
          <button
            onClick={() => navigate(`/reports/${row.id}`)}
            className={`clean-button my-auto `}
          >
            <OutlineEye float="left" width="24" height="24" />
          </button>
        );
      },
    },
  ];
  /** ------ PAGINATION ------
   * In this section determines the navigation between pages according to the distribution of the registered reports.
   * This, in turn, is determined with the Count/Limit calculation and the index is assigned to each button in the renderPagesNumber function.
   *  eg: 100/10 = 10 (reports per page)
   */
  //Navigate through the navigation pages
  const handlePagination = (evt) => {
    setSearchPag("");
    let pageNumber = Number(evt.target.id);
    setCurrentPage(pageNumber);
    handleReCallReports(evt);
  };
  //Change page on previos or next registers
  const handlePage = async (url) => {
    setIsLoading(true);
    try {
      await getReportsWithPagination(url).then((resp) => {
        setListReports(resp.results?.reports || []);
        setNext(resp.next);
        setPrevious(resp.previous);
        setIsLoading(false);
      });
    } catch (error) {
      console.error(error); 
    }
  };
  // Distribution of results on pages according to selected rows
  const pages = [];
  const page = count / limit;
  for (let i = 0; i < Math.ceil(page); i++) {
    pages.push(i);
  }
  // Renders the buttons with the page numbers for navigation
  const renderPagesNumber = pages.map((number) => {
    if (number < maxPageNum && number >= minPageNum) {
      return (
        <button
          key={number}
          id={number}
          name="number"
          onClick={handlePagination}
          value={number * limit}
          className={
            currentPage === number
              ? "paginator__btnPag --activate"
              : "paginator__btnPag --scale09"
          }
          disabled={currentPage === number}
        >
          {number + 1}
        </button>
      );
    }
  });
  // Event on pressing 'Enter' key
  useEffect(() => {
    const keyDown = (evt) => {
      if (
        evt.key === "Enter" &&
        (evt.target === inputRef1.current || evt.target === inputRef2.current)
      ) {
        evt.preventDefault(evt);
        handleSearchPage(evt);
      }
    };
    document.addEventListener("keydown", keyDown);
    return () => {
      document.removeEventListener("keydown", keyDown);
    };
  });
  // Searching page on input
  const handleSearchPage = (evt) => {
    setSearchPag("");
    setCurrentPage(evt.target.value - 1);
    handleReCallReports(evt);
    setMaxPageNum(Number(evt.target.value));
    setMinPageNum(Number(evt.target.value) - pageNumLimit);
  };
  // If there are a next page
  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    setOffset((currentPage + 1) * limit);
    handlePage(next);
    setSearchPag("");
    if (currentPage + 1 >= maxPageNum) {
      setMaxPageNum(Number(maxPageNum) + pageNumLimit);
      setMinPageNum(minPageNum + pageNumLimit);
    }
  };
  // If there are a previous page
  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
    setOffset((currentPage - 1) * limit);
    handlePage(previous);
    setSearchPag("");
    if (currentPage - 1 < minPageNum) {
      setMaxPageNum(Number(maxPageNum) - pageNumLimit);
      setMinPageNum(minPageNum - pageNumLimit);
    }
  };
  // Go to the next section of pages
  const handleNextSection = (evt) => {
    setMaxPageNum(maxPageNum + pageNumLimit);
    setMinPageNum(minPageNum + pageNumLimit);
    setCurrentPage(minPageNum + pageNumLimit);
    handleReCallReports(evt);
  };
  // Go to previous section of pages
  const handlePrevSection = (evt) => {
    setMaxPageNum(maxPageNum - pageNumLimit);
    setMinPageNum(minPageNum - pageNumLimit);
    setCurrentPage(maxPageNum - pageNumLimit - 1);
    handleReCallReports(evt);
  };
  // Go to last page
  const handleLastPage = (evt) => {
    setMaxPageNum(pages.length);
    setMinPageNum(pages.length - pageNumLimit);
    handleReCallReports(evt);
    setCurrentPage(pages.length - 1);
  };
  // Go to first page
  const handleFirstPage = (evt) => {
    setMaxPageNum(pageNumLimit);
    setMinPageNum(0);
    handleReCallReports(evt);
    setCurrentPage(0);
  };
  // --- Only if the page limit is greater than 10, the tools are enabled to navigate between results. ---//
  // Navigate between next pages
  let pageIncrement = null;
  if (pages.length > maxPageNum) {
    pageIncrement = (
      <>
        <button
          className="paginator__btnSection --scale09"
          onClick={handleNextSection}
          value={(pageNumLimit + minPageNum) * limit}
          name="section"
        >
          <p>&#8250;&#8250;</p>
        </button>
        <input
          type="number"
          name="searchingPage"
          placeholder="..."
          value={searchPag}
          max={pages.length}
          min={1}
          onChange={(evt) => setSearchPag(evt.target.value)}
          className="paginator__searchPage"
          ref={inputRef2}
        />
        <button
          id={Number(pages.length) - 1}
          className="paginator__btnPag --scale09"
          onClick={handleLastPage}
          value={Math.abs(pages.length - 1) * limit}
          name="number"
        >
          {pages.length}
        </button>
      </>
    );
  }
  // Navigate between previous pages
  let pageDecrement = null;
  if (minPageNum >= 1) {
    pageDecrement = (
      <>
        <button
          className="paginator__btnPag --scale09"
          onClick={handleFirstPage}
          name="number"
          value={0}
        >
          1
        </button>
        <input
          type="number"
          name="searchingPage"
          placeholder="..."
          value={searchPag}
          max={pages.length}
          min={1}
          onChange={(evt) => setSearchPag(evt.target.value)}
          className="paginator__searchPage"
          ref={inputRef1}
        />
        <button
          className="paginator__btnSection --scale09"
          onClick={handlePrevSection}
          value={Math.abs(maxPageNum - pageNumLimit - 1) * limit}
          name="section"
        >
          <p>&#8249;&#8249;</p>
        </button>
      </>
    );
  }

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  const handleRowClick = (row) => {
    // Navegar a la ruta del reporte específico
    navigate(`/reports/${row.id}`);
  };

  return (
    <div className={`reportList page`}>
      {/* Reports Table Title */}
      <GeneralIconHeader
        title={t("ReportsList.all_reports")}
        text={t("ReportsList.all_reports_list")}
        icon="outline_document_filter"
      />
      <Breadcrum items={breadcrumbItems} />
      <div className="dyTheme1 dyBorder1 p-sm-4 reportsTable">
        {isLoading ? (
          <Loading />
        ) : (
          // Start Report Table
          <div>
            {/* Table Sub-header */}
            <div className="dyBorder1 dyTheme2 rounded sub-header my-auto tabletd">
              <div className="sub-header__settings">
                <small className="ml-2 showContent">
                  {`${t("ReportsList.showing")} ${count} ${t(
                    "ReportsList.report"
                  )}${count > 1 ? "s" : ""}`}
                </small>
              </div>
              <div className="filters">
                <Filter
                  handleReCallReports={handleReCallReports}
                  checksFilter={checksFilter}
                  setChecksFilter={setChecksFilter}
                  folio={folio}
                  setFolio={setFolio}
                  tracking_code={tracking_code}
                  setTracking_code={setTracking_code}
                  month_created={month_created}
                  setMonth_created={setMonth_created}
                  year_created={year_created}
                  setYear_created={setYear_created}
                  before_created={before_created}
                  setBefore_created={setBefore_created}
                  after_created={after_created}
                  setAfter_created={setAfter_created}
                  r_start_created={r_start_created}
                  setR_start_created={setR_start_created}
                  r_end_created={r_end_created}
                  setR_end_created={setR_end_created}
                  created={created}
                  setCreated={setCreated}
                  open_days_less_than={open_days_less_than}
                  setOpen_days_less_than={setOpen_days_less_than}
                  open_days_greater_than={open_days_greater_than}
                  setOpen_days_greater_than={setOpen_days_greater_than}
                  r_start_open_days={r_start_open_days}
                  setR_start_open_days={setR_start_open_days}
                  r_end_open_days={r_end_open_days}
                  setR_end_open_days={setR_end_open_days}
                  status={status}
                  setStatus={setStatus}
                  reportClassificationSelected={reportClassificationSelected}
                  setReportClassificationSelected={
                    setReportClassificationSelected
                  }
                  kind_of_grievance={kind_of_grievance}
                  setKind_of_grievance={setKind_of_grievance}
                  kind_of_severity={kind_of_severity}
                  setKind_of_severity={setKind_of_severity}
                  kind_of_entryways={kind_of_entryways}
                  setKind_of_entryways={setKind_of_entryways}
                  selectURL={selectURL}
                />
              </div>
            </div>
            {/* TableBody */}
            {!isLoading && listReports && (
              <div className="dataTable">
                <DataTable
                  direction="ltr"
                  subHeaderAlign="left"
                  columns={returnColumns}
                  data={listReports} // Utilizamos la variable 'data' que hemos definido previamente
                  fixedHeader
                  responsive
                  highlightOnHover
                  onRowClicked={handleRowClick}
                />
                {/* Paginator */}
                <nav className="paginator dyTheme1">
                  {/* Select row per page */}
                  <div className="paginator__filesPerPage">
                    <small className="p-1">{t("ReportsList.per_page")}: </small>
                    <select
                      className="paginator__select"
                      name="limit"
                      value={limit}
                      onChange={handleReCallReports}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                  <div className="paginator__btnsAction">
                    <div className="paginator__renderPagesNumber">
                      {pageDecrement}
                      <button
                        className={`paginator__btnSection --scale09 ${
                          !previous ? "--disabled" : ""
                        }`}
                        onClick={handlePrev}
                        disabled={!previous}
                      >
                        <p>&#8249;</p>
                      </button>
                      {renderPagesNumber}
                      <button
                        className={`paginator__btnSection --scale09 ${
                          !next ? "--disabled" : ""
                        }`}
                        onClick={handleNext}
                        disabled={!next}
                      >
                        <p>&#8250;</p>
                      </button>
                      {pageIncrement}
                    </div>
                  </div>
                  <div>
                    <small className="paginator__results">
                      {`${t("ReportsList.results")}: ${
                        count === 0 ? 0 : Number(offset) + 1
                      } ${t("ReportsList.to")} ${
                        listReports.length + Number(offset)
                      } ${t("ReportsList.of")} ${Number(count)}`}
                    </small>
                  </div>
                </nav>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
