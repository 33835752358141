import { enqueueSnackbar } from "notistack";
import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;

//function to handle errors and display them to the user
const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};
// MAIN //
export const getInvestigation = async (alias = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/reports/investigation/${alias}`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_retrieve_report_data} (${error.response.status})`
    );
    console.error(error);
  }
};

export const createInvestigation = async (id, type) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/reports/investigation/create/`,
      { assigns: [id], type: type },
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_create_investigation} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const patchInvestigation = async (alias = "", form = {}) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall
      .patch(`${tenant}/api/reports/investigation/${alias}`, form, {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      })
      .then((resp) => {
        enqueueSnackbar(`${language.data_update_successful}`, {
          variant: "success",
          autoHideDuration: 10000,
        });
        return resp.data;
      })
      .catch((err) => {
        showErrorMessage(`${language.error_update_data}`);
      });
  } catch (error) {
    showErrorMessage(
      `${language.error_update_data} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const getInvestigationListByReportId = async (id = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/reports/investigation/list/${id}`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );

    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_retrieve_investigation_data} (${error.response.status})`
    );
    console.error(error.message);
    throw new Error(language.error_retrieve_investigation_data);
  }
};

// Get all offenders from investigation by Report ID
export const getOffendersListByReportId = async (id = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/reports/investigation/offenders/${id}`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_retrieving_list_offenders_report} (${error.response.status})`
    );
    console.error(error.message);
    // Prevents crash
    return [];
  }
};

// Get all victims from investigation by Report ID
export const getVictimsListByReportId = async (id = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/reports/investigation/victims/${id}`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_retrieving_list_victims_report} (${error.response.status})`
    );
    console.error(error.message);
    // Prevents crash
    return [];
  }
};

// STATUS //
export const getInvestigationStatus = async (id = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/reports/investigation/${id}/status`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_retrieving_data} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const updateInvestigationStatus = async (status = "", id = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/reports/investigation/${id}/status`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_update_data} (${error.response.status})`
    );
    console.error(error.message);
  }
};

// ASIGNS //
export const getInvestigationAssign = async (alias = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/reports/investigation/${alias}/assign`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_update_data} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const updateInvestigationAssign = async (reports = [], id = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.patch(
      `${tenant}/api/reports/investigation/${id}/assign`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_update_data} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const createInvestigationAssign = async (alias, id) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/reports/investigation/${alias}/assign`,
      { report: id },
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_update_data} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const deleteInvestigationAssign = async (alias, id) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.delete(
      `${tenant}/api/reports/investigation/${alias}/assign`,
      {
        data: {
          report: Number(id),
        },
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_deleting_investigation_assignment} (${error.response.status})`
    );
    console.error(error.message);
  }
};
