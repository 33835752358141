import { Navigate, Route, Routes } from "react-router-dom"
import { useSelector } from 'react-redux';

import { Sidebar } from "../components/GlobalComponents/Sidebar";
import { Navbar } from "../components/GlobalComponents/Navbar";
import { Dashboard } from "../components/Dashboard/Dashboard";
import { ReportsList } from "../components/Report/Lists/ReportsList"
import { ReportViewer } from "../components/Report/All/ReportViewer";
import { ConfigInterview } from "../components/Report/All/Investigation/Entrevista/ConfigInterview";
import { StartInterview } from "../components/Report/All/Investigation/Entrevista/StartInterview";
import { AnalyzeInterview } from "../components/Report/All/Investigation/Entrevista/AnalyzeInterview";
import { ConfigEvidence } from "../components/Report/All/Investigation/Evidencia/ConfigEvidence";
import { CustodyEvidence } from "../components/Report/All/Investigation/Evidencia/CustodyEvidence";
import { ProfileScreen } from "../components/Profile/ProfileScreen";

import { Users } from "../components/Users/Users";
import { User } from "../components/Users/User";
import { EditUser } from "../components/Users/EditUser";
import { RolesUser } from "../components/Users/Roles/RolesUser";
import { NewRoleUser } from "../components/Users/Roles/NewRoleUser";
import { Segmentation } from "../components/Users/Segmentation/Segmentation";
import { SegmentationList } from "../components/Users/Segmentation/SegmentationList";
import { MenusUser } from "../components/Users/Menus/MenusUser";
import { NewMenuUser } from "../components/Users/Menus/NewMenuUser";
import { PermitsUser } from "../components/Users/Permits/PermitsUser";

import { Catalogues } from "../components/Catalogues/Catalogues";
import { FormsList } from "../components/JSONForms/FormsList/FormsList";
import { CreateForm } from "../components/JSONForms/CreateForm/CreateForm";
import { NewReport } from "../components/JSONForms/Report/NewReport";

import { Settings } from "../screens/Settings/Main/Settings";
import { BusinessUnit } from "../screens/Settings/BusinessUnit/BusinessUnit";
import { LandingPageSettings } from "../screens/Settings/LandingPageSettings/LandingPageSettings";
import { PeopleSettings } from "../screens/Settings/People/PeopleSettings";
import { WorkplaceSettings } from "../screens/Settings/Workplace/WorkplaceSettings";
import { DepartmentSettings } from "../screens/Settings/Departaments/DepartmentSettings";
import { ReportSettings } from "../screens/Settings/Reports/ReportSettings";
import { ClientSignup } from "../screens/Auth/ClientSignup";
import { NotFound } from "../screens/NotFound/NotFound";
import { BusinessUnitList } from "../screens/Settings/BusinessUnit/BusinessUnitList";
import { CreateCatalogue } from "../components/Catalogues/CreateCatalogue";
import { Exportables } from "../components/Exports/Main/Exports";
import { ModalKeepSessionAlive } from "../components/Modal/ModalKeepSessionAlive";
import { Bitacora } from "../components/MainComponents/bitacora/Bitacora";
import { startGettingUserInfo } from "../store/auth/authThunks";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export const UserRoutes = () => {

  const { gTheme } = useSelector(state=>state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startGettingUserInfo());
  }, []);

  return (
    <div className= {`${gTheme!=="light"?"dark-mode":""} d-flex`}>
      <Sidebar />
      <div className="dyBackground contents">
        <Navbar />
        <Routes>
          <Route path="/" element={ <Navigate to="/dashboard" />} />
          <Route path="/login" element={ <Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={ <Dashboard />} />
          <Route path="/evidence/new" element={ <ConfigEvidence />} />
          <Route path="/evidence/details" element={ <ConfigEvidence />} />
          <Route path="/evidence/custody" element={ <CustodyEvidence />} />
          <Route path="/interview/config" element={ <ConfigInterview />} />
          <Route path="/interview/start" element={ <StartInterview />} />
          <Route path="/interview/analysis" element={ <AnalyzeInterview />} />
          <Route path="/profile" element={ <ProfileScreen vTheme={gTheme} />} />
          <Route path="/reports/new" element={ <NewReport />} />
          <Route path="/reports/all" element={ <ReportsList />} />
          <Route path="/reports/all/:status" element={ <ReportsList />} />
          <Route path="/reports/:id" element={ <ReportViewer />} />
          <Route path="/users" element={ <Users />} />
          <Route path="/users/new" element={ <User />} />
          <Route path="/users/edit" element={ <User />} />
          <Route path="/users/permits" element={ <PermitsUser vTheme={gTheme} />} />
          <Route path="/users/roles/new" element={ <NewRoleUser vTheme={gTheme} />} />
          <Route path="/users/menus" element={ <MenusUser vTheme={gTheme} />} />
          <Route path="/users/menus/new" element={ <NewMenuUser vTheme={gTheme} />} />
          <Route path="/segments" element={ <SegmentationList />} />
          <Route path="/segments/new" element={ <Segmentation />} />
          <Route path="/segments/:id" element={ <Segmentation />} />
          <Route path="/users/roles" element={ <RolesUser />} />
          <Route path="/formslist" element={ <FormsList />} />
          <Route path="/createform" element={ <CreateForm />} />
          <Route path="/editform/:id" element={ <CreateForm />} />
          <Route path="/catalogues" element={ <Catalogues/>} />
          <Route path="/catalogues/new" element={ <CreateCatalogue/>} />
          <Route path="/clientsCr" element={ <ClientSignup />} />
          
          <Route path="/settings" element={ <Settings />} />
          <Route path="/settings/business/list" element={ <BusinessUnitList />} />
          <Route path="/settings/business/create" element={ <BusinessUnit />} />
          <Route path="/settings/business/:alias" element={ <BusinessUnit />} />
          <Route path="/settings/department" element={ <DepartmentSettings vTheme={gTheme} />} />
          <Route path="/settings/landing/:businessUnit" element={ <LandingPageSettings vTheme={gTheme} />} />
          <Route path="/settings/people" element={ <PeopleSettings vTheme={gTheme} />} />
          <Route path="/settings/reports" element={ <ReportSettings vTheme={gTheme} />} />
          <Route path="/settings/workplace" element={ <WorkplaceSettings vTheme={gTheme} />} />

          <Route path="/exports/" element= { <Exportables /> } />
          <Route path="/logs/" element={ <Bitacora/>} />
          <Route path="/*" element={ <NotFound to="/notfound" /> } />
        </Routes>
      </div>
    </div>
  )
}
