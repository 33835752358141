import { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Modal, Row } from "react-bootstrap";
import { getUtilitiesEAM } from "../../../apis/apiEAM";
import { catalogByPart } from "../../../apis";
import { CatalogueInput } from "../../JSONForms/PreviewForm/CatalogueInput";
import { MCSelect } from "../../MainComponents/Select/Select";
import { MCButton } from "../../MainComponents/Button/Button";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Icon from "../../Icon/Icon";
import { useTranslation } from "react-i18next";
import styleModal from "./styleModal.scss";
import { useLocation } from 'react-router-dom';
const EveryValue = ({
  value,
  catalogueInit,
  catDataInit,
  onChange = () => {},
}) => {
  const [catalogue, setCatalogue] = useState(catalogueInit);
  const [catData, setCatData] = useState(catDataInit);

  useEffect(() => {
    setCatalogue(catalogueInit);
    setCatData(catDataInit);
  }, [catalogueInit, catDataInit]);

  useEffect(() => {
    if (catalogue && catData) {
      onChange(catData[0][catalogue[0].catalogue], catalogue[0].label);
    }
  }, [catalogue, catData]);

  return (
    <div>
      {catalogue && catData && (
        <Row>
          <CatalogueInput
            schema={catalogue[0]}
            scope={catalogue[0]}
            value={catData[0][catalogue[0].catalogue]}
            catalogue={catalogue[0].catalogue}
            isOwn={catalogue[0].isOwn}
            entireSchema={catalogue}
            entireFormData={catData}
            setSchemaState={setCatalogue}
            setFormData={setCatData}
            pathSchema={"0"}
            pathData={"0"}
            returnValidClass={() => {}}
            handleValidate={() => {}}
          />
        </Row>
      )}
    </div>
  );
};

export const ModalSegmentCatalogue = ({
  show,
  onHide,
  scope,
  path,
  handleAddChild,
  utilitiesCatalogues,
  dynamicStyle
}) => {
  const { gTheme } = useSelector((state) => state.theme);
  const [t] = useTranslation("users");

  const [selectedCatalogue, setSelectedCatalogue] = useState(null);
  const [catalogue, setCatalogue] = useState(null);
  const [catData, setCatData] = useState(null);
  const [rerender, setRerender] = useState(false);
  const [values, setValues] = useState([]);
  const location = useLocation();

  // Verifica si estamos en la ruta /segments/new
  const isSegmentsNewRoute = location.pathname === '/segments/new';
  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  useEffect(() => {
    if (selectedCatalogue) {
      setCatalogue(null);
      setCatData(null);
      setValues([]);
      catalogByPart({
        is_own: selectedCatalogue.isOwn,
        catalogue: selectedCatalogue.catalogue,
        path: "/",
      })
        .then((resp) => {
          let children = [...resp.data];
          let objCatalogue = {
            key: `C::${selectedCatalogue.isOwn ? "OWN" : "EG"}::${
              selectedCatalogue.catalogue
            }`,
            type: "catalog-select",
            label: selectedCatalogue.label,
            placeholder: t("segmentCatalogue.support_text"),
            required: false,
            sensitive: false,
            scope: "/",
            selected: "",
            isOwn: selectedCatalogue.isOwn ? true : false,
            catalogue: selectedCatalogue.catalogue,
            children: children,
          };
          let objCatData = {
            [selectedCatalogue.catalogue]: "",
            catalogue: selectedCatalogue.catalogue,
            isOwn: selectedCatalogue.isOwn ? true : false,
            sensitive: false,
          };
          setCatalogue([objCatalogue]);
          setCatData([objCatData]);
        })
        .catch((error) => console.log(error));
    } else {
      setCatalogue(null);
      setCatData(null);
    }
  }, [selectedCatalogue]);

  const handleValues = (val, label, idx) => {
    if (val) {
      const newValues = _.cloneDeep(values);
      newValues[idx] = val;
      setValues(newValues);
    }
  };

  const handleDeleteValue = (idx) => {
    const newValues = _.cloneDeep(values);
    newValues.splice(idx, 1);
    setValues(newValues);
    setRerender(true);
  };

  const handleAddChildLocal = () => {
    if (values.includes("")) {
      Swal.fire(t("segmentCatalogue.values_havent_selected"));
      return;
    }
    const child = {
      child: null,
      field: catalogue[0].key,
      value: values,
      label: catalogue[0].label,
    };
    handleAddChild(path, child);
    onHide();
  };
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      className={`${gTheme !== "light" ? "dark-mode" : ""} `}
      centered
      scrollable
      contentClassName={isSegmentsNewRoute ? 'modal-content-visible' : 'modal-content-hidden'}

    >
      <Modal.Header
        closeButton
        className={`${gTheme !== "light" ? "btn-close-white" : ""} border-0`}
      >
        <Modal.Title>{t("segmentCatalogue.add_segment")}</Modal.Title>
      </Modal.Header>
      {utilitiesCatalogues && (
        <div className="py-2 pt-4">
          <MCSelect
            options={utilitiesCatalogues}
            value={selectedCatalogue}
            onChange={setSelectedCatalogue}
            
          />
        </div>
      )}

      <Row>
        {Array.isArray(values) &&
          values.map((item, idx) => (
            <Col sm={1} md={4} key={idx} className="pb-2">
              <div className="dyTheme1 dyBorder1 rounded">
                <div className="d-flex justify-content-end pe-1 pt-2">
                  <Icon
                    name="cancel_circle"
                    className="pointer"
                    onClick={() => handleDeleteValue(idx)}
                  />
                </div>
                <hr className=" mt-1 " />
                <EveryValue
                  value={item}
                  catalogueInit={catalogue}
                  catDataInit={catData}
                  onChange={(val, label) => handleValues(val, label, idx)}
                />
              </div>
            </Col>
          ))}
      </Row>

      <div className="d-flex justify-content-end">
        <MCButton
          label={t("segmentCatalogue.cancel")}
          variant="danger"
          outline
          className="me-2"
          onClick={onHide}
        />
        {selectedCatalogue && (
          <MCButton
            label={t("segmentCatalogue.add_filter")}
            variant="primary"
            outline
            className="me-2"
            onClick={() => setValues([...values, ""])}
          />
        )}
        {values.length > 0 && (
          <MCButton
            label={t("segmentCatalogue.save")}
            variant="success"
            outline
            onClick={handleAddChildLocal}
          />
        )}
      </div>
    </Modal>
  );
};
