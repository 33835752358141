import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import _ from "lodash";
import Swal from "sweetalert2";
import Container from "react-bootstrap/Container";
import { Col, Row, Button, Tab, Nav } from "react-bootstrap";
import { CDBStep, CDBStepper } from "cdbreact";
import { createAttachment, createAttachmentForReport, createReport, updateReport } from "../../../apis";
import { MCInput } from "../../MainComponents/Input/Input";
import Icon from "../../Icon/Icon";
import { CatalogueInput } from "./CatalogueInput";
import { ConditionalInputs } from "./ConditionalInputs";
import ReactDOMServer from 'react-dom/server';
import {
  CATALOGUES_PREFIX,
  INPUT_TYPE_CATALOGUE_ERROR,
  INPUT_TYPE_CATALOGUE_RADIO,
  INPUT_TYPE_CATALOGUE_RADIO_CONDITIONAL,
  INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION,
  INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION_CONDITIONAL,
  INPUT_TYPE_CATALOGUE_SELECT,
  INPUT_TYPE_CATALOGUE_SELECT_CONDITIONAL,
  INPUT_TYPE_CHECKBOX,
  INPUT_TYPE_CHECKBOX_CONDITIONAL,
  INPUT_TYPE_DATE,
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_DESCRIPTION,
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_FILE,
  INPUT_TYPE_NUMBER,
  INPUT_TYPE_SUBJECT,
  INPUT_TYPE_TEXT,
  INPUT_TYPE_TEXTAREA,
  INPUT_TYPE_INVOLVED,
  isForLanding,
  isEspecialCatalogue
} from "../consts";

import I18nText from "./i18n";
import "./styles.scss";

import { useTranslation } from "react-i18next";
//import "./PreviewForm.scss";
import { MCButton, MCSelect } from "../../MainComponents";


// Con esta funcion se modifica el schema del formulario
export const hdlSchm = ({
  action,
  path,
  selected,
  children,
  pathSchema,
  inputs,
  entireSchema,
  setSchemaState,
}) => {
  
  const putAllPreviousLastChildOnFalse = (schema) => {
    let nextCount = path.split(".");
    nextCount = nextCount.filter(i=>i==="next").length;
    for (let index = 0; index <= nextCount; index++) {
      _.set(newSchema, `${pathSchema}.${index > 0 ? "next."*index : ""}lastChild`, false);
    }
  }
  
  const newSchema = _.cloneDeep(entireSchema);
  if (action === "selection") {
    _.unset(newSchema, `${pathSchema}.${path}next`);
    putAllPreviousLastChildOnFalse(newSchema);
    _.set(newSchema, `${pathSchema}.${path}lastChild`, false);
    _.set(newSchema, `${pathSchema}.${path}selected`, selected);
    _.set(newSchema, `${pathSchema}.${path}next.children`, children);
    _.set(newSchema, `${pathSchema}.${path}next.selected`, "");
    _.set(newSchema, `${pathSchema}.${path}next.lastChild`, true);
  }
  if (action === "nullSelection") {
    _.set(newSchema, `${pathSchema}.${path}selected`, "");
    _.set(newSchema, `${pathSchema}.${path}lastChild`, false);
    _.unset(newSchema, `${pathSchema}.${path}next`);
  }
  if (action === "noNextPath") {
    _.set(newSchema, `${pathSchema}.${path}selected`, selected);
    _.set(newSchema, `${pathSchema}.${path}lastChild`, true);
    _.unset(newSchema, `${pathSchema}.${path}next`);
  }
  if (action === "resetInputs") {
    _.set(newSchema, `${pathSchema}`, inputs);
  }
  setSchemaState(newSchema);
};
// Con esta funcion se modifica la data del formulario
export const hdlChg = ({
  e,
  entirePathData,
  entirePathDataWithKey,
  params,
  entireFormData,
  setFormData,
}) => {
  const newFromData = _.cloneDeep(entireFormData);
  if (e.target.type === "checkbox") {
    _.set(newFromData, entirePathDataWithKey, e.target.checked);
  } else if (e.target.type === "file") {
    _.set(newFromData, entirePathDataWithKey, e.target.files);
  } else if (typeof e.target?.type === "string" && e.target.type.includes("date-range")) {
    if (e.target.type.includes("start")) {
      let dates = _.get(newFromData, entirePathDataWithKey);
      dates = dates.split("__");
      if (dates.length === 2) {
        dates[0] = e.target.value.toJSON();
        dates = dates.join("__");
        _.set(newFromData, entirePathDataWithKey, dates);
      }
    } else if (e.target.type.includes("end")) {
      let dates = _.get(newFromData, entirePathDataWithKey);
      dates = dates.split("__");
      if (dates.length === 2) {
        dates[1] = e.target.value.toJSON();
        dates = dates.join("__");
        _.set(newFromData, entirePathDataWithKey, dates);
      }
    }
  } else if (e.target?.type === "involved") {
    if (e.target.action === "add") {
      const currentInvolveds = _.get(newFromData, entirePathDataWithKey);
      currentInvolveds.push(e.target.value);
    }
    if (e.target.action === "delete") {
      console.log(e.target.idx);
      const currentInvolveds = _.get(newFromData, entirePathDataWithKey);
      currentInvolveds.splice(e.target.idx, 1);
    }
  } else if (params && "catalogue" in params && "isOwn" in params) {
    if ("conditionals" in params) {
      const temp = _.get(newFromData, entirePathData);
      _.set(newFromData, entirePathData, {
        ...params,
        conditionals: temp?.conditionals || [],
      });
    } else {
      _.set(newFromData, entirePathData, { ...params });
    }
    _.set(newFromData, `${entirePathData}.${params.catalogue}`, e.target.value);
  } else if (params && "conditionals" in params) {
    _.set(newFromData, `${entirePathData}.conditionals`, params.conditionals);
  } else {
    _.set(newFromData, entirePathData, { ...params });
    _.set(newFromData, entirePathDataWithKey, e.target.value);
  }
  setFormData(newFromData);
};
// Con esta funcion se modifica los valores de validacion del formulario
export const hdlIsVal = ({
  errors,
  entirePathDataWithKey,
  entireIsValid,
  setIsValid,
}) => {
  const newIsValid = _.cloneDeep(entireIsValid);
  _.set(newIsValid, `${entirePathDataWithKey}`, errors);
  setIsValid(newIsValid);
};
// Con esta funcion se modifican los valores de restriccion del formulario
export const hdlConstraints = ({
  origin,
  value,
  constraints,
  setConstraints,
}) => {
  const newConstraints = constraints.map((item) => {
    if (item.origin == origin) {
      // if (item.value == value) { // En caso de que se quiera que el valor sea exacatamente el marcado en las constraints
      //   return {...item, selected: true}
      // } else {
      //   return {...item, selected: false}
      // }
      if (value.startsWith(item.value)) {
        return { ...item, selected: true };
      } else {
        return { ...item, selected: false };
      }
    }
    return item;
  });
  setConstraints(newConstraints);
};
// Input de Involucrados
const FormInputInvolved = ({ schema, value, valid, br, origin, tryNext, handleChange }) => {

  const [t] = useTranslation("forms");

  const lang = localStorage.getItem("lang") || "es";

  const [nameInvolved, setNameInvolved] = useState("");
  const [occupationInvolved, setOccupationInvolved] = useState("");
  const [tryToAdd, setTryToAdd] = useState(false);
  const [typeInvolved, setTypeInvolved] = useState(null);

  const [victims, setVictims] = useState([]);
  const [witnesses, setWitnesses] = useState([]);
  const [offenders, setOffenders] = useState([]);
  const [accomplices, setAccomplices] = useState([]);
  const [orderedInvolved, setOrderedInvolved] = useState([]);
  

  const optionsInvolved = [
    { label: t("formPreview.option_victim"), value: "victim" },
    { label: t("formPreview.option_witness"), value: "witness" },
    { label: t("formPreview.option_offender"), value: "offender" },
    { label: t("formPreview.option_accomplice"), value: "accomplice" },
  ]

  useEffect(() => {
    // Verificamos si value es null, undefined, o no es un array
    if (!value || !Array.isArray(value) || value.length === 0) return;
  
    const tempVictims = [];
    const tempWitnesses = [];
    const tempOffenders = [];
    const tempAccomplice = [];
  
    for (const [idx, involved] of value.entries()) {
      if (involved.type === "victim") {
        tempVictims.push({ idx, ...involved });
      }
      if (involved.type === "witness") {
        tempWitnesses.push({ idx, ...involved });
      }
      if (involved.type === "offender") {
        tempOffenders.push({ idx, ...involved });
      }
      if (involved.type === "accomplice") {
        tempAccomplice.push({ idx, ...involved });
      }
    }
    
    if (tempVictims.length > 0) {
      setVictims(tempVictims);
    }
    if (tempWitnesses.length > 0) {
      setWitnesses(tempWitnesses);
    }
    if (tempOffenders.length > 0) {
      setOffenders(tempOffenders);
    }
    if (tempAccomplice.length > 0) {
      setAccomplices(tempAccomplice);
    }
  
    setOrderedInvolved([ ...tempVictims, ...tempWitnesses, ...tempOffenders, ...tempAccomplice ]);
  
  }, [value]);
  
  const hdlAddInvolved = () => {
    setTryToAdd(true);
    if (nameInvolved.trim() === "") return;
    if (occupationInvolved.trim() === "") return;
    if (typeInvolved === null) return;
    handleChange({
      target: {
        name: schema.key,
        type: "involved",
        action: "add",
        value: {
          type: typeInvolved,
          name: nameInvolved,
          occupation: occupationInvolved,
        },
      },
    })
    setNameInvolved("");
    setOccupationInvolved("");
    setTryToAdd(false);
  }

  const hdlDeleteInvolved = (idx) => {
    handleChange({
      target: {
        name: schema.key,
        type: "involved",
        action: "delete",
        idx
      },
    })
    setNameInvolved("");
    setOccupationInvolved("");
  }

  const returnIsValid = (input) => {
    if (!tryToAdd) return true;
    if (input === "name" && nameInvolved.trim() === "") return false;
    if (input === "occupation" && occupationInvolved.trim() === "") return false;
    if (input === "type" && typeInvolved === null) return false;
    return true;
  }


  return (
    <>
      <Col lg={schema.grid || 12} className={`preview-input-container form-input-involved`}>
        <label className={`${schema.required ? "label-required" : ""}`}>
          {schema.locales?.[lang] === undefined ? schema.label : schema.locales[lang]}:{schema.required ? " *" : ""}
        </label>

        {/* Agregar un involucrado */}
        <div className="form-input-involved__add-involved ">

          <div className="flex-grow-1">
            <MCInput
              value={nameInvolved}
              onChange={(e) => setNameInvolved(e.target.value)}
              autoComplete="off"
              type="text"
              isValid={returnIsValid("name")}
              name={`${origin}-involved-name`}
              placeholder={t("formPreview.involved_name_placeholder")}
            />
          </div>

          <div className="flex-grow-1">
            <MCInput
              value={occupationInvolved}
              autoComplete="off"
              type="text"
              isValid={returnIsValid("occupation")}
              name={`${origin}-involved-occupation`}
              onChange={(e) => setOccupationInvolved(e.target.value)}
              placeholder={t("formPreview.involved_occupation_placeholder")}
            />
          </div>

          <div className="flex-grow-1">
            <MCSelect
              type="text"
              name={`${origin}-involved-type`}
              options={optionsInvolved}
              isValid={returnIsValid("type")}
              value={optionsInvolved.find(item => item.value === typeInvolved)}
              onChange={(e) => setTypeInvolved(e.value)}
              placeholder={t("formPreview.involved_type_placeholder")}
            />
          </div>

          <div>
            <MCButton
              type="submit"
              label={t("formPreview.involved_addBtn")}
              variant="primary"
              outline
              onClick={() => hdlAddInvolved(nameInvolved, occupationInvolved, typeInvolved)}
            />
          </div>

        </div>

        {/* <div className="people-involved ">

          {victims.map((item, idx) => (
            <div key={idx} className={`selectable-item ${item.type}`}>
              <strong>{t(`formPreview.option_${item.type}`)}:&nbsp;</strong>{item.name} ({item.occupation}) <button onClick={() => hdlDeleteInvolved(item.idx)}>X</button>
            </div>
          ))}

          {witnesses.map((item, idx) => (
            <div key={idx} className={`selectable-item ${item.type}`}>
              <strong>{t(`formPreview.option_${item.type}`)}:&nbsp;</strong>{item.name} ({item.occupation}) <button onClick={() => hdlDeleteInvolved(item.idx)}>X</button>
            </div>
          ))}

          {offenders.map((item, idx) => (
            <div key={idx} className={`selectable-item ${item.type}`}>
              <strong>{t(`formPreview.option_${item.type}`)}:&nbsp;</strong>{item.name} ({item.occupation}) <button onClick={() => hdlDeleteInvolved(item.idx)}>X</button>
            </div>
          ))}

          {accomplices.map((item, idx) => (
            <div key={idx} className={`selectable-item ${item.type}`}>
              <strong>{t(`formPreview.option_${item.type}`)}:&nbsp;</strong>{item.name} ({item.occupation}) <button onClick={() => hdlDeleteInvolved(item.idx)}>X</button>
            </div>
          ))}

        </div> */}
        
        <div className="people-involved ">

          {(orderedInvolved.length >= 1) ? <>
            {/* <div className="legend">
            
              {victims.length >= 1 &&
                <div className="legend-item">
                    <div className="legend-color legend-victim"></div> {t("formPreview.victim")}
                </div>
              }
              {witnesses.length >= 1 &&
                <div className="legend-item">
                    <div className="legend-color legend-witness"></div> {t("formPreview.witness")}
                </div>
              }
              {offenders.length >= 1 &&
                <div className="legend-item">
                    <div className="legend-color legend-offender"></div> {t("formPreview.offender")}
                </div>
              }
              {accomplices.length >= 1 &&
                <div className="legend-item">
                    <div className="legend-color legend-accomplice"></div>  {t("formPreview.accomplice")}
                </div>
              }

              </div> */}


            <div className="selectable-list">

              {orderedInvolved.map((item, idx) => (
                <div key={idx} className={`selectable-item ${item.type}`}>
                  <strong>{t(`formPreview.option_${item.type}`)}:&nbsp;</strong>{item.name} ({item.occupation}) <button onClick={() => hdlDeleteInvolved(item.idx)}>X</button>
                </div>
              ))}

            </div>
          </> :
            <div className="not-involved">
              {t("formPreview.not_involved_added")}
            </div>
          }

        </div>

        {/* Lista de involucrados */}
        {/* <div className="form-input-involved__columns">

          <div className="flex-grow-1">
            <label className="mb-0 mt-2">
              {t("formPreview.victim")}
            </label>
            <ul>
              {
                victims.map((item, idx) =>
                  <li key={`${origin}-victim-${idx}`} className="d-flex align-items-center">
                    <span>{item.name} ({item.occupation})</span>
                    <div className="delete-involved" onClick={() => hdlDeleteInvolved(item.idx)}>x</div>
                  </li>
                )
              }
            </ul>
          </div>

          <div className="flex-grow-1">
            <label className="mb-0 mt-2">
              {t("formPreview.witness")}
            </label>
            <ul>
              {
                witnesses.map((item, idx) =>
                  <li key={`${origin}-witness-${idx}`} className="d-flex align-items-center">
                    <span>{item.name} ({item.occupation})</span>
                    <div className="delete-involved" onClick={() => hdlDeleteInvolved(item.idx)}>x</div>
                  </li>
                )
              }
            </ul>
          </div>

          <div className="flex-grow-1">
            <label className="mb-0 mt-2">
              {t("formPreview.offender")}
            </label>
            <ul>
              {
                offenders.map((item, idx) =>
                  <li key={`${origin}-offender-${idx}`} className="d-flex align-items-center">
                    <span>{item.name} ({item.occupation})</span>
                    <div className="delete-involved" onClick={() => hdlDeleteInvolved(item.idx)}>x</div>
                  </li>
                )
              }
            </ul>
          </div>

          <div className="flex-grow-1">
            <label className="mb-0 mt-2">
              {t("formPreview.accomplice")}
            </label>
            <ul>
              {
                accomplices.map((item, idx) =>
                  <li key={`${origin}-accomplice-${idx}`} className="d-flex align-items-center">
                    <span>{item.name} ({item.occupation})</span>
                    <div className="delete-involved" onClick={() => hdlDeleteInvolved(item.idx)}>x</div>
                  </li>
                )
              }
            </ul>
          </div>

        </div> */}

        <ReturnErrorMesages tryNext={tryNext} valid={valid} />
      </Col>
      {br && <div></div>}
    </>
  );
}

// Componente que retorna un mensaje de error en caso de que el input sea invalido
export const ReturnErrorMesages = ({ tryNext, valid }) => {
  return (
    <>
      {tryNext &&
        Array.isArray(valid) &&
        valid?.length > 0 &&
        valid.map((err, idx) => (
          <div key={idx} className="invalid-msg">
            {err} <br />
          </div>
        ))}
    </>
  );
};

// Render de cada uno de los inputs en el schema
export const RenderInput = ({
  entireSchema,
  entirePathSchema,
  setSchemaState,
  entireFormData,
  entirePathData,
  setFormData,
  entireIsValid,
  setIsValid,
  constraints,
  setConstraints,
  attachments,
  setAttachments,
  tryToNext,
  activeStep,
  origin,
  answered,
}) => {
  
  const inputAttachment = useRef(null);

  const lang = isForLanding ? localStorage.getItem("selectedLanguage") : localStorage.getItem("lang") ?? "es";
  const i18n = {
    "form_requiered": I18nText({ lang, translation: "form_requiered" }),
    "form_check_required": I18nText({ lang, translation: "form_check_required" }),
    "form_file_required": I18nText({ lang, translation: "form_file_required" }),
    "form_invalid_date": I18nText({ lang, translation: "form_invalid_date" }),
    "form_invalid_initial_date": I18nText({ lang, translation: "form_invalid_initial_date" }),
    "form_invalid_finish_date": I18nText({ lang, translation: "form_invalid_finish_date" }),
    "form_invalid_field": I18nText({ lang, translation: "form_invalid_field" }),
    "form_invalid_email": I18nText({ lang, translation: "form_invalid_email" }),
    "input_file_drag_drop_files": I18nText({ lang, translation: "input_file_drag_drop_files" }),
    "input_file_or_click_here": I18nText({ lang, translation: "input_file_or_click_here" }),
    "input_date_range_start_date": I18nText({ lang, translation: "input_date_range_start_date" }),
    "input_date_range_end_date": I18nText({ lang, translation: "input_date_range_end_date" }),

    "step_no_name": I18nText({ lang, translation: "step_no_name" }),
    "step_without_name": I18nText({ lang, translation: "step_without_name" }),
    "form_next_button": I18nText({ lang, translation: "form_next_button" }),
    "form_prev_button": I18nText({ lang, translation: "form_prev_button" }),
    "form_send_button": I18nText({ lang, translation: "form_send_button" }),
  }


  const [text, setText] = useState('');

  const [t] = useTranslation("forms");
  
  const formatDate = (dateString) => new Date(dateString).toISOString().split('T')[0];

  const schema = _.get(entireSchema, entirePathSchema);
  if (schema === undefined) return;
  const key = schema.type.includes(CATALOGUES_PREFIX)
    ? schema.catalogue
    : schema.key;

  const entirePathDataWithKey = `${entirePathData}.${key}`;
  const value = _.get(entireFormData, entirePathDataWithKey);
  const valid = _.get(entireIsValid, entirePathDataWithKey);
  const tryNext = _.get(tryToNext, activeStep);
  const br = schema.break;

  const utilHdlChg = {
    entireFormData,
    entirePathData,
    entirePathDataWithKey,
    setFormData,
  };

  const doValidate = (test, initType, initRequired) => {
    let errors = [];
    const type = initType ?? schema.type;
    const required = initRequired ?? schema.required;
    if (type === INPUT_TYPE_TEXT) {
      if (required === true) {
        if (test.trim() === "") {
          errors.push(i18n["form_requiered"]);
        }
      }
    }
    if (type === INPUT_TYPE_NUMBER) {
      const tempNum = Number(test);
      if (required === true) {
        if (test.trim() === "") {
          // errors.push(
          errors.push(i18n["form_requiered"]);
        } else if (isNaN(tempNum)) {
          errors.push(i18n["form_invalid_field"]);
        } else if (tempNum < 0) {
          errors.push(i18n["form_invalid_field"]);
        }
      } else {
        if (isNaN(tempNum)) {
          errors.push(i18n["form_invalid_field"]);
        } else if (tempNum < 0) {
          errors.push(i18n["form_invalid_field"]);
        }
      }
    }
    if (type === INPUT_TYPE_TEXTAREA) {
      if (required === true) {
        if (test.trim() === "") {
          errors.push(i18n["form_check_required"]);
        }
      }
    }
    if (type.includes(INPUT_TYPE_CHECKBOX)) {
      if (required === true) {
        if (test === false) {
          errors.push(i18n["form_check_required"]);
        }
      }
    }
    if (type === INPUT_TYPE_FILE) {
      if (required === true) {
        const formDataEntries = test.entries();
        const length = Array.from(formDataEntries).length;
        // console.log("Cantidad de elementos en FormData:", length);
        if (test === null) {
          errors.push(i18n["form_file_required"]);
        }
      }
    }
    if (type === INPUT_TYPE_DATE) {
      // if (!isValidDate()) {
      //   errors.push("Fecha no válida (reportar)")
      // }
    }
    if (type === INPUT_TYPE_DATE_RANGE) {
      // let dates = test;
      // dates = dates.split("__");
      // if (dates.length === 2) {
      //   if (!isValidDate("start")) {
      //     errors.push("Fecha inicial no válida (reportar)")
      //   }
      //   if (!isValidDate("end")) {
      //     errors.push("Fecha final no válida (reportar)")
      //   }
      // } else {
      //   errors.push("Campo no válido (reportar)")
      // }
    }
    if (type === INPUT_TYPE_SUBJECT) {
      if (required === true) {
        if (test.trim() === "") {
          errors.push(i18n["form_requiered"]);
        }
      }
    }
    if (type === INPUT_TYPE_DESCRIPTION) {
      if (required === true) {
        if (test.trim() === "") {
          errors.push(i18n["form_requiered"]);
        }
      }
    }
    if (type === INPUT_TYPE_EMAIL) {
      const regex = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (required === true) {
        if (!regex.test(test)) {
          errors.push(i18n["form_invalid_email"]);
        }
      } else {
        if (test.trim() !== "" && !regex.test(test)) {
          errors.push(i18n["form_invalid_email"]);
        }
      }
    }
    if (type.includes(CATALOGUES_PREFIX)) {
      if (required) {
        if (!test) {
          errors.push(i18n["form_requiered"]);
        }
      }
    }
    return errors;
  };

  
  const handleKeyDown = (e) => {
    const { key } = e;
        console.log(e)
        // Verificar si la tecla presionada es una letra
        if (/^[a-zA-Z]$/.test(key)) {
          setText("Presiona solo numeros");
          e.preventDefault();
        } else if (/^\d$/.test(key) || key === 'Backspace' || key === 'Delete') {
          setText('');
        } else {
          e.preventDefault();
        }
  }
  const handleChange = (e) => {
    if(schema.type === INPUT_TYPE_EMAIL){
      const regex = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (!regex.test(e.target.value)) {
          setText(i18n["form_invalid_email"]);
        }
        else {
          setText("");
        }
      }
    hdlChg({
      e,
      params: { sensitive: schema.sensitive, origin },
      ...utilHdlChg,
    });
    const value = schema.type.includes(INPUT_TYPE_CHECKBOX)
      ? e.target.checked
      : e.target.value;
    const errors = doValidate(value);
    if (
      typeof schema.type.includes(INPUT_TYPE_CHECKBOX) &&
      !schema.type.includes(CATALOGUES_PREFIX)
    ) {
      hdlIsVal({ errors, entirePathDataWithKey, entireIsValid, setIsValid });
    }
  };

  const handleValidate = (validCat) => {
    const errors = doValidate(validCat);
    hdlIsVal({ errors, entirePathDataWithKey, entireIsValid, setIsValid });
  };

  const returnValidClass = (type) => {
    if (
      Array.isArray(valid) &&
      valid.length > 0 &&
      tryNext &&
      type === "container"
    ) {
      return "is-invalid-container";
    }
    if (Array.isArray(valid) && valid.length > 0 && tryNext) {
      return "is-invalid";
    }
    return "";
  };

  // FOR ATTACHMENTS
  const hdlAttch = (e) => {
    let formData;
    if (!attachments) {
      formData = new FormData();
    } else {
      formData = attachments;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      if (!answered) {
        formData.append("attachments", e.target.files[i]);
      } else {
        formData.append("file", e.target.files[i]);
      }
    }
    const errors = doValidate(formData);
    hdlIsVal({ errors, entirePathDataWithKey, entireIsValid, setIsValid });
    setAttachments(formData);
  };

  const hdlDragOvAttch = (e) => {
    e.preventDefault();
  };

  const hdlDropAttch = (e) => {
    e.preventDefault();
    let formData;
    if (!attachments) {
      formData = new FormData();
    } else {
      formData = attachments;
    }
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      if (!answered) {
        formData.append("attachments", e.dataTransfer.files[i]);
      } else {
        formData.append("file", e.dataTransfer.files[i]);
      }
    }
    const errors = doValidate(formData);
    hdlIsVal({ errors, entirePathDataWithKey, entireIsValid, setIsValid });
    setAttachments(formData);
  };

  const hdlDeleteAttch = (position) => {

    let newFormData = new FormData();
    let index = 0;
    attachments.forEach((value, currentKey) => {
      if (index !== position) {
        newFormData.append(currentKey, value);
      }
        index++;
    });

    const errors = doValidate(newFormData);
    hdlIsVal({ errors, entirePathDataWithKey, entireIsValid, setIsValid });
    setAttachments(newFormData);
  };

  // FOR DATES
  const isValidDate = (startend) => {
    if (startend) {
      let dates = value;
      dates = dates.split("__");
      if (startend === "start" && dates.length === 2) {
        return !isNaN(new Date(dates[0]).getTime());
      } else if (startend === "end" && dates.length === 2) {
        return !isNaN(new Date(dates[1]).getTime());
      }
    }
    return !isNaN(new Date(value).getTime());
  };
  // const isValidDate = (value, startend) => {
  //   const formatDate = (dateString) => {
  //     const date = new Date(dateString);
  //     if (isNaN(date.getTime())) {
  //       return null;
  //     }
  //     const day = (`0${date.getDate()}`).slice(-2);
  //     const month = (`0${date.getMonth() + 1}`).slice(-2);
  //     const year = date.getFullYear().toString().slice(-2);
  //     return `${day}/${month}/${year}`;
  //   };
  
  //   if (value) {
  //     let dates = value.split("__");
  //     if (startend === "start" && dates.length === 2) {
  //       return formatDate(dates[0]);
  //     } else if (startend === "end" && dates.length === 2) {
  //       return formatDate(dates[1]);
  //     } else {
  //       return formatDate(value);
  //     }
  //   }
  //   return null;
  // };
  const returnDateRange = (startend) => {
    let dates = value;
    dates = dates.split("__");
    if (startend === "start" && dates.length === 2) {
      let newDate = new Date(dates[0]);
      return newDate;
    } else if (startend === "end" && dates.length === 2) {
      let newDate = new Date(dates[1]);
      return newDate;
    }
    return false;
  };


  if (schema.hidden && !isEspecialCatalogue(schema.catalogue) && isForLanding) return <></>;

  if (schema.type === INPUT_TYPE_TEXT) {
  
    return (
      <>
        <Col lg={schema.grid || 12} className={`preview-input-container`}>
          <div className="d-flex">
            
            <label className={`${schema.required ? "label-required" : ""}`}>
              {schema.locales?.[lang] === undefined ? schema.label : schema.locales[lang]}:{schema.required ? " *" : ""}
            </label>
            {
              schema.hidden && !isForLanding &&
              <Icon className="ms-2 fill-yellow" name="outline_eye_slash" />
            }
          </div>
          <div
            className={`preview-input-container-inp ${returnValidClass(
              "container"
            )}`}
          >
            <MCInput
              autoComplete="off"
              type="text"
              className={`${returnValidClass()}`}
              name={schema.key}
              defaultValue={value}
              onChange={handleChange}
              placeholder={schema.placeholder}
            />
          </div>
          <ReturnErrorMesages tryNext={tryNext} valid={valid} />
        </Col>
        {br && <div></div>}
      </>
    );
  }
  if (schema.type === INPUT_TYPE_NUMBER) {
    return (
      <>
        <Col lg={schema.grid || 12} className={`preview-input-container`}>
          <div className="d-flex">
            <label className={`${schema.required ? "label-required" : ""}`}>
              {schema.locales?.[lang] === undefined ? schema.label : schema.locales[lang]}:{schema.required ? " *" : ""}
            </label>
            {
              schema.hidden && !isForLanding &&
              <Icon className="ms-2 fill-yellow" name="outline_eye_slash" />
            }
          </div>
          <div
            className={`preview-input-container-inp ${returnValidClass(
              "container"
            )}`}
          >
            <MCInput
              autoComplete="off"
              type="text"
              className={`${returnValidClass()}`}
              name={schema.key}
              defaultValue={value}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              placeholder={schema.placeholder}
            />
          </div>
           {
              <div className="invalid-msg">
                {text} <br />
              </div>
            }
          <ReturnErrorMesages tryNext={tryNext} valid={valid} />
        </Col>
        {br && <div></div>}
      </>
    );
  }
  if (schema.type === INPUT_TYPE_TEXTAREA) {
    return (
      <>
        <Col lg={schema.grid || 12} className={`preview-input-container`}>
          <div className="d-flex">
            <label className={`${schema.required ? "label-required" : ""}`}>
              {schema.locales?.[lang] === undefined ? schema.label : schema.locales[lang]}:{schema.required ? " *" : ""}
            </label>
            {
              schema.hidden && !isForLanding &&
              <Icon className="ms-2 fill-yellow" name="outline_eye_slash" />
            }
          </div>
          <div
            className={`preview-input-container-inp ${returnValidClass(
              "container"
            )}`}
          >
            <MCInput
              type="textarea"
              rows={3}
              autoComplete="off"
              className={`${returnValidClass()}`}
              name={schema.key}
              defaultValue={value}
              onChange={handleChange}
              placeholder={schema.placeholder}
            />
          </div>
          <ReturnErrorMesages tryNext={tryNext} valid={valid} />
        </Col>
        {br && <div></div>}
      </>
    );
  }
  if (schema.type === INPUT_TYPE_CHECKBOX) {
    return (
      <>
        <Col lg={schema.grid || 12} className={`preview-input-container`}>
          <div className={`form-check ${returnValidClass("container")} ${schema.defaultAnswer===true?"ps-0":""}`}>
            
            {
              !schema.defaultAnswer===true &&
              <input className="form-check-input" type="checkbox" id={origin} name={schema.key} defaultChecked={value} value={value} onChange={handleChange} />
            }
            <div className="d-flex">
              <label className={`${schema.required ? "label-required" : ""} form-check-label`} htmlFor={origin}>
                {schema.locales?.[lang] === undefined ? schema.label : schema.locales[lang]}:{schema.required ? " *" : ""}
              </label>
              {
                schema.hidden && !isForLanding &&
                <Icon className="ms-2 fill-yellow" name="outline_eye_slash" />
              }
            </div>
          </div>
          <ReturnErrorMesages tryNext={tryNext} valid={valid} />
        </Col>
        {br && <div></div>}
      </>
    );
  }
  if (schema.type === INPUT_TYPE_CHECKBOX_CONDITIONAL) {
    return (
      <>
        <>
          <Col lg={schema.grid || 12} className={`preview-input-container`}>
            <div className={`form-check ${returnValidClass("container")}`}>
              <input
                className="form-check-input"
                type="checkbox"
                name={schema.key}
                id={schema.key}
                defaultChecked={value}
                value={value}
                onChange={handleChange}
              />
              <div className="d-flex">
                <label className={`${schema.required ? "label-required" : ""} form-check-label`} htmlFor={schema.key}>
                  {schema.locales?.[lang] === undefined ? schema.label : schema.locales[lang]}:{schema.required ? " *" : ""}
                </label>
                {
                  schema.hidden && !isForLanding &&
                  <Icon className="ms-2 fill-yellow" name="outline_eye_slash" />
                }
              </div>
            </div>
            <ReturnErrorMesages tryNext={tryNext} valid={valid} />
          </Col>
          {br && <div></div>}
        </>
        <ConditionalInputs
          entireSchema={entireSchema}
          entirePathSchema={entirePathSchema}
          setSchemaState={setSchemaState}
          entireFormData={entireFormData}
          entirePathData={entirePathData}
          setFormData={setFormData}
          entireIsValid={entireIsValid}
          setIsValid={setIsValid}
          doValidate={doValidate}
          conditionals={schema.conditionals}
          parentValue={value}
          parentOrigin={origin}
          attachments={attachments}
          setAttachments={setAttachments}
          tryToNext={tryToNext}
          activeStep={activeStep}
          answered={answered}
        />
      </>
    );
  }
  if (schema.type === INPUT_TYPE_FILE) {
    return (
      <>
        <Col
          lg={schema.grid || 12}
          className={`preview-input-container`}
          onDrop={hdlDropAttch}
          onDragOver={hdlDragOvAttch}
        >
          <div className="d-flex">
            <label className={`${schema.required ? "label-required" : ""}`}>
              {( schema.locales?.[lang] == undefined )? schema.label : schema.locales[lang]}:
            </label>
            {
              schema.hidden && !isForLanding &&
              <Icon className="ms-2 fill-yellow" name="outline_eye_slash" />
            }
          </div>
          <div
            className="attachments pointer"
            onClick={() => inputAttachment.current.click()}
          >
            <div className="attachments-icon-container">
              <Icon name="gallery" />
              <Icon name="outline_clipboard_text" />
              <Icon name="volume_high" />
              <Icon name="outline_video" />
              <Icon name="outline_document" />
            </div>

            <div className="attachments-instructions">
              <p>{i18n["input_file_drag_drop_files"]}</p>
              <button className="btn attachments-btn">
                {i18n["input_file_or_click_here"]}
              </button>
            </div>
          </div>
          <input
            className={`form-control ${returnValidClass()} w-100`}
            name={schema.key}
            type="file"
            onChange={hdlAttch}
            multiple
            hidden
            ref={inputAttachment}
          />
          <ul className="list-none">
            {attachments !== null &&
            [...attachments.entries()].map((item, idx) => {
              return <li key={idx} className="d-flex align-items-center">
                <span
                  className="pointer"
                  style={{ height: 16, width: 16, fontWeight: "600", border: 0, color: "red", display: "inline-block", marginTop: -8 }} 
                  onClick={()=>hdlDeleteAttch(idx)}>
                  x
                </span>
                <span>
                  {item[1].name}
                </span>
                {/* <button 
                  style={{ height: 24, width: 24, border: 0, backgroundColor: "red", color: "white" }} 
                  onClick={()=>hdlDeleteAttch(idx)}>
                </button> */}
              </li>;
            })}
          </ul>
          {/* <div className={`preview-input-err-msg`}> */}
          <ReturnErrorMesages tryNext={tryNext} valid={valid} />
        </Col>
        {br && <div></div>}
      </>
    );
  }
  if (schema.type === INPUT_TYPE_DATE) {
    const initialDate = new Date();
    // console.log("DATE PARA EDIT: ",value);
    return (
      <>
        <Col lg={schema.grid || 12} className={`preview-input-container`}>
          <div className={`${returnValidClass("container")}`}>
            <div className="d-flex">
              <label className={`${schema.required ? "label-required" : ""}`}>
                {schema.locales?.[lang] === undefined ? schema.label : schema.locales[lang]}:{schema.required ? " *" : ""}
              </label>
              {
                schema.hidden && !isForLanding &&
                <Icon className="ms-2 fill-yellow" name="outline_eye_slash" />
              }
            </div>
            <div className="preview-input-container-inp">
              <MCInput
                type="date"
                className={`${returnValidClass()}`}
                selected={value && isValidDate() ? value : initialDate}
                dateFormat="dd/MM/yy"
                onChange={(value) =>
                  handleChange({
                    target: { name: schema.key, value, type: "date" },
                  })
                }
              // onChange={(value) => hdlChg({ e: { target: { name: schema.key, value, type: "date" } }, ...utilHdlChg })}
              />
            </div>
          </div>
          <ReturnErrorMesages tryNext={tryNext} valid={valid} />
        </Col>
        {br && <div></div>}
      </>
    );
  }
  if (schema.type === INPUT_TYPE_DATE_RANGE) {
    const initialDate = new Date();
    return (
      <>
        {schema.grid !== 12 ? (
          <>
            <Col lg={schema.grid || 12} className={`preview-input-container`}>
              <div className="d-flex">
                <label className={`${schema.required ? "label-required" : ""}`}>
                  {( schema.locales?.[lang] == undefined )? schema.label : schema.locales[lang]}:
                  {schema.required ? " *" : ""} <br />
                  {i18n["input_date_range_start_date"]}:
                </label>
                {
                  schema.hidden && !isForLanding &&
                  <Icon className="ms-2 fill-yellow" name="outline_eye_slash" />
                }
              </div>
              <div className="preview-input-container-inp">
                <MCInput
                  type="date"
                  className={`${returnValidClass()}`}
                  selected={
                    isValidDate("start")
                      ? returnDateRange("start")
                      : initialDate
                  }
                  dateFormat="dd/MM/yy"
                  onChange={(value) =>
                    handleChange({
                      target: {
                        name: schema.key,
                        value,
                        type: "date-range-start",
                      },
                    })
                  }
                // onChange={(value) => hdlChg({ e: { target: { name: schema.key, value, type: "date-range-start" } }, ...utilHdlChg })}
                />
              </div>
              <ReturnErrorMesages />
            </Col>
            <Col lg={schema.grid || 12} className={`preview-input-container`}>
              <br />
              <label>{i18n["input_date_range_end_date"]}:</label>
              <div className="preview-input-container-inp">
                <MCInput
                  type="date"
                  className={`${returnValidClass()}`}
                  selected={
                    isValidDate("end") ? returnDateRange("end") : initialDate
                  }
                  dateFormat="dd/MM/yy"
                  onChange={(value) =>
                    handleChange({
                      target: {
                        name: schema.key,
                        value,
                        type: "date-range-end",
                      },
                    })
                  }
                // onChange={(value) => hdlChg({ e: { target: { name: schema.key, value, type: "date-range-end" } }, ...utilHdlChg })}
                />
              </div>
            </Col>
            {br && <div></div>}
          </>
        ) : (
          <>
            <Col lg={schema.grid || 12} className="preview-input-container">
              <div className={`${returnValidClass("container")}`}>
                <label className={`${schema.required ? "label-required" : ""}`}>
                  {schema.locales?.[lang] === undefined ? schema.label : schema.locales[lang]}:{schema.required ? " *" : ""}
                </label>
                <Row className="p-0">
                  <Col md="6" className="ps-lg-0">
                    <label>{i18n["input_date_range_start_date"]}:</label>
                    <div className="preview-input-container-inp">
                      <MCInput
                        type="date"
                        className={`${returnValidClass()}`}
                        selected={
                          isValidDate("start")
                            ? returnDateRange("start")
                            : initialDate
                        }
                        dateFormat="dd/MM/yy"
                        onChange={(value) =>
                          hdlChg({
                            e: {
                              target: {
                                name: schema.key,
                                value,
                                type: "date-range-start",
                              },
                            },
                            ...utilHdlChg,
                          })
                        }
                      />
                    </div>
                  </Col>
                  <Col md="6" className="pe-lg-0">
                    <label>{i18n["input_date_range_end_date"]}:</label>
                    <div className="preview-input-container-inp">
                      <MCInput
                        type="date"
                        className={`${returnValidClass()}`}
                        selected={
                          isValidDate("end")
                            ? returnDateRange("end")
                            : initialDate
                        }
                        dateFormat="dd/MM/yy"
                        onChange={(value) =>
                          hdlChg({
                            e: {
                              target: {
                                name: schema.key,
                                value,
                                type: "date-range-end",
                              },
                            },
                            ...utilHdlChg,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            {br && <div></div>}
          </>
        )}
      </>
    );
  }

  
  if (schema.type === INPUT_TYPE_SUBJECT) {
    return (
      <>
        <Col lg={schema.grid || 12} className={`preview-input-container`}>
        <div className="d-flex">
          <label className={`${schema.required ? "label-required" : ""}`}>
            {schema.locales?.[lang] === undefined ? schema.label : schema.locales[lang]}:{schema.required ? " *" : ""}
          </label>
          {
            schema.hidden && !isForLanding &&
            <Icon className="ms-2 fill-yellow" name="outline_eye_slash" />
          }
        </div>
          <div
            className={`preview-input-container-inp ${returnValidClass(
              "container"
            )}`}
          >
            <MCInput
              autoComplete="off"
              type="text"
              className={`${returnValidClass()}`}
              name={schema.key}
              defaultValue={value}
              onChange={handleChange}
              placeholder={schema.placeholder}
            />
          </div>
          <ReturnErrorMesages tryNext={tryNext} valid={valid} />
        </Col>
        {br && <div></div>}
      </>
    );
  }
  if (schema.type === INPUT_TYPE_DESCRIPTION) {
    return (
      <>
        <Col lg={schema.grid || 12} className={`preview-input-container`}>
        <div className="d-flex">
          <label className={`${schema.required ? "label-required" : ""}`}>
            {schema.locales?.[lang] === undefined ? schema.label : schema.locales[lang]}:{schema.required ? " *" : ""}
          </label>
          {
            schema.hidden && !isForLanding &&
            <Icon className="ms-2 fill-yellow" name="outline_eye_slash" />
          }
        </div>
          <div
            className={`preview-input-container-inp ${returnValidClass(
              "container"
            )}`}
          >
            <MCInput
              type="textarea"
              autoComplete="off"
              className={`${returnValidClass()}`}
              name={schema.key}
              defaultValue={value}
              onChange={handleChange}
              placeholder={schema.placeholder}
              rows="10"
            />
          </div>
          <ReturnErrorMesages tryNext={tryNext} valid={valid} />
        </Col>
        {br && <div></div>}
      </>
    );
  }
  if (schema.type === INPUT_TYPE_EMAIL) {
    return (
      <>
        <Col lg={schema.grid || 12} className={`preview-input-container`}>
          <div className="d-flex">
            <label className={`${schema.required ? "label-required" : ""}`}>
              {schema.locales?.[lang] === undefined ? schema.label : schema.locales[lang]}:{schema.required ? " *" : ""}
            </label>
            {
              schema.hidden && !isForLanding &&
              <Icon className="ms-2 fill-yellow" name="outline_eye_slash" />
            }
          </div>
          <div
            className={`preview-input-container-inp ${returnValidClass(
              "container"
            )}`}
          >
            <MCInput
              type="text"
              autoComplete="off"
              className={`${returnValidClass()}`}
              name={schema.key}
              defaultValue={value}
              onChange={handleChange}
              placeholder={schema.placeholder}
            />
          </div>
          {
              <div className="invalid-msg">
                {text} <br />
              </div>
            }
          <ReturnErrorMesages tryNext={tryNext} valid={valid} />
          
        </Col>
        {br && <div></div>}
      </>
    );
  }
  if (schema.type === INPUT_TYPE_INVOLVED) {
    return (
      <FormInputInvolved
        schema={schema}
        origin={origin}
        tryNext={tryNext}
        valid={valid}
        value={value}
        br={br}
        handleChange={handleChange}
      />
    )
  }
  if (
    schema.type === INPUT_TYPE_CATALOGUE_SELECT ||
    schema.type === INPUT_TYPE_CATALOGUE_RADIO ||
    schema.type === INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION
  ) {
    return (
      <>
        <CatalogueInput
          schema={schema}
          scope={schema}
          value={value}
          valid={valid}
          origin={origin}
          pathSchema={entirePathSchema}
          pathData={entirePathData}
          entireSchema={entireSchema}
          entireFormData={entireFormData}
          setSchemaState={setSchemaState}
          setFormData={setFormData}
          constraints={constraints}
          setConstraints={setConstraints}
          tryToNext={tryNext}
          handleValidate={handleValidate}
          returnValidClass={returnValidClass}
        />
        {br && <div></div>}
      </>
    );
  }
  if (
    schema.type === INPUT_TYPE_CATALOGUE_SELECT_CONDITIONAL ||
    schema.type === INPUT_TYPE_CATALOGUE_RADIO_CONDITIONAL ||
    schema.type === INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION_CONDITIONAL
  ) {
    return (
      <>
        <CatalogueInput
          schema={schema}
          scope={schema}
          value={value}
          valid={valid}
          origin={origin}
          pathSchema={entirePathSchema}
          pathData={entirePathData}
          entireSchema={entireSchema}
          entireFormData={entireFormData}
          setSchemaState={setSchemaState}
          setFormData={setFormData}
          constraints={constraints}
          setConstraints={setConstraints}
          tryToNext={tryNext}
          handleValidate={handleValidate}
          returnValidClass={returnValidClass}
        />
        {br && <div></div>}
        <ConditionalInputs
          entireSchema={entireSchema}
          entirePathSchema={entirePathSchema}
          setSchemaState={setSchemaState}
          entireFormData={entireFormData}
          entirePathData={entirePathData}
          setFormData={setFormData}
          entireIsValid={entireIsValid}
          setIsValid={setIsValid}
          doValidate={doValidate}
          conditionals={schema.conditionals}
          constraints={constraints}
          setConstraints={setConstraints}
          parentValue={value}
          parentOrigin={origin}
          attachments={attachments}
          setAttachments={setAttachments}
          tryToNext={tryToNext}
          activeStep={activeStep}
          answered={answered}
        />
      </>
    );
  }
  if (schema.type === INPUT_TYPE_CATALOGUE_ERROR) {
    return <></>;
  }
};

// Formulario
export const PreviewForm = ({
  steps,
  schemaState,
  formData,
  isValid,
  setSchemaState,
  setFormData,
  setIsValid,
  constraints,
  setConstraints,
  showButtons = true,
  stepClick = false,
  // lang,
  content,
  fullscreen = false,
  // Answered form
  answered = false,
  id = null,
}) => {

  /**
   * @description - En esta funcion es donde el formulario empieza a contruirse mediante la estructura que le sea proporcionada (Este solo se llama una vez).
   * @see {formStarter.js} - En ese archivo es donde se inicializan los formularios dependiendo de si estos ya estan creados o no. Es el cual retornara los
   * parametros necesarios para mandarle a esta funcion. Por lo que los estados que se controlaran desde el formulario deben de crearse un nivel arriba, esto
   * con el fin de que tambien puedan ser manipulados facilmente desde donde se este utilizando el formulario,
   * @param {Array} steps - Donde se almacenan los steps que contiene el formulario.
   * @param {Array} schemaState - El schema con el que se construira el formulario. (Inputs, catalogos, anidados, etc).
   * @param {Array} formData - Es donde que guardaran las respuestas que de el usuario en el fomrulario.
   * @param {Array} isValid - Para validar que el campo este correcto, esto depende del schema, si es requerido, si el catalogo es obligatorio, etc.
   * @param {Array} setSchemaState, - Es necesario editar el schema ya que dependiendo de las respuestas que de el usario, puede tener hijos un catalogo,
   * que halla condicionales, etc.
   * @param {Array} setFormData, - Para editar la data que vaya respondiendo el usuario.
   * @param {Array} setIsValid, - Dependiendo de la data y el schema, se validara el campo.
   * @param {Boolean} showButtons - Variable que controla si los botones de siguiente, anterior y enviar aparecen. (Puede haber casos donde no se necesite, como
   * en la creacion de un formulario)
   * @param {Boolean} stepClick - Esto en caso de que se este creando un formulario, sea para vista previa y casos donde no sea necesario pasar por todos los steps
   * @returns {Element} - Formulario
   */

  // Language
  const lang = isForLanding ? localStorage.getItem("selectedLanguage") : localStorage.getItem("lang") ?? "es";
  const i18n = {
    "step_no_name": I18nText({ lang, translation: "step_no_name" }),
    "step_without_name": I18nText({ lang, translation: "step_without_name" }),
    "form_next_button": I18nText({ lang, translation: "form_next_button" }),
    "form_prev_button": I18nText({ lang, translation: "form_prev_button" }),
    "form_send_button": I18nText({ lang, translation: "form_send_button" }),
    "form_create_new_report": I18nText({ lang, translation: "form_create_new_report" }),
    "form_go_reports": I18nText({ lang, translation: "form_go_reports" }),
    "form_success": content?.form_success ? content.form_success : I18nText({ lang, translation: "form_success" }),
    "form_tracking": content?.form_tracking ? content.form_tracking : I18nText({ lang, translation: "form_tracking" })
  }
  // Custom hooks
  const navigate = useNavigate();

  const [t] = useTranslation("forms");
  // States
  const [activeStep, setActiveStep] = useState(0); // Para constrolar que step es el que esta activo
  const [tryToNext, setTryToNext] = useState(null); // Es un array de booleans para controlar por que steps ya han pasado o cuales se intento pasar sin que fuera valido.
  const [attachments, setAttachments] = useState(null); // Donde se guardan los archivos externos que se suban.
  const formRef = useRef(null); // Referencia al formulario en el dom para que se pueda hacer scroll.

  // En este efecto solo se actualiza el estado tryToNext cada que cambien los steps.
  useEffect(() => {
    const newTryToNext = steps.map((item) => false);
    setTryToNext(newTryToNext);
  }, [steps]);

  // Genera un folio aleatorio
  const genFolio = () => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const segmentLength = 4;
    const totalLength = 24;
    let folio = "";

    for (let i = 0; i < totalLength; i++) {
      // Add a separator '-' every 4 characters, except at the beginning
      if (i > 0 && i % segmentLength === 0) {
        folio += "-";
      }

      // Generate a random character from the 'characters' string
      const randomIndex = Math.floor(Math.random() * characters.length);
      folio += characters.charAt(randomIndex);
    }

    return folio;
  };

  // Genera un trackin-code aleatorio
  const genNumberFolio = (length) => {
    const characters = "0123456789";
    let folio = "";

    for (let i = 0; i < length; i++) {
      // Generate a random character from the 'characters' string
      const randomIndex = Math.floor(Math.random() * characters.length);
      folio += characters.charAt(randomIndex);
    }

    return folio;
  };

  // Al editar el formulario en el formulario
  const handleUpdate = async () => {
    // attachments.forEach(element => {
    //   console.log(element)
    // });
    // llama a la funcion para validar antes de enviar
    let isValidForm = handleValidStep({ lastStep: true });

    // si todos los steps son validos
    if (!(isValidForm === false)) {
      try {
        let formAnswers = {};
        // si hay adjuntos se guardan los ids de los attachments despues de subirlos con la api
        if (attachments) {
          await createAttachmentForReport(id, attachments);
        }
        // para darle el formato requerido para mandarlos al backend, se tiene que crear un objeto con los nombres de los steps por keys y
        // como valor seria la data correspondiente en formData
        for (const [idx, step] of steps.entries()) {
          formAnswers[step.name] = formData[idx];
        }
        // el objeto final que se mandara al back
        const objToSend = {
          metadata: formAnswers,
        };
        // crea el reporte mandando el objeto
        //console.log(objToSend);
        const resp = await updateReport(id, objToSend);

        //console.log("ID", id);


        //si se envio correctamente dispara una alerta de que ha sido correctamente creado
        Swal.fire({
          title: t("formPreview.report_sent_successfully"),
          text: `${t("formPreview.your_tracking_code")}: ${resp.tracking_code}`,
          icon: "success",
          allowOutsideClick: false, // Disable clicking outside the modal
          allowEscapeKey: false, // Disable pressing the escape key
          showCloseButton: false, // Show a close button in the modal

          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonText: t("formPreview.view_changes"),
          confirmButtonText: t("formPreview.go_reports"),
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/reports/all");
          } else if (result.isDismissed) {
            window.location.reload();
          }
        });


      } catch (error) {
        // si se falla al enviar el formulario
        Swal.fire(
          "Error",
          `${t("formPreview.error_ocurred_while_sending_report")}`,
          "error"
        );
      }
    }
  };

  // Al hacer submit en el formulario
  const handleSubmit = async () => {
    // llama a la funcion para validar antes de enviar
    let isValidForm = handleValidStep({ lastStep: true });

    // si todos los steps son validos
    if (!(isValidForm === false)) {
      try {
        let formAnswers = {};
        var attachments_id = [];
        // si hay adjuntos se guardan los ids de los attachments despues de subirlos con la api
        if (attachments) {
          const attachmentsRes = await createAttachment(attachments);
          attachments_id = attachmentsRes.attachments_id;
        }
        // para darle el formato requerido para mandarlos al backend, se tiene que crear un objeto con los nombres de los steps por keys y
        // como valor seria la data correspondiente en formData
        for (const [idx, step] of steps.entries()) {
          formAnswers[step.name] = formData[idx];
        }
        const randomFolio = genFolio();
        const randomNumericFolio = genNumberFolio(12);
        // el objeto final que se mandara al back
        const objToSend = {
          folio: "FOL-"+randomFolio,
          tracking_code: randomNumericFolio,
          metadata: formAnswers,
          attachments_id
        };
        // crea el reporte mandando el objeto
        const resp = await createReport(objToSend);
        const title = i18n["form_success"];
        const tracking_text = i18n["form_tracking"];
        // si se envio correctamente dispara una alerta de que ha sido correctamente creado
        if (isForLanding) { // Cuando es la landing
          Swal.fire({
            title: ReactDOMServer.renderToString(title),
            text: `${ReactDOMServer.renderToString(tracking_text)}: ${resp.tracking_code}`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonText: 'Ok',
            allowOutsideClick: false, // Disable clicking outside the modal
            allowEscapeKey: false,    // Disable pressing the escape key
            showCloseButton: false,    // Show a close button in the modal
            // confirmButtonText: ReactDOMServer.renderToString(confirmButtonContent)
          }).then((result) => {
            if (result.isConfirmed) {
              //navigate("/formonly");
              window.location.reload();
            } 
          })
        } else { // Cuando es el case
          Swal.fire({
            title: ReactDOMServer.renderToString(title),
            text: `${ReactDOMServer.renderToString(tracking_text)}: ${resp.tracking_code}`,
            icon: "success",
            allowOutsideClick: false, // Disable clicking outside the modal
            allowEscapeKey: false, // Disable pressing the escape key
            showCloseButton: false, // Show a close button in the modal
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: i18n["form_create_new_report"],
            confirmButtonText: i18n["form_go_reports"],
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/reports/all");
            } else if (result.isDismissed) {
              window.location.reload();
            }
          });
        }

        // console.log(resp);
      } catch (error) {
        Swal.fire('Error', 'An error has occurred while sending the form', 'error');
        console.log(error);
      }
    }
  }

  // Valida cada que se oprime el boton next y cuando se hace submit del formulario
  const handleValidStep = ({ lastStep }) => {
    // funcion recursiva para que revise todos los inputs ya sean normales o condicionales
    const validForNextStep = (inputs) => {
      // iterar sobre cada input en el array 'inputs'
      for (const inp of inputs) {
        const { conditionals, ...rest } = inp; // desestructurar el input para obtener 'conditionals' y el resto de las propiedades
        for (const key in rest) {
          // iterar sobre cada propiedad del input (excepto 'conditionals')
          const valor = rest[key]; // variable temporal para obtener el array de errores con la key
          if (valor.length > 0) {
            // Ve si el array de errores esta vacio, si no es asi este retorna false;
            return false;
          }
        }
        if (conditionals) {
          // si hay condiciones (propiedad 'conditionals')
          const conditionalsValid = validForNextStep(conditionals); // llamar recursivamente con las condiciones
          if (!conditionalsValid) {
            // si las condiciones no son válidas, retornar false;
            return false;
          }
        }
      }
      // si todos los inputs pasan las validaciones anteriores, retornar true;
      return true;
    };

    const tempIsValid = _.cloneDeep(isValid[activeStep]);
    const isValidStep = validForNextStep(tempIsValid);

    setTryToNext(
      tryToNext.map((item, idx) => (idx === activeStep ? true : item))
    );

    //
    if (isValidStep) {
      if (!lastStep) {
        setActiveStep(activeStep + 1);
        handleScroll();
      }
      return true;
    } else {
      return false;
    }
  };

  // Para hacer un scroll cuando el usuario cambie de step
  const handleScroll = () => {
    if (formRef.current) {
      const rect = formRef.current.getBoundingClientRect();
      const offset = 50;
      window.scrollBy({ top: rect.top - offset, behavior: "smooth" });
    }
  };

  return (
    <div className="mt-20" ref={formRef}>
      <Container fluid={fullscreen? "fl": "md" } className={`dyTheme1 dyBorder1 p-5`}>
        <div className="form-preview" onSubmit={(e) => handleSubmit(e)}>
          {schemaState && (
            <div className="form-preview-inputs animation">
              <Tab.Container activeKey={activeStep}>
                <div className="form-preview-header-steper">
                  <Nav>
                    <CDBStepper
                      direction="horizontal"
                      md="2"
                      currentStepNumber={0}
                      stepColor=""
                      steps={[...steps.map((step, index) => index)]}
                    >
                      {schemaState.map((step, index) => (
                        <CDBStep
                          key={index + 1}
                          id={index + 1}
                          name={steps[index]?.title || i18n["step_no_name"]}
                          handleClick={
                            stepClick ? () => setActiveStep(index) : () => { }
                          }
                          active={activeStep + 1}
                          activeBgColor="#009ed7"
                          activeTextColor="#009ed7"
                          incompleteBgColor="#ffaf00"
                          incompleteTextColor="#ffefc0"
                          completeBgColor="#44cb67"
                          completeTextColor="white"
                          children={<Nav.Link eventKey={index}></Nav.Link>}
                        />
                      ))}
                    </CDBStepper>
                  </Nav>
                </div>

                <Tab.Content>
                  {schemaState.map((step, idxStep) => (
                    <Tab.Pane eventKey={idxStep} key={idxStep}>
                      <div className="form-preview-header mb-30 ">
                        <div className="form-preview-header-name">
                          <h3>
                            {steps[idxStep].title ||
                              i18n["step_without_name"]}
                          </h3>
                        </div>
                      </div>
                      <Row className="form-grid">
                        {step.map((input, idxInput) => (
                          <RenderInput
                            key={`form-input-${idxStep}-${idxInput}`}
                            entireSchema={schemaState}
                            entireFormData={formData}
                            entireIsValid={isValid}
                            constraints={constraints}
                            setConstraints={setConstraints}
                            entirePathSchema={`${idxStep}.${idxInput}`}
                            entirePathData={`${idxStep}.${idxInput}`}
                            setSchemaState={setSchemaState}
                            setFormData={setFormData}
                            setIsValid={setIsValid}
                            tryToNext={tryToNext}
                            activeStep={idxStep}
                            idx={idxInput}
                            attachments={attachments}
                            setAttachments={setAttachments}
                            origin={`stepers::${idxStep}::form::json-schema::${idxInput}`}
                            answered={answered}
                          />
                        ))}
                      </Row>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          )}
         
        </div>
        {showButtons && (
          <div className="form-buttons">
            {activeStep !== 0 && (
              <Button
                style={{ backgroundColor: "#009ED7" }}
                className="btn-form ant"
                onClick={() => setActiveStep((active) => active - 1)}
              >
                {i18n["form_prev_button"]}
              </Button>
            )}
            {activeStep === steps.length - 1 ? (
              <Button
                style={{ backgroundColor: "#152235" }}
                className="btn-send"
                onClick={ answered ? handleUpdate : handleSubmit }
              >
                {
                  answered 
                  ? "Actualizar"
                  : t("formPreview.send")
                }
              </Button>
            ) : (
              <Button
                style={{ backgroundColor: "#009ED7" }}
                className="btn-form"
                onClick={handleValidStep}
              >
                {i18n["form_next_button"]}
              </Button>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};
