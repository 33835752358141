import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { PieChart } from "./charts/PieChart/PieChart";
import { Row, Col, FormCheck } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { MCTooltip } from "../../MainComponents/Tooltip/Tooltip";
import { EGButton } from "../../MainComponents/Button/EGButton/EGButton";
import {
  deleteElement,
  getDataGraphicByPath,
} from "../../../apis/apiDashboard";
import BarChart from "./charts/BarChart/BarChart";
import { GraphicComplements } from "./complements/GraphicComplements";
import "./graphics.scss";


// import { DatePicker } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, Button, Popover, TextField } from '@mui/material';
import { format } from 'date-fns';


import Modal from 'react-bootstrap/Modal';
// import { PieChart } from "./charts/PieChart/PieChart";
import { DonutChart } from "./charts/PieChart/DonutChart";
import { DoubleChart } from "./charts/PieChart/DoubleChart";
import { LineChart } from "./charts/LineChart/historicLineChart";
import { LineChartSteps } from "./charts/LineChart/LineChartSteps";
import { LineChartWhitOutScales } from "./charts/LineChart/LineChartWithoutScale";
import { MonthsReportsHistoric } from "./charts/LineChart/MonthsReportsHistoric";
import { MonthsReportsHistoricBarChart } from "./charts/LineChart/MonthsReportsHistoricBarChart";
import { LineDoubleOpenClose } from "./charts/SpecialChart/LineDoubleOpenClose";
import { DispersionChart } from "./charts/SpecialChart/GraficaDispersion";
import { BarChartHistoric } from "./charts/LineChart/BarChartHistoric";
import { RadarChart } from "./charts/RadarChart/RadarChart";
import { WorldBubbleChart } from "./charts/GeoChart/WorldBubbleChart";
import { GeoXTypeChart } from "./charts/SpecialChart/GeoXTypeChart";
import { MexicoChart } from "./charts/GeoChart/MexicoGeoChart";
import { GeneroChart } from "./charts/SpecialChart/GeneroChart";
import { BarrasEstadoStack } from "./charts/SpecialChart/BarrasEstadoStackChart";
import { DistibutionStateSeverity } from "./charts/SpecialChart/DistributionStateSeverity";
import { DistibutionStateType } from "./charts/SpecialChart/DistributionStateType";
// import BarChart from "./charts/BarChart/BarChart";
import ParetoChart from "./charts/BarChart/ParetoChart";
import BarChartHorizontal from "./charts/BarChart/BarChartHorizontal";
import { LineOpenCloseChart } from "./charts/SpecialChart/LineOpenCloseChart";


import Icon from "../../Icon/Icon";

import { useSnackbar } from "notistack";
import { LoadingGraphic } from "../../Loading/Graphic/Graphic";
import { useTranslation } from "react-i18next";

export const Graphics = ({ graphicsData, stateLoading = null }) => {
  const [t] = useTranslation("dashboard");
  const { gTheme } = useSelector((state) => state.theme);
  const theme = gTheme === "dark" ? "dark" : "light";
  const { enqueueSnackbar } = useSnackbar();

  const [graphics, setGraphics] = useState([]);
  const [deleting, setDeleting] = useState("");
  const [replaceData, setReplaceData] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [elementVisibility, setElementVisibility] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customRange, setCustomRange] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [selectedRange, setSelectedRange] = useState(30);
  const [customRangeText, setCustomRangeText] = useState("");


  const [currentGraphicIndex, setCurrentGraphicIndex] = useState(null);
  const today = new Date(); // Fecha actual

  useEffect(() => {
    if (!replaceData) {
      setOriginalData(Object.values(graphicsData));
      setGraphics(Object.values(graphicsData));
      setReplaceData(true);
    }
  }, []);

  const deleteGraphic = async (graphicPath) => {
    setDeleting(graphicPath);

    const response = await deleteElement({ path: graphicPath });
    if (stateLoading !== null && response !== undefined) stateLoading(true);
  };


  const [elementVisibilityIndicators, setElementVisibilityIndicators] = useState([])
  const toggleElementVisibility = (index) => {
    const updatedElementVisibility = [...elementVisibility];
    updatedElementVisibility[index] = !updatedElementVisibility[index];
    setElementVisibility(updatedElementVisibility);
  };


  const toggleElementVisibilityIndicator = (index) => {
    const updatedElementVisibilityIndicators = [...elementVisibilityIndicators];
    updatedElementVisibilityIndicators[index] = !updatedElementVisibilityIndicators[index];
    setElementVisibilityIndicators(updatedElementVisibilityIndicators);
  };


  // Funcion para obtener los datos de la grafica
  // Con filtros de los ultimos 30, 60 y 90 dias
  const getGraphicData = async (graphicPath, days, index) => {
    setCurrentIndex(index);
    setIsLoading(true);

    

 // Obtener las fechas actuales de r_start y r_end si no están definidas
 let requestData;
 if (days.r_start && days.r_end) {
     requestData = { path: graphicPath, r_start: days.r_start, r_end: days.r_end };
 } else {
     const today = new Date();
     const r_end = today.toISOString().split("T")[0]; // Fecha de hoy en formato 'YYYY-MM-DD'
     const r_start = new Date(today.setDate(today.getDate() - days))
       .toISOString()
       .split("T")[0]; // Restar el número de días de 'days' a la fecha actual
     
     requestData = { path: graphicPath, r_start, r_end };
 }



    const response = await getDataGraphicByPath(requestData);

    if (response.status === 204) {
      enqueueSnackbar(t("Graphics.no_data"), {
        variant: "warning",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
    if (response.status === 404) {
      enqueueSnackbar(response.data, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
    if (response.status === 200) {
      enqueueSnackbar(t("Graphics.obtained_data"), {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });

      var tmpGraphics = [...graphics];
      tmpGraphics[index] = response.data;
      setGraphics(tmpGraphics);
    }
    setCurrentIndex(-1);
    setIsLoading(false);
  };




  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // Resetear las fechas temporales al cerrar el pop-up
    setTempStartDate(null);
    setTempEndDate(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-popover' : undefined;

  const handleShow = (index) => {
    setCurrentGraphicIndex(index);
    setFullscreen(true);
    setShow(true);
  }

  const [currentIndexSelected, setCurrentIndexSelected] = useState(null);
  const [currentPathSelected, setCurrentPathSelected] = useState(null);
  const [currentDateSelected, setCurrentDateSelected] = useState({});
  // console.log("currentDateSelected", currentDateSelected);


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>

      {graphics.length > 0 &&
        graphics.map((graphicItem, index) => {
          return (
            <Row key={index}>
              <div className={`graphic-content ${theme}`} >
                {/* <div className={`graphic-header ${theme}` }> */}
                <div className={`graphic-header ${theme} ${elementVisibilityIndicators[index] ? 'col-lg-8' : 'col-lg-12'}`}>
                  <Col className="subtitle" lg={8}>
                    {graphicItem.title === deleting
                      ? `${t("Graphics.deleting")}...`
                      : graphicItem.title}
                  </Col>
                  <Col lg={4} className={`options ${theme}`}>
                    <Col sm={10}>
                      <MCTooltip text={t("Graphics.get_results")} position="bottom">
                        <Form.Select
                          size="sm"
                          // value={customRange ? "custom" : selectedRange}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            // getGraphicData(graphicItem.path, selectedValue, index); // Obtener los datos gráficos
                            setCurrentIndexSelected(index);
                            setCurrentPathSelected(graphicItem.path);

                            if (selectedValue === 'custom') {
                              setCustomRange(true); // Mostrar el calendario
                              handleClick(e); // Llamar a la función para manejar el clic si es necesario
                            } else {
                              setCustomRange(false); // Ocultar el calendario
                              // setSelectedRange(selectedValue); // Establecer el rango seleccionado
                              getGraphicData(graphicItem.path, selectedValue, index); // Obtener los datos gráficos
                            }
                          }}
                        >
                          <option value={30}>{t("Graphics.last_30")}</option>
                          <option value={60}>{t("Graphics.last_60")}</option>
                          <option value={90}>{t("Graphics.last_90")}</option>
                          <option value={183}>{t("Graphics.past_six_months")}</option>
                          <option value={365}>{t("Graphics.last_year")}</option>
                          <option value="custom">{currentDateSelected[index] || t("Graphics.custom_range")}</option>
                        </Form.Select>
                      </MCTooltip>
                    </Col>


                    {customRange && (
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <DatePicker
                            label={t("Graphics.start_date")}
                            value={tempStartDate}
                            onChange={(newValue) => setTempStartDate(newValue)}
                            maxDate={today}
                            textField={(params) => <TextField {...params} />}
                          />
                          <Box sx={{ my: 2 }} />
                          <DatePicker
                            label={t("Graphics.end_date")}
                            value={tempEndDate}
                            onChange={(newValue) => setTempEndDate(newValue)}
                            minDate={tempStartDate}
                            maxDate={today}
                            textField={(params) => <TextField {...params} />}
                          />
                          <EGButton
                            label={t("Graphics.apply_custom_range")}
                            color="primary"
                            sx={{
                              display: 'flex', justifyContent: 'flex-end', mt: 2

                            }} onClick={() => {
                              const formattedStartDate = format(tempStartDate, 'yyyy-MM-dd');
                              const formattedEndDate = format(tempEndDate, 'yyyy-MM-dd');
                              const visibleStartDate = format(tempStartDate, 'dd-MM-yyyy');
                              const visibleEndDate = format(tempEndDate, 'dd-MM-yyyy');

                              setCurrentDateSelected(prevState => ({
                                ...prevState,
                                [currentIndexSelected]: `${visibleStartDate} - ${visibleEndDate}`
                              }));

                              setSelectedRange("custom");
                              setCustomRangeText(`${visibleStartDate} - ${visibleEndDate}`);
                              getGraphicData(currentPathSelected, { r_start: formattedStartDate, r_end: formattedEndDate }, currentIndexSelected); // Envía fechas formateadas
                              handleClose();
                            }}

                            disabled={!tempStartDate || !tempEndDate || tempEndDate < tempStartDate}
                          />

                        </Box>
                      </Popover>
                    )}

                    <button
                      onClick={() => handleShow(index)}
                      className="maximize-button"
                    >
                      <Icon
                        size={20}
                        name="outline_maximize"
                      />
                    </button>
                    <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
                      <Modal.Header closeButton>
                        <Modal.Title>{graphics[currentGraphicIndex]?.title}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {graphics[currentGraphicIndex] && (
                          <div className="graphic-element">
                            {graphics[currentGraphicIndex].show_graphic === "bars" ? (
                              <>
                                {graphics[currentGraphicIndex].show_graphic_graphic == 3 ? (
                                  <BarChartHorizontal chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 4 ? (
                                  <BarChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 5 ? (
                                  <ParetoChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : (
                                  <BarChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                )}
                              </>
                            ) : graphics[currentGraphicIndex].show_graphic === "circles" ? (
                              <>
                                {graphics[currentGraphicIndex].show_graphic_graphic == 1 ? (
                                  <PieChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 2 ? (
                                  <DonutChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 8 ? (
                                  <DoubleChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : (
                                  <PieChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                )}
                              </>

                            ) : graphics[currentGraphicIndex].show_graphic === "historic" ? (
                              <>
                                {graphics[currentGraphicIndex].show_graphic_graphic == 6 ? (
                                  <LineChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 7 ? (
                                  <LineChartWhitOutScales chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 9 ? (
                                  <LineChartSteps chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 11 ? (
                                  <BarChartHistoric chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 23 ? (
                                  <MonthsReportsHistoric chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 100 ? (
                                  <MonthsReportsHistoricBarChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : (
                                  <PieChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                )}
                              </>
                            ) : graphics[currentGraphicIndex].show_graphic === "radar" ? (
                              <>
                                {graphics[currentGraphicIndex].show_graphic_graphic == 10 ? (
                                  <RadarChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : (
                                  <PieChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                )}
                              </>
                            ) : graphics[currentGraphicIndex].show_graphic === "special" ? (
                              <>
                                {graphics[currentGraphicIndex].show_graphic_graphic == 14 ? (
                                  <BarChartHorizontal chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 15 ? (
                                  <LineOpenCloseChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 16 ? (
                                  <GeoXTypeChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 17 ? (
                                  <LineDoubleOpenClose chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 18 ? (
                                  <DispersionChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 19 ? (
                                  <BarrasEstadoStack chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 20 ? (
                                  <GeneroChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 21 ? (
                                  <DistibutionStateSeverity chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 22 ? (
                                  <DistibutionStateType chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : (
                                  <PieChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                )}
                              </>
                            ) : graphics[currentGraphicIndex].show_graphic === "geographic" ? (
                              <>
                                {graphics[currentGraphicIndex].show_graphic_graphic == 12 ? (
                                  <WorldBubbleChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 13 ? (
                                  <MexicoChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : (
                                  <PieChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                )}
                              </>
                            ) : (
                              <>
                                <LineChartWhitOutScales chartData={graphics[currentGraphicIndex]} maximize={true} />
                              </>
                            )}
                          </div>
                        )}
                      </Modal.Body>
                    </Modal>

                    <Col sm={2} className={`option-menu ${theme} `}>
                      <div className={`graphic-options ${theme}`}>
                        <MCTooltip text={t("Graphics.options")} position="bottom">
                          <DropdownButton
                            key="end"
                            id="dropdown-button-drop-end"
                            drop="end"
                            title="ooo"
                          >
                            {/* <Dropdown.Item eventKey="1">
                          {t("dashboard.graphics.dropdown.changeRange")}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="2">
                          {t("dashboard.graphics.dropdown.asignRangue")}
                        </Dropdown.Item>
                        <Dropdown.Divider /> */}
                            <Dropdown.Item
                              eventKey="4"
                              onClick={() => deleteGraphic(graphicItem.path)}
                            >
                              {t("Graphics.delete")}
                            </Dropdown.Item>
                          </DropdownButton>
                        </MCTooltip>
                      </div>
                    </Col>
                  </Col>
                </div>

                {Object.keys(graphicItem.indicators).length > 0 ? (
                  <div className="row">
                    <div className="col-12">
                      <div
                        className={`graphic-container ${isLoading && currentIndex === index ? "show-loading" : ""
                          }`}
                      >
                        <LoadingGraphic />
                        <Row className="graphic-body">
                          {graphicItem.show_graphic_graphic !== "19" && graphicItem.show_graphic_graphic !== "16" && graphicItem.show_graphic_graphic !== "22" ? (
                            <Col lg={elementVisibilityIndicators[index] ? 8 : 12}>
                              <div className="graphic-switch ">
                                <Icon
                                  size={16}
                                  className="graphic-option"
                                  name="bar_chart_bold"
                                />
                                <FormCheck
                                  type="switch"
                                  checked={elementVisibilityIndicators[index]}
                                  onChange={() => toggleElementVisibilityIndicator(index)}
                                />
                                <Icon
                                  size={16}
                                  className="data-option"
                                  name="outline_chart_bold"
                                />
                              </div>
                            </Col>
                          ) :
                            (
                              <></>
                            )}
                          {elementVisibility[index] ? (
                            <div className="graphic-indicators">
                              <GraphicComplements chartData={graphicItem} startDate={startDate} endDate={endDate} />
                            </div>
                          ) : (
                            <Col lg={elementVisibilityIndicators[index] ? 8 : 12} className="graphic-element">
                              {graphicItem.show_graphic === "bars" ? (
                                <>
                                  {graphicItem.show_graphic_graphic == 3 ? (
                                    <BarChartHorizontal chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 4 ? (
                                    <BarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 5 ? (
                                    <ParetoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : (
                                    <BarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  )}
                                </>
                              ) : graphicItem.show_graphic === "circles" ? (
                                <>
                                  {graphicItem.show_graphic_graphic == 1 ? (
                                    <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 2 ? (
                                    <DonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 8 ? (
                                    <DoubleChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : (
                                    <DonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  )}
                                </>
                              ) : graphicItem.show_graphic === "historic" ? (
                                <>
                                  {graphicItem.show_graphic_graphic == 6 ? (
                                    <LineChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 7 ? (
                                    <LineChartWhitOutScales chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 9 ? (
                                    <LineChartSteps chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 11 ? (
                                    <BarChartHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 23 ? (
                                    <MonthsReportsHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 100 ? (
                                    <MonthsReportsHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : (
                                    <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  )}
                                </>
                              ) : graphicItem.show_graphic === "radar" ? (
                                <>
                                  {graphicItem.show_graphic_graphic == 10 ? (
                                    <RadarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : (
                                    <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  )}
                                </>
                              ) : graphicItem.show_graphic === "special" ? (
                                <>
                                  {graphicItem.show_graphic_graphic == 14 ? (
                                    <BarChartHorizontal chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 15 ? (
                                    <LineOpenCloseChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 16 ? (
                                    <GeoXTypeChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 17 ? (
                                    <LineDoubleOpenClose chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 18 ? (
                                    <DispersionChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 19 ? (
                                    <BarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 20 ? (
                                    <GeneroChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 21 ? (
                                    <DistibutionStateSeverity chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 22 ? (
                                    <DistibutionStateType chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 100 ? (
                                    <MonthsReportsHistoricBarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : (
                                    <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  )}
                                </>
                              ) : graphicItem.show_graphic === "geographic" ? (
                                <>
                                  {graphicItem.show_graphic_graphic == 12 ? (
                                    <WorldBubbleChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                                  ) : graphicItem.show_graphic_graphic == 13 ? (
                                    <MexicoChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                                  ) : (
                                    <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                                  )}
                                </>
                              ) : (
                                <>
                                  <LineChartWhitOutScales chartData={graphicItem} />
                                </>
                              )}
                            </Col>
                          )}
                          {elementVisibilityIndicators[index] ? (
                            <Col lg={4}>
                              <div className="graphic-indicaors-right" t>
                                <GraphicComplements chartData={graphicItem} startDate={startDate} endDate={endDate} right={true} />
                              </div>
                            </Col>
                          ) : (
                            <></>
                          )}
                        </Row>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`graphic-container ${isLoading && currentIndex === index ? "show-loading" : ""
                      }`}
                  >
                    <LoadingGraphic />
                    <Row className="graphic-body">
                      {graphicItem.show_graphic_graphic !== "19" && graphicItem.show_graphic_graphic !== "16" && graphicItem.show_graphic_graphic !== "22" ? (
                        <div className="graphic-switch">
                          <Icon
                            size={16}
                            className="graphic-option"
                            name="bar_chart_bold"
                          />
                          <FormCheck
                            type="switch"
                            checked={elementVisibility[index]}
                            onChange={() => toggleElementVisibility(index)}
                          />
                          <Icon
                            size={16}
                            className="data-option"
                            name="outline_chart_bold"
                          />
                        </div>
                      ) :
                        (
                          <></>
                        )}
                      {elementVisibility[index] ? (
                        <div className="graphic-indicators">
                          <GraphicComplements chartData={graphicItem} startDate={startDate} endDate={endDate} />
                        </div>
                      ) : (
                        <Col lg={12} className="graphic-element">
                          {graphicItem.show_graphic === "bars" ? (
                            <>
                              {graphicItem.show_graphic_graphic == 3 ? (
                                <BarChartHorizontal chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 4 ? (
                                <BarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 5 ? (
                                <ParetoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : (
                                <BarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              )}
                            </>
                          ) : graphicItem.show_graphic === "circles" ? (
                            <>
                              {graphicItem.show_graphic_graphic == 1 ? (
                                <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 2 ? (
                                <DonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 8 ? (
                                <DoubleChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : (
                                <DonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              )}
                            </>
                          ) : graphicItem.show_graphic === "historic" ? (
                            <>
                              {graphicItem.show_graphic_graphic == 6 ? (
                                <LineChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 7 ? (
                                <LineChartWhitOutScales chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 9 ? (
                                <LineChartSteps chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 11 ? (
                                <BarChartHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 23 ? (
                                <MonthsReportsHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 100 ? (
                                <MonthsReportsHistoricBarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : (
                                <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              )}
                            </>
                          ) : graphicItem.show_graphic === "radar" ? (
                            <>
                              {graphicItem.show_graphic_graphic == 10 ? (
                                <RadarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : (
                                <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              )}
                            </>
                          ) : graphicItem.show_graphic === "special" ? (
                            <>
                              {graphicItem.show_graphic_graphic == 14 ? (
                                <BarChartHorizontal chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 15 ? (
                                <LineOpenCloseChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 16 ? (
                                <GeoXTypeChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 17 ? (
                                <LineDoubleOpenClose chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 18 ? (
                                <DispersionChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 19 ? (
                                <BarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 20 ? (
                                <GeneroChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 21 ? (
                                <DistibutionStateSeverity chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 22 ? (
                                <DistibutionStateType chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : (
                                <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              )}
                            </>
                          ) : graphicItem.show_graphic === "geographic" ? (
                            <>
                              {graphicItem.show_graphic_graphic == 12 ? (
                                <WorldBubbleChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                              ) : graphicItem.show_graphic_graphic == 13 ? (
                                <MexicoChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                              ) : (
                                <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                              )}
                            </>
                          ) : (
                            <>
                              <LineChartWhitOutScales chartData={graphicItem} />
                            </>
                          )}
                        </Col>
                      )}
                    </Row>
                  </div>
                )}
              </div>
            </Row>
          );
        })
      }
    </LocalizationProvider>
  );
};
