import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/logo-eg.svg";
import Icon from "../Icon/Icon";

const MenuItem = ({ path = "", title = "", icon = "", outsite = false }) => {
  const list = (
    <li className="sidebar_menu_item">
      <div className="sidebar_menu_item_content">
        <div />
        <div>
          <Icon name={icon} className="sidebar-icon" />
          <span>{title}</span>
        </div>
      </div>
    </li>
  );

  return outsite ? (
    <a href={path} target="_blank" rel="noopener noreferrer">
      {list}
    </a>
  ) : (
    <NavLink to={path} className={({ isActive }) => (isActive ? "active" : "")}>
      {list}
    </NavLink>
  );
};

const MenuItemSubmenu = ({ options = [], title = "", icon = "" }) => {
  const { pathname } = useLocation();
  const [toggled, settoggled] = useState(false);

  const getPathName = () => {
    const pathsOnReports = options.map((opt) => opt.path);
    if (pathsOnReports.includes(pathname)) return true;
    return false;
  };

  return (
    <li
      className={`sidebar_menu_item submenu ${getPathName() ? "active" : ""} ${
        toggled ? "toggled" : ""
      }`}
      onClick={() => settoggled(!toggled)}
    >
      <div className="sidebar_menu_item_content">
        <div />
        <div>
          <Icon name={icon} className="sidebar-icon" />
          <span>{title}</span>
          <Icon name="arrow_down_2" className="submenu_arrow_collapse" />
        </div>
      </div>
      <div className="sidebar_menu_item_dropdown">
        <ul>
          {options.map((option, idx) => (
            <Link to={option.path} key={idx}>
              <li>{option.title}</li>
            </Link>
          ))}
        </ul>
      </div>
    </li>
  );
};

export const Sidebar = () => {
  const [t] = useTranslation("components");
  const { gTheme } = useSelector((state) => state.theme);

  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className={`sidebar_space ${collapsed ? "collapse" : ""}`}>
      <div className={`sidebar ${gTheme === "light" ? "" : "dark-mode"}`}>
        <div className="sidebar_top">
          <div className="sidebar_header">
            <Link to="/" className="headerSideBar">
              <img src={Logo} className="mobile-logo" alt="logo" />
            </Link>
          </div>

          <div className="sidebar_menu">
            <ul>
              <MenuItem
                path="/dashboard"
                title="Dashboard"
                icon="bold_element_2"
              />
              
              <MenuItemSubmenu
                title={t("Sidebar.reports")}
                icon="bold_document_text"
                options={[
                  {
                    path: "/reports/new",
                    title: t("Sidebar.new_report"),
                  },
                  {
                    path: "/reports/all",
                    title: t("Sidebar.all_reports"),
                  },
                  {
                    path: "/reports/all/:new",
                    title: t("Sidebar.news"),
                  },
                  {
                    path: "/reports/all/:pending",
                    title: t("Sidebar.pending"),
                  },
                  {
                    path: "/reports/all/:process",
                    title: t("Sidebar.process"),
                  },
                  {
                    path: "/reports/all/:finish",
                    title: t("Sidebar.finish"),
                  },
                  {
                    path: "/reports/all/:closed",
                    title: t("Sidebar.closed"),
                  },
                  
                  // {
                  //   path: "/reports/complaints",
                  //   title: t("Sidebar.grievance"),
                  // },
                  // {
                  //   path: "/reports/questions",
                  //   title: t("Sidebar.questions"),
                  // },
                  // {
                  //   path: "/reports/suggestions",
                  //   title: t("Sidebar.suggestions"),
                  // },
                ]}
              />

              <MenuItemSubmenu
                title={t("Sidebar.users")}
                icon="bold_profile_2_ser"
                options={[
                  {
                    path: "/users",
                    title: t("Sidebar.all_users"),
                  },
                  {
                    path: "/users/new",
                    title: t("Sidebar.add_user"),
                  },
                  {
                    path: "/segments",
                    title: t("Sidebar.groups"),
                  },
                  {
                    path: "/users/roles",
                    title: "Roles",
                  },
                ]}
              />

              <MenuItem
                path="/exports/"
                title="Exportables"
                icon="bold_folder_open"
              />

              {/*<MenuItem
                  path="/users"
                  title={t("globalComponents.aside.users")}
                  icon="bold_profile_2_ser"
                />

                <MenuItem
                  path="/statistics"
                  title={t("globalComponents.aside.statistics")}
                  icon="bar_chart2_bold"
                />
                <MenuItem
                  path="/notifications"
                  title={t("globalComponents.aside.notifications")}
                  icon="notification_bold"
                />
                <MenuItem
                  path="/mail"
                  title={t("globalComponents.aside.mailbox")}
                  icon="bold_direct"
                /> */}
                <MenuItem
                path="/logs"
                title={t("Sidebar.logs")}
                icon="bold_document_filter"
              />
            </ul>
          </div>
        </div>

        <div className="sidebar_bottom">
          {/* <div className="sidebar_card">
            <div className="sidebar_card_icon_container">
              <Icon name="group_39_2" className="sidebar_card_icon" />
            </div>
            <span>{t("Sidebar.discover_features")}</span>
            <Button
              size="large"
              className={`${
                gTheme === "light" ? "athics-lignt-btn" : "athics-outline-btn"
              } w-100`}
            >
              {t("Sidebar.add_plans")}
            </Button>
          </div> */}

          <div className="sidebar_footer">
            <ul>
              <MenuItem
                path="https://webservice.atlassian.net/servicedesk/customer/portal/"
                title={t("Sidebar.help")}
                icon="bold_message_question_2"
                outsite={true}
              />
            </ul>
            <hr />
            <span>Powered by EthicsGlobal</span>
          </div>
        </div>
      </div>
    </div>
  );
};
