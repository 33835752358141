import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos




export const PieChart = ({ chartData, startDate, endDate }) => {
  const [chart, setChart] = useState(null);
  const chartDivId = useRef(`piechart-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
  const diaInicio = startDate
  const diaFin = endDate
  const graphicTitle = chartData.title


  // console.log("Console.lxog", chartData);


  useEffect(() => {
    let newChart = null;

    const generateChart = async () => {
      try {


        var startDate = new Date(diaInicio).getTime();
        var endDate = new Date(diaFin).getTime();
        const endDatePlusOneDay = new Date(endDate);
        endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


        const root = am5.Root.new(chartDivId.current);
        root.setThemes([MyTheme.new(root)]);

        newChart = root.container.children.push(
          am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
          })
        );


        const pieSeries = newChart.series.push(
          am5percent.PieSeries.new(root, {
            name: "Series",
            valueField: "value",
            categoryField: "category",

          })
        );

        // // Set data
        // console.log("Datos a asignar al gráfico:", chartData.data.map((item) => ({
        //   category: item.label,
        //   value: item.data.length,
        // })));


        pieSeries.data.setAll(
          chartData.data
              .map((item) => {
                  // Filtrar valores que cumplen la condición de fecha
                  const filteredValues = item.data[0].filter((value, index) => {
                      const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
                      return startDate === 0 && endDate === 0
                          ? true
                          : date >= startDate && date <= endDatePlusOneDay;
                  });
      
                  // Contar solo los valores mayores a 0
                  const count = filteredValues.filter(value => value > 0).length;
      
                  // Retornar solo si el conteo es mayor a 0
                  return count > 0 ? { category: item.label, value: count } : null;
              })
              .filter(Boolean) // Filtrar elementos nulos
      );


        // var exporting = am5plugins_exporting.Exporting.new(root, {
        //   menu: am5plugins_exporting.ExportingMenu.new(root, {
        //     align: "right",
        //     valign: "bottom"
        //   })
        // });







        var title = newChart.children.push(am5.Label.new(root, {
          text: graphicTitle,
          fontSize: 20,
          x: am5.percent(50),
          y: am5.percent(0),
          centerX: am5.percent(50),
          visible: false // Ocultar el título inicialmente
        }));

        // Configuración de exportación
        var exporting = am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
            align: "right",
            valign: "bottom"
          }),
          pdfOptions: {
            addURL: false
          }
        });

        // Evento para mostrar el título solo durante la exportación a PDF
        exporting.events.on("exportstarted", function (event) {
          if (event.format === "pdf") {
            title.set("visible", true); // Mostrar el título solo al exportar a PDF
          }
        });

        exporting.events.on("exportfinished", function (event) {
          title.set("visible", false); // Ocultar el título nuevamente después de exportar
        });





        
        // Add legend
        newChart.legend = am5.Legend.new(root, {});


        if (chart) {
          chart.dispose();
        }

        setChart(newChart);
      } catch (error) {
        console.error("Error al cargar las bibliotecas de AmCharts:", error);
      }
    };


    generateChart();




    return () => {

      am5.array.each(am5.registry.rootElements, function (root) {
        if (root) {
          if (root.dom.id == chartDivId.current) {
            root.dispose();
          }
        }
      });


      if (chart) {
        chart.dispose();
      }
    };
  }, [chartData, startDate, endDate]);

  return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default PieChart;