import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { usePrompt } from "./usePrompt"; // Te mostraré cómo implementar este hook a continuación
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;
/**
 * Custom Hook to prevent unsaved exit when editing a form
 * @param {*} editingState State to be evaluated true|false if edit mode is activated
 * @param {*} message Customizable internal navigation message
 * @returns
 */
const useUnsavedChangesWarning = (
  editingState,
  message = language.you_have_unsaved_changes_are_you_sure_you_want_to_leave
) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    // Evaluate the state that has been passed
    if (typeof editingState === "object") {
      if (Object.values(editingState).some((value) => value === true)) {
        setUnsavedChanges(true);
      } else {
        setUnsavedChanges(false);
      }
    } else if (typeof editingState === "boolean") {
      if (editingState === true) {
        setUnsavedChanges(true);
      } else {
        setUnsavedChanges(false);
      }
    }
  }, [editingState]);

  usePrompt(unsavedChanges, message);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        // For supported browsers
        event.returnValue = message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [unsavedChanges, message]);
  // Returns current location if needed
  return location;
};

export default useUnsavedChangesWarning;